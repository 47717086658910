import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { createMerchant, createBrand, supplierError, bankList } from '../../store/merchant/actions';
import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class MerchantCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            feature_image: {},
            supplier: {

            },

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchsupplierList();
        this.fetchCityList();
        this.fetchBankList();
    }

    fetchsupplierList = () => {
        // let options = {
        //     page: this.state.page,
        //     per_page: this.state.per_page,
        //     search: this.state.search
        // }
        // supplierList(options).then((response) => {
        //     this.setState({
        //         supplierList: response.data,
        //     });
        //     this.state.supplierList = response.data;
        // })
        //     .catch((err) => {
        //         console.log(err);
        //     })
    }

    fetchBankList = () => {
        bankList().then((response) => {
            this.setState({
                bankList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }


    fetchCityList = () => {
        cityList().then((response) => {
            this.setState({
                cityList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchDistrictList = (city_id) => {
        districtList(city_id).then((response) => {
            this.setState({
                districtList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchThanaList = (district_id) => {
        thanaList(district_id).then((response) => {
            this.setState({
                thanaList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.createMerchant(values);
        setTimeout(this.props.history.push('/merchant'), 3000);
    }

    // submitForm(e) {
    //     e.preventDefault()
    //     setTimeout(this.props.history.push('/merchant'), 3000);
    //     // this.props.history.push('/products'); // <--- The page you want to redirect your user to.
    //     // window.location.reload()
    // }

    handleImageUpload = (e) => {
        var id = e.target.name;
        // var value = e.target.files[0];
        id = id[id.length - 1];
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type };
            this.state.feature_image[id] = formData;
           // console.log(formData);

        }
    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchsupplierList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }


    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    render() {
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let bankList = [];
        let paginator;
        if (this.state.hasOwnProperty('supplierList')) {
            supplierListMap = this.state.supplierList.data;
            paginator = {
                'currentPage': this.state.supplierList.current_page,
                'pagesCount': this.state.supplierList.last_page,
                'total': this.state.supplierList.total,
                'to': this.state.supplierList.to,
                'from': this.state.supplierList.from,
            }
        }
        const { supplier_id, supplier_code, supplier_name_bn, supplier_name_en, supplier_address, supplier_mobile, f_division_id, f_district_id, f_thana_id } = this.state.supplier;
        if (this.state.hasOwnProperty('cityList')) { cityList = this.state.cityList; }
        if (this.state.hasOwnProperty('districtList')) { districtList = this.state.districtList; }
        if (this.state.hasOwnProperty('thanaList')) { thanaList = this.state.thanaList; }
        if (this.state.hasOwnProperty('bankList')) { bankList = this.state.bankList; }
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        //console.log("this.props.success", this.props.success)
        if (this.props.success != '') {
            setTimeout(function () {
                window.location.reload();
            }, 1000);

        }

        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Merchant" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>


                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Merchant</CardTitle>

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>
                                            
                                            <Row>
                                                <div className="col-md-12">
                                                   <AvField label="Marchant Logo" type="file" className="form-control" name="marchent_logo" onChange={(e) => this.handleImageUpload(e)} id="marchent_logo" />
                                                   <AvField type="hidden" value={this.state.feature_image} name="feature_image" id="feature_image" />
                                               
                                                </div>
                                            </Row>

                                            <Row>

                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Merchant Name')} id="merchant_name_en" name="name_en" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label="Merchant Name (Bangla)" type="text" name="name_bn" id="merchant_name_bn" />
                                                </div>

                                            </Row>
                                            <Row>

                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Merchant Address')} type="text" name="address" id="merchant_address" />
                                                </div>

                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Merchant Mobile')} type="number" name="mobile" id="merchant_mobile" required />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel("Merchant Email")} type="email" name="email" id="merchant_email" required />
                                                </div>

                                                <div className="col-md-6">
                                                    <AvField type="select" className="custom-select" label={reqLabel('Bank Name')} name="f_bank_id" id="f_bank_id" onChange={(e) => this.handleInputChange(e)} required >
                                                        <option defaultValue>Select Bank Name</option>
                                                     
                                                        {bankList.map((bank, index) =>
                                                            <option value={bank.parent_bank_id}>{bank.parent_bank_name}</option>
                                                        )} 
                                                    </AvField>
                                                </div>
                                            </Row>

                                            
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField type="text" className="form-control" label={reqLabel('Bank Branch Name')} name="branch_name" id="f_bank_branch_name" required >
                                          
                                                    </AvField>
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Bank Address')} type="text" name="branch_address" id="bank_address" required />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel("Bank A/C No.")} type="text" name="branch_acc_no" id="bank_acc_no" required />
                                                </div>

                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Bank Route NO.')} type="number" name="branch_route_id" id="bank_route_no" required />
                                                </div>
                                            </Row>
                                            <div className=" mt-4">
                                                {/* <form onSubmit={this.submitForm.bind(this)}>
                                                        <Button type="submit" color="success" >Submit</Button>
                                                </form> */}
                                                <Button type="submit" color="success">Submit</Button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.Merchant;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createMerchant})(MerchantCreate));