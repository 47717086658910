import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_LOAN_TYPE,LOAN_TYPE_SUCCESS,EDIT_LOAN_TYPE,SHOW_LOAN_TYPE } from './actionTypes';

import { loanTypeSuccess, loanTypeError,loanTypeShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createLoanType({ payload: { loanType } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'loanType', { 
                    loan_type_name_en : loanType.loan_type_name_en,
                    loan_type_name_bn : loanType.loan_type_name_bn,
                    remarks : loanType.remarks
                });

             yield put(loanTypeSuccess(response));
          }
    } catch (error) {
        yield put(loanTypeError(error));
    }
}


function* editLoanType({ payload: { loanType } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'loanType/id/'+loanType.loan_type_id, { 
                    loan_type_name_en : loanType.loan_type_name_en,
                    loan_type_name_bn : loanType.loan_type_name_bn,
                    remarks : loanType.remarks
                });
             yield put(loanTypeSuccess(response));
          }
          
          
    } catch (error) {
        yield put(loanTypeError(error));
    }
}


function* showLoanType({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);
             yield put(loanTypeShow(response));
          }
          
          
    } catch (error) {
        yield put(loanTypeError(error));
    }
}

export function* watchLoanType() {
    yield takeEvery(EDIT_LOAN_TYPE, editLoanType)
    yield takeEvery(CREATE_LOAN_TYPE, createLoanType)
    yield takeEvery(SHOW_LOAN_TYPE, showLoanType)
}

function* LoanTypeSaga() {
    yield all([
        fork(watchLoanType),
    ]);
}

export default LoanTypeSaga;