import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
import {voucharList} from '../../store/voucher/actions';
import { deleteVoucher,editVoucher } from '../../store/voucher/actions';
import {  marchantList} from '../../store/merchantBranch/actions';
import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class VoucherIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            voucher:{},
            supplier: {

            },

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchvoucharList();  
        this.fetchCityList();     
    }

    fetchvoucharList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        voucharList(options).then((response) => {
         //   console.log(response);
            this.setState({
                voucharList: response.data,
            });
            this.state.voucharList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }


    fetchCityList = () => {
        cityList().then((response) => {
            this.setState({
                cityList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchDistrictList = (city_id) => {
        districtList(city_id).then((response) => {
            this.setState({
                districtList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchThanaList = (district_id) => {
        thanaList(district_id).then((response) => {
            this.setState({
                thanaList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchMarchantList = () => {
        marchantList().then((response) => {
            this.setState({
                marchantList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (voucher) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.voucher = voucher;
     //   console.log("voucher->>>>>>>>>>>>>>",voucher.f_marchant_id);
        this.fetchMarchantList(voucher.f_marchant_id);
        this.removeBodyCss();
    }

    Voucherdelete = (voucherId) => {
        
        this.state.voucherId = voucherId;
        deleteVoucher(voucherId);
        setTimeout(function () {
            window.location.reload();
          }, 3500)
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.createSupplier(values);
        this.form && this.form.reset();
        this.fetchvoucharList();
        // alert("Supplier Create Successfully");
        // window.location.reload();
    }


    handleEditSubmit(event, values) {
        this.props.editVoucher(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchvoucharList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchvoucharList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchvoucharList();
        });
    }

    render() {
        let voucharListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        if (this.state.hasOwnProperty('voucharList')) {
            voucharListMap = this.state.voucharList.data;
            paginator = {
                'currentPage': this.state.voucharList.current_page,
                'pagesCount': this.state.voucharList.last_page,
                'total': this.state.voucharList.total,
                'to': this.state.voucharList.to,
                'from': this.state.voucharList.from,
            }
        }

        let singlevoucher =this.state.voucher;
      //  console.log("singlevoucher",singlevoucher);
        const { code,status,id,f_marchant_id,name_en,voucher_end_date,voucher_start_date,autot_commission_amount,autot_commission_percent,voucher_buy_price,voucher_discount_amount,voucher_discount_percent,voucher_original_price,f_merchant_id,name_bn , voucher_details, vouchar_specification} = this.state.voucher;
        if (this.state.hasOwnProperty('cityList')) { cityList = this.state.cityList; }
        if (this.state.hasOwnProperty('districtList')) { districtList = this.state.districtList; }
        if (this.state.hasOwnProperty('thanaList')) { thanaList = this.state.thanaList; }
        if (this.state.hasOwnProperty('marchantList')) { marchantList = this.state.marchantList; }
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
     //   console.log("this.props.success", this.props.success)
        if (this.props.success != '') {
            setTimeout(function () {
                window.location.reload();
            }, 1000);

        }

        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Voucher" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader>
                                        <Link to={'/voucherCreate'}><Button className="btn btn-success pull-right">Add New Voucher</Button></Link>
                                    </CardHeader>
                                    <CardBody>
                                        <CardTitle>Voucher List</CardTitle>
                                        <div className="table-responsive-sm">
                                            <DataTabeSearch handleInputChange={this.handleInputChange} />
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Voucher Name</th>
                                                        <th>Voucher Name (Bangla)</th>
                                                        <th>Code</th>
                                                        <th>Merchant Name</th>
                                                        <th>Buy Price</th>
                                                        <th>Original Price</th>
                                                        <th>Discount amount</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th colspan="2">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {voucharListMap.map((vouchar, index) =>
                                                        <tr key={vouchar.id.toString()}>
                                                            <td scope="row">{index + 1}</td>
                                                            <td>{vouchar.name_en}</td>
                                                            <td>{vouchar.name_bn}</td>
                                                            <td>{vouchar.code}</td>
                                                            <td>{vouchar.marchant != null ? vouchar.marchant.name_en : ''}</td>
                                                            <td>{vouchar.voucher_buy_price}</td>
                                                            <td>{vouchar.voucher_original_price}</td>
                                                            <td>{vouchar.voucher_discount_amount}</td>
                                                            <td>{vouchar.voucher_start_date}</td>
                                                            <td>{vouchar.voucher_end_date}</td>
                                                            <td><button type="button"
                                                                className="btn btn-sm btn-primary waves-effect waves-light"
                                                                onClick={() => { this.tog_scroll(vouchar) }}
                                                                data-toggle="modal"
                                                            >Edit</button>
                                                            </td>
                                                            <td><button type="button"
                                                                className="btn btn-sm btn-danger waves-effect waves-light"
                                                                onClick={() => { this.Voucherdelete(vouchar.id) }}
                                                                data-toggle="modal"
                                                            >Delete</button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                        <CardFooter className="p-6">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    }
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">{name_en}</h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} >
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    Voucher Updated Successfully!
                                                </Alert>
                                            ) : null}
                                           <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="hidden" id="id" name="id" value={id}  />
                                                    <AvField className="form-control" type="text" label={reqLabel('Voucher Code')} id="code" name="code" value={code} readOnly />
                                                    <AvField className="form-control" type="text" label={reqLabel('Voucher Name')} id="name_en" name="name_en" value={name_en} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label="Voucher Name (Bangla)" type="text" name="name_bn" value={name_bn} id="name_bn" />
                                                    <AvField label={reqLabel('Merchant Name')} value={f_marchant_id} name="f_marchant_id" id="f_marchant_id" type="select" className="custom-select"  onChange={(e) => this.handleInputChange(e)} required >
                                                        <option defaultValue>Select Merchant Name</option>
                                                        {marchantList.map((marchant, index) =>
                                                           <option value={marchant.id}>{marchant.name_en}</option>
                                                        )}
                                                    </AvField>
                                                </div>
                                            </Row>
                                            <Row>
                                              
                                                    {/* <AvField name="f_category_info_id" label="Category" value={f_category_info_id} type="select"className="custom-select" onChange={e=>this.handleEditInputChange(e)}>
                                                                                <option defaultValue>Select Category</option>
                                                                            {categoryList.map((category,index)=>
                                                                                <option value={category.category_id}>{category.category_name_en}</option>
                                                                            )}
                                                    </AvField>   */}
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Voucher Discount Percent')} type="number" name="voucher_discount_percent" id="voucher_discount_percent" value={voucher_discount_percent} required />
                                                </div>

                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Voucher Original Price')} type="number" name="voucher_original_price" id="voucher_original_price" value={voucher_original_price}/>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel("Voucher Discount Amount")} type="number" name="voucher_discount_amount" id="voucher_discount_amount"  value={voucher_discount_amount} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel("Autot Commission Percent")} type="number" name="autot_commission_percent" id="autot_commission_percent" value={autot_commission_percent} required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Voucher Buy Price')} type="number" name="voucher_buy_price" id="voucher_buy_price" value={voucher_buy_price} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel("Voucher Start Date")} type="date" name="voucher_start_date" id="voucher_start_date" value={voucher_start_date} required />
                                                </div>
                                                
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Autot Commission Amount')} type="number" name="autot_commission_amount" id="autot_commission_amount" value={autot_commission_amount}  required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel("Voucher End Date")} type="date" name="voucher_end_date" id="voucher_end_date" value={voucher_end_date} required />
                                                </div>  
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField name="voucher_details" label="Description"  value={this.state.voucher_details==null?'':this.state.voucher_details}  className="form-control"  type="hidden" />
                                                                            <CKEditor
                                                                                editor={ ClassicEditorDescription }
                                                                                data={this.state.voucher_details==null?'':this.state.voucher_details}
                                                                                onReady={ editor => {
                                                                                    this.setState({
                                                                                        voucher_details :voucher_details, 
                                                                                    });
                                                                                    // You can store the "editor" and use when it is needed.
                                                                                    // console.log( 'Editor is ready to use!', editor );
                                                                                } }
                                                                                onChange={ ( event, editor ) => {
                                                                                    const data = editor.getData();
                                                                                    this.setState({
                                                                                        voucher_details :data, 
                                                                                    });
                                                                                } }
                                                                                onBlur={ ( event, editor ) => {
                                                                                    // console.log( 'Blur.', editor );
                                                                                } }
                                                                                onFocus={ ( event, editor ) => {
                                                                                    // console.log( 'Focus.', editor );
                                                                                } }
                                                                                
                                                                        />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField name="vouchar_specification" label={reqLabel('Product Specification')} value={this.state.vouchar_specification==null?'':this.state.vouchar_specification}  className="form-control"  type="hidden" required/>
                                                    <CKEditor
                                                            editor={ ClassicEditorSpecification }
                                                            data={this.state.vouchar_specification==null?'':this.state.vouchar_specification}
                                                            onReady={ editor => {
                                                                this.setState({
                                                                    vouchar_specification :vouchar_specification, 
                                                                });
                                                                // You can store the "editor" and use when it is needed.
                                                                // console.log( 'Editor is ready to use!', editor );
                                                            } }
                                                            onChange={ ( event, editor ) => {
                                                                const data = editor.getData();
                                                                this.setState({
                                                                    vouchar_specification :data, 
                                                                });
                                                            } }
                                                            onBlur={ ( event, editor ) => {
                                                                // console.log( 'Blur.', editor );
                                                            } }
                                                            onFocus={ ( event, editor ) => {
                                                                // console.log( 'Focus.', editor );
                                                            } }
                                                    />
                                                </div>
                                            </Row>



                                            <Row>
                                                <div className="col-md-6">
                                                    <label className="mb-3">is_Closed</label>
                                                    <br />
                                                    <div className="d-flex">
                                                        <div>
                                                            <Switch
                                                                name="status"
                                                                id="status"
                                                                onChange={this.handleChange}
                                                                checked={this.state.status}
                                                                className="react-switch"
                                                                id="normal-switch"
                                                            />
                                                        </div>
                                                        <div className="mt-1 mx-2"><span>{status == 1 ? ' Is Active' : ' Is Inactive'}</span>
                                                        </div>
                                                    </div>
                                                    <AvField name="status" value={status} type="hidden" />
                                                </div>
                                            </Row>

                                            {/* <Row>
                                                <div className="col-md-6">
                                                    <img src={imageLink + 'brand/' + brand_logo} alt="" height="50" />
                                                    <AvField type="hidden" value={this.state.voucharImage} name="voucharImage" id="voucharImage" />
                                                    <AvField label="Brand Image" type="file" className="form-control" name="voucharImage_0"  onChange={(e) => this.handleImageUpload(e)} id="voucharImage" />
                                                </div>
                                            </Row> */}

                                            <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                            </div>
                                        </AvForm>

                                    </div>
                                    </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.Voucher;
    return { msg, error, success };
}

// export default withRouter(connect(mapStatetoProps, { createSupplier, editSupplier, supplierError })(VoucherIndex));
export default withRouter(connect(mapStatetoProps,{deleteVoucher,editVoucher})(VoucherIndex));