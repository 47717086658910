import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_MEMBER,MEMBER_SUCCESS,EDIT_MEMBER,SHOW_MEMBER } from './actionTypes';

import { memberSuccess, memberError,memberShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createMember({ payload: { member } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'member', { 
                    member_name : member.member_name,
                    member_name_bn : member.member_name_bn,
                    relationship : member.relationship
                });

             yield put(memberSuccess(response));
          }
    } catch (error) {
        yield put(memberError(error));
    }
}


function* editMember({ payload: { member } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'member/id/'+member.member_id, { 
                    member_name : member.member_name,
                    member_name_bn : member.member_name_bn,
                    relationship : member.relationship
                });
             yield put(memberSuccess(response));
          }
          
          
    } catch (error) {
        yield put(memberError(error));
    }
}


function* showMember({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);
             yield put(memberShow(response));
          }
          
          
    } catch (error) {
        yield put(memberError(error));
    }
}

export function* watchMember() {
    yield takeEvery(EDIT_MEMBER, editMember)
    yield takeEvery(CREATE_MEMBER, createMember)
    yield takeEvery(SHOW_MEMBER, showMember)
}

function* MemberSaga() {
    yield all([
        fork(watchMember),
    ]);
}

export default MemberSaga;