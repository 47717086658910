import React, { Component } from "react";
import { Container, Row, Col, Card,Table,Button,CardFooter,Alert} from "reactstrap";

//Import Image
import {featuresShow,deletefeatures} from '../../store/homefeatures/actions';
import {CustomPagination} from "../Settings/Common/CustomPagination";
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import AvForm from "availity-reactstrap-validation/lib/AvForm";


const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }

class HomeFeaturesMapDeatils extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            featuresDetail:{

            },
        };
    }

    componentDidMount()
    {
        if (this.props.match.params.id) {
            this.fetchFeaturesDetails(this.props.match.params.id);
        }
    }

    fetchFeaturesDetails = (home_feature_id) => {

        featuresShow(home_feature_id).then((response) => {
            this.setState({
                homefeatures : response.data.homefeatures,
            });
            this.state.homefeatures = response.data.homefeatures;
        })
        .catch((err) => {
            console.log(err);
        })
    }

    deletefeatures(e, v) {
        alert('Are you sure you want to delete?');
        this.props.deletefeatures(v);
    }
    

    //modal
      removeBodyCss() {
        document.body.classList.add("no_padding");
      }


       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchFeaturesDetails();
        });
       }
       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index,

            },() => {
                this.fetchFeaturesDetails();
            });
        }

    render() {

        const {home_features_map_code,home_features_name_en,f_product_id}=this.state.featuresDetail
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }

          let paginator;
          let featuresDetails = [];
          if (this.state.hasOwnProperty('homefeatures')) {
            featuresDetails = this.state.homefeatures;
              
              paginator = {
                  'currentPage':this.state.homefeatures.current_page,
                  'pagesCount':this.state.homefeatures.last_page,
                  'total':this.state.homefeatures.total,
                  'to':this.state.homefeatures.to,
                  'from':this.state.homefeatures.from,
              }
          }

        console.log("this.props.success",this.props.success)
        
        if(this.props.success != ''){
          setTimeout(function() {
              window.location.reload();
          }, 1000);
        }

        return (
            <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Invoices" breadcrumbItem="Feature Map Details" />
                    {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                    <Row>
                        <Col lg={12}>
                        <Card>
                        <Col>
                        <div xs="4" className="mt-3 text-center">
                            <h3 className="font-size-15 font-weight-bold">Features Map Details</h3>
                        </div>
                        <AvForm>
                        <Table className="table-nowrap" style={{marginTop:'20px'}}>
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Home Features Code</th>
                                    <th>Features Name</th>
                                    <th>Product Name</th>
                                    <th>Category Name</th>
                                    <th>Brand Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {featuresDetails != undefined && featuresDetails!=null && featuresDetails.map((feature,index) =>
                                    <tr>
                                        <td scope="row">{index+1}</td>
                                        <td>{feature.home_features_map_code}</td>
                                        <td>{feature.features.home_features_name_en}</td>
                                        <td>{feature.product == null ? feature.product:feature.product.product_name_en }</td>
                                        <td>{feature.category == null ? feature.category:feature.category.category_name_en }</td>
                                        <td>{feature.brand == null ? feature.brand:feature.brand.brand_name_en }</td>
                                        <td><button type="button" className="btn btn-sm btn-primary waves-effect waves-light" onClick={(e, v) => { this.deletefeatures(e, feature.home_features_map_id) }}><i className="bx bxs-trash"></i></button></td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        </AvForm>
                        </Col>
                        {paginator &&
                            <CardFooter className="p-6">
                                <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                            </CardFooter>
                        }
                        </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.HomeFeatures;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps,{featuresShow,deletefeatures})(HomeFeaturesMapDeatils));
