import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {  marchantList ,daleteMerchant, bankList , editMarchant, editBanner ,logoUpload } from '../../store/merchant/actions';
import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class MerchantIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            marchant:{

            },
            supplier: {

            },

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchmarchantList();  
        this.fetchCityList();     
    }

    fetchmarchantList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        marchantList(options).then((response) => {
          //  console.log(response);
            this.setState({
                marchantList: response.data,
            });
            this.state.marchantList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }


    fetchCityList = () => {
        cityList().then((response) => {
            this.setState({
                cityList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    // fetchDistrictList = (city_id) => {
    //     districtList(city_id).then((response) => {
    //         this.setState({
    //             districtList: response.data,
    //         });
    //     })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    // }

    // fetchThanaList = (district_id) => {
    //     thanaList(district_id).then((response) => {
    //         this.setState({
    //             thanaList: response.data,
    //         });
    //     })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    // }
    fetchBankList = (f_bank_id) => {
        bankList(f_bank_id).then((response) => {
            this.setState({
                bankList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (marchant) => {
     //   console.log("marchant ====",marchant);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.marchant = marchant;
        this.fetchBankList(marchant.f_bank_id);
       // this.fetchThanaList(marchant.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.createSupplier(values);
        this.form && this.form.reset();
        this.fetchmarchantList();
        // alert("Supplier Create Successfully");
        // window.location.reload();
    }

    handleDeleteSubmit( values) {
        alert("Are You Want To Delete This?");
        this.fetchmarchantList();  
        daleteMerchant(values)
        .then((response) => {
          if (response.data.status == true) {
           //   console.log(response)
            alert(response.data.message);
            this.fetchmarchantList();  
            window.location.reload();
          }
          else if (response.data.status == true) {
            alert(response.data.message);
          }
        })
        .catch((err) => {
          this.props.handleErrorResponse(err);
        //  console.log('err', this.props.handleErrorResponse(err));
        });
    }

    handleImageUpload = (e) => {
        var id = e.target.id;
        var id = this.state.marchant.id;
        let files = e.target.files;

        let reader = new FileReader(); 
        reader.readAsDataURL(files[0]); 
        reader.onload = (e)  => {
                const formData = {file: e.target.result, name:files[0].name, type:files[0].type ,id:id};
          //  console.log("formData",formData);
            logoUpload(formData,formData.id);
                // sliderUpload(formData,formData.id).then((response) => {
                //     this.state.marchent_logo = response.data.marchent_logo;
                //   //  this.fetchCategoryList();     
                //     this.setState({
                //         marchent_logo : response.data.marchent_logo, 
                //     });
                //     // alert('Logo Uploaded Successfully');

                // })
                // .catch((err) => {
                //     console.log(err);
                // })
            }
       }

    //    handleEditImageSubmit(event, values) {  
    //        console.log(values);
    //     this.props.editBanner(values);
    //     this.setState(prevState => ({
    //      image_modal_scroll: !prevState.image_modal_scroll,
    //    })); 
    //  }


    handleEditSubmit(event, values) {
      //  console.log("values------------------",values);
        this.props.editMarchant(values,values.id);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchmarchantList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchmarchantList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchmarchantList();
        });
    }

    render() {
        let marchantListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let bankList = [];
        let paginator;
        const imageLink =process.env.REACT_APP_IMAGE_URL;
        const { id,marchent_logo,name_en,name_bn,code,mobile,address,email,f_bank_id,branch_name,branch_address,branch_acc_no,branch_route_id } = this.state.marchant;

        if (this.state.hasOwnProperty('marchantList')) {
            marchantListMap = this.state.marchantList.data;
            paginator = {
                'currentPage': this.state.marchantList.current_page,
                'pagesCount': this.state.marchantList.last_page,
                'total': this.state.marchantList.total,
                'to': this.state.marchantList.to,
                'from': this.state.marchantList.from,
            }
        }
       // console.log("marchantListMap----",this.state.marchant)
        if (this.state.hasOwnProperty('cityList')) { cityList = this.state.cityList; }
        if (this.state.hasOwnProperty('districtList')) { districtList = this.state.districtList; }
        if (this.state.hasOwnProperty('thanaList')) { thanaList = this.state.thanaList; }
        if (this.state.hasOwnProperty('bankList')) { bankList = this.state.bankList; }
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
      //  console.log("this.props.success", this.props.success)
        if (this.props.success != '') {
            setTimeout(function () {
                window.location.reload();
            }, 1000);

        }

        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Merchant" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader>
                                        <Link to={'/MerchantCreate'}><Button className="btn btn-success pull-right">Add New Merchant</Button></Link>
                                    </CardHeader>
                                    <CardBody>
                                        <CardTitle>Merchant List</CardTitle>
                                        <div className="table-responsive-sm">
                                            <DataTabeSearch handleInputChange={this.handleInputChange} />
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Merchant Name</th>
                                                        <th>Merchant Name (Bangla)</th>
                                                        <th>Code</th>
                                                        <th>Address</th>
                                                        <th>Mobile</th>
                                                        <th>Email</th>
                                                        <th>Bank A/C No.</th>
                                                        <th colspan="2" >Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {marchantListMap.map((marchant, index) =>
                                                        <tr key={marchant.id.toString()}>
                                                            <td scope="row">{index + 1}</td>
                                                            <td>{marchant.name_en}</td>
                                                            <td>{marchant.name_bn}</td>
                                                            <td>{marchant.code}</td>
                                                            <td>{marchant.address}</td>
                                                            <td>{marchant.mobile}</td>
                                                            <td>{marchant.email}</td>
                                                            <td>{marchant.branch_acc_no}</td>
                                                            <td><button type="button"
                                                                className="btn btn-sm btn-primary waves-effect waves-light"
                                                                onClick={() => { this.tog_scroll(marchant) }}
                                                                data-toggle="modal"
                                                                >Edit</button>
                                                            </td>
                                                            <td>    
                                                            <button type="button"
                                                                className="btn btn-sm btn-danger waves-effect waves-light"
                                                                onClick={() => { this.handleDeleteSubmit( marchant.id) }}
                                                                >Delete</button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                        <CardFooter className="p-6">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    }
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0"></h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} >
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    Merchant Updated Successfully!
                                                </Alert>
                                            ) : null}
                                            <div className="col-md-12">
                                                <AvField name="code" label="Merchant Code" value={code} className="form-control" type="text" readOnly />
                                            </div>

                                            <div className="col-md-12">
                                                {
                                                    marchent_logo != null  ?
                                                    (
                                                        <img src={imageLink+'upload/Marchant/'+marchent_logo} alt="" height="50" />
                                                    ) : null
                                                }
                                                 
                                                 <AvField  type="hidden" value={marchent_logo} name="marchent_logo" id="marchent_logo"/>
                                                 <AvField label="Banner Image" type="file" className="form-control"  name="marchent_logo_0" onChange={(e ,v) =>this.handleImageUpload(e , v)} id="marchent_logo"/>
                                            </div>

                                            <div className="col-md-12">
                                                <AvField name="name_en" label={reqLabel("Merchant Name")} value={name_en} className="form-control " type="text" required/>
                                                <AvField name="id" id="id" value={id} type="hidden" />
                                            </div>
                                            <div className="col-md-12">
                                                <AvField name="name_bn" label="Merchant Name (Bangla)" value={name_bn} className="form-control" type="text" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Merchant Address" type="text" value={address} name="address" id="supplier_address" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label={reqLabel("Merchant Mobile")} type="number" value={mobile} name="mobile" id="supplier_mobile" required/>
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label={reqLabel("Merchant Email")} type="email" value={email} name="email" id="supplier_mobile" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField type="select" className="custom-select" label={reqLabel("Bank Name")} value={f_bank_id} name="f_bank_id" id="f_division_id" >
                                                    <option defaultValue>Select Bank</option>
                                                    {bankList.map((bank, index) =>
                                                        <option value={bank.parent_bank_id}>{bank.parent_bank_name}</option>
                                                    )}
                                                </AvField>
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label={reqLabel("Bank Address")} type="text" value={branch_address} name="branch_address" id="supplier_mobile" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label={reqLabel("Bank A/C No.")} type="text" value={branch_acc_no} name="branch_acc_no" id="supplier_mobile" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label={reqLabel("Bank Route NO.")} type="number" value={branch_route_id} name="branch_route_id" id="supplier_mobile" />
                                            </div>

         
                                            <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </Modal>
                            </Col>
                            {/* <Col lg="4" md="4">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create supplier</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label={reqLabel('Supplier Name')}  id="supplier_name_en" name="supplier_name_en" required />
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Supplier Name (Bangla)"  type="text" name="supplier_name_bn" id="supplier_name_bn" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Supplier Address"  type="text" name="supplier_address" id="supplier_address" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label={reqLabel('Supplier Mobile')}  type="number" name="supplier_mobile" id="supplier_mobile" required />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField type="select" className="custom-select"  label={reqLabel('Division')}  name="f_division_id" id="f_division_id" onChange={(e) =>this.handleInputChange(e)} required >
                                                            <option defaultValue>Select Division</option>
                                                            {cityList.map((city,index)=>
                                                                <option value={city.division_id}>{city.division_name}</option>
                                                            )}
                                                     </AvField>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField type="select" className="custom-select" label={reqLabel('District')} name="f_district_id" id="f_district_id" onChange={(e) =>this.handleInputChange(e)} required >
                                                            <option defaultValue>Select District</option>
                                                            {districtList.map((district,index)=>
                                                                <option value={district.district_id}>{district.district_name}</option>
                                                            )}
                                                     </AvField>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField type="select" className="custom-select" label={reqLabel('Thana')} name="f_thana_id" id="f_thana_id" required >
                                                            <option defaultValue>Select Thana</option>
                                                            {thanaList.map((thana,index)=>
                                                                <option value={thana.thana_id}>{thana.thana_name}</option>
                                                            )}
                                                     </AvField>
                                                 </div>    
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.Merchant;
    return { msg, error, success };
}

// export default withRouter(connect(mapStatetoProps, { createSupplier, editSupplier, supplierError })(MerchantIndex));
export default withRouter(connect(mapStatetoProps,{ daleteMerchant , editMarchant, editBanner, logoUpload}  )(MerchantIndex));
