import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import UserProfile from "../pages/Authentication/UserProfile";
import CreateUserProfile from "../pages/Authentication/CreateUserProfile";
import CreateRole from "../pages/Authentication/CreateRole";
import Roles from "../pages/Authentication/Roles";
import Users from "../pages/Authentication/Users";
import CategoryCreate from "../pages/Settings/Category/CategoryCreate";
import LoanTypeCreate from "../pages/Settings/LoanTypes/LoanTypeCreate";
import MemberCreate from "../pages/Settings/Member/MemberCreate";

import IncomeExpenseTypeCreate from "../pages/Settings/IncomeExpenseType/IncomeExpenseTypeCreate";
import TransactionCreate from "../pages/Settings/Transaction/TransactionCreate";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

  // Dashboard
import Dashboard from "../pages/Dashboard/index";

//PetrolPump
import PetrolPumpList from "../pages/PetrolPump/PetrolPump/Index";
import PetrolPumpCreate from "../pages/PetrolPump/PetrolPump/Create";

import FuelList from "../pages/PetrolPump/Fuel/FuelCreate";
import FuelPumpList from "../pages/PetrolPump/FuelPumpMap/FuelPumpMapCreate";

//Product
import ProductList from "../pages/Product/Product/ProductIndex";
import ProductCreate from "../pages/Product/Product/ProductCreate";
//Product Category
import ProductCategoryList from "../pages/Product/ProductCategory/CategoryCreate";
//Product Brand
import ProductBrandList from "../pages/Product/ProductBrand/BrandCreate";
//Product Supplier
import ProductSupplierList from "../pages/Product/ProductSupplier/SupplierCreate";
//Product Warehouse
import WarehouseList from "../pages/Product/Warehouse/WarehouseCreate";
import WarehouseSupplierMapList from "../pages/Product/WarehouseSupplierMap/WarehouseSupplierMapCreate";
//Product Stock
import ProductStock from "../pages/Product/ProductStock/ProductStock";

//Banner
import BannerList from "../pages/Banner/Banner/BannerCreate";
//Order
import OrderDetails from "../pages/Order/OrderDetails";
import OrderList from "../pages/Order/OrderList";
//Home Features
import HomeFeatures from "../pages/HomeFeatures/HomeFeatures";
import HomeFeaturesMap from "../pages/HomeFeatures/HomeFeaturesMap";
import HomeFeaturesMapDetails from "../pages/HomeFeatures/HomeFeaturesMapDeatils";
import PrivacyPolicy from "../pages/Settings/PrivacyPolicy/privacyPolicy";
import CourierCreate from "../pages/Courier/Courier_Info";
import CourierPricingCreate from "../pages/Courier/CourierPricing";
import MerchantIndex from "../pages/Merchant/MerchantIndex";
import MerchantCreate from "../pages/Merchant/MerchantCreate";
import MerchantBranch from "../pages/Merchant/MerchantBranch";
import MerchantBranchCreate from "../pages/Merchant/MerchantBranchCreate";
import VoucherIndex from "../pages/Voucher/VoucherIndex";
import VoucherCreate from "../pages/Voucher/VoucherCreate";
import VoucherGenerateIndex from "../pages/Voucher/VoucherGenerateIndex";
import VoucherGenerateDetails from "../pages/Voucher/VoucherGenerateDetails";





const authProtectedRoutes = [

	{ path: "/dashboard", component: Dashboard },
	{ path: "/profile", component: UserProfile },
	{ path: "/users/create", component: CreateUserProfile },
	{ path: "/users/:id", component: CreateUserProfile },
	{ path: "/users", component: Users },
	{ path: "/create/role", component: CreateRole },
	{ path: "/roles/:id", component: CreateRole },
	{ path: "/role", component: Roles },
	// { path: "/category", component: CategoryCreate },
	// { path: "/transaction", component: TransactionCreate },
	// { path: "/incomeExpenseType", component: IncomeExpenseTypeCreate },
	// { path: "/loanType", component: LoanTypeCreate },
	// { path: "/member", component: MemberCreate },
	{ path: "/petrolPump", component: PetrolPumpList },
	{ path: "/petrolPumpCreate", component: PetrolPumpCreate },
	{ path: "/fuel", component: FuelList },
	{ path: "/fuelPumpMap", component: FuelPumpList },
	{ path: "/products", component: ProductList },
	{ path: "/productCreate", component: ProductCreate },
	{ path: "/productCategory", component: ProductCategoryList },
	{ path: "/productBrand", component: ProductBrandList },
	{ path: "/productSupplier", component: ProductSupplierList },
	{ path: "/warehouse", component: WarehouseList },
	{ path: "/warehouseSupplierMap", component: WarehouseSupplierMapList },
	{ path: "/productStock", component: ProductStock },
	{ path: "/banner", component: BannerList },
	{ path: "/order", component: OrderList },
	{ path: "/order-details/:id", component: OrderDetails },
	{ path: "/featuresCreate", component: HomeFeatures },
	{ path: "/featuresMap", component: HomeFeaturesMap },
	{ path: "/features-details/:id", component: HomeFeaturesMapDetails},
	{ path: "/privacy/policy/update/1", component: PrivacyPolicy},
	{ path: "/courierCreate", component: CourierCreate },
	{ path: "/courierPricingCreate", component: CourierPricingCreate },
	{ path: "/merchant", component: MerchantIndex },
	{ path: "/merchantCreate", component: MerchantCreate },
	{ path: "/merchantBranch", component: MerchantBranch },
	{ path: "/merchantBranchCreate", component: MerchantBranchCreate },
	{ path: "/voucher", component: VoucherIndex},
	{ path: "/voucherCreate", component: VoucherCreate},
	{ path: "/voucherGenerateList", component: VoucherGenerateIndex},
	{ path: "/voucherGenerate-details/:id", component: VoucherGenerateDetails },
	


	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },

];

export { authProtectedRoutes, publicRoutes };
