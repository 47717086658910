import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_COURIER,COURIER_SUCCESS,EDIT_COURIER,SHOW_COURIER,CREATE_COURIER_PRICING,EDIT_COURIER_PRICING } from './actionTypes';

import { courierSuccess, courierError,courierShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createCourier({ payload: { courier } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'courier/create',courier);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(courierError(response.message.message));
            }else if(response.success == true){
                yield put(courierSuccess(response.data.message));
            } 
          }
    } catch (error) {
        yield put(courierError(error));
    }
}


function* editCourier({ payload: { courier } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'courier/'+courier.id,courier);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(courierError(response.message.message));
            }else if(response.success == true){
                yield put(courierSuccess(response.data.message));
            } 
          }
          
          
    } catch (error) {
        yield put(courierError(error));
    }
}


function* showCourier({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);
             yield put(courierShow(response));
          }
          
          
    } catch (error) {
        yield put(courierError(error));
    }
}

function* createCourierPricing({ payload: { courierPricing } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'courier/pricingcreate',courierPricing);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(courierError(response.message.message));
            }else if(response.success == true){
                yield put(courierSuccess(response.data.message));
            } 
          }
    } catch (error) {
        yield put(courierError(error));
    }
}

function* editCourierPricing({ payload: { courierPricing } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'courier/pricing/'+courierPricing.id,courierPricing);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(courierError(response.message.message));
            }else if(response.success == true){
                yield put(courierSuccess(response.data.message));
            } 
          }
          
          
    } catch (error) {
        yield put(courierError(error));
    }
}

export function* watchCourier() {
    yield takeEvery(EDIT_COURIER, editCourier)
    yield takeEvery(EDIT_COURIER_PRICING, editCourierPricing)
    yield takeEvery(CREATE_COURIER, createCourier)
    yield takeEvery(CREATE_COURIER_PRICING, createCourierPricing)
    yield takeEvery(SHOW_COURIER, showCourier)
}

function* CourierSaga() {
    yield all([
        fork(watchCourier),
    ]);
}

export default CourierSaga;