import { WAREHOUSESUPPLIERMAP_ERROR,WAREHOUSESUPPLIERMAP_SUCCESS,EDIT_WAREHOUSESUPPLIERMAP,SHOW_WAREHOUSESUPPLIERMAP,CREATE_WAREHOUSESUPPLIERMAP } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createWarehouseSupplierMap = (warehouseSupplierMap) => {
    return {
        type: CREATE_WAREHOUSESUPPLIERMAP,
        payload: { warehouseSupplierMap }
    }
}

export const showWarehouseSupplierMap = (map_id) => {
    return {
        type: SHOW_WAREHOUSESUPPLIERMAP,
        payload: { map_id }
    }
}
 
export const editWarehouseSupplierMap = (warehouseSupplierMap) => {
    return {
        type: EDIT_WAREHOUSESUPPLIERMAP,
        payload: { warehouseSupplierMap }
    }
}



export const warehouseSupplierMapSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: WAREHOUSESUPPLIERMAP_SUCCESS,
        payload: msg
    }
}


export function warehouseSupplierMapShow(map_id){
    return getApi().get('users/id/'+map_id)
}


export function warehouseSupplierMapList(params){
    return getApi().get('warehouseSupplierMap/list',{
        params: params
    })
}

export const warehouseSupplierMapError = (error) => {
    return {
        type: WAREHOUSESUPPLIERMAP_ERROR,
        payload: error
    }
}
