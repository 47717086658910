import { TRANSACTION_ERROR,TRANSACTION_SUCCESS,EDIT_TRANSACTION,SHOW_TRANSACTION,CREATE_TRANSACTION } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createTransaction = (transaction) => {
    return {
        type: CREATE_TRANSACTION,
        payload: { transaction }
    }
}

export const showTransaction = (transaction_id) => {
    return {
        type: SHOW_TRANSACTION,
        payload: { transaction_id }
    }
}

export const editTransaction = (transaction) => {
    return {
        type: EDIT_TRANSACTION,
        payload: { transaction }
    }
}



export const transactionSuccess = (success) => {
    return {
        type: TRANSACTION_SUCCESS,
        payload: success
    }
}


export function transactionShow(transaction_id){
    return getApi().get('users/id/'+transaction_id)
}


export function expenseTransactionList(params){
    return getApi().get('transaction/expense',{
        params: params
    })
}

export function incomeTransactionList(params){
    return getApi().get('transaction/income',{
        params: params
    })
}

export function expenseCategoryList(){
    return getApi().get('category/expenseCategory')
}

export function incomeCategoryList(){
    return getApi().get('category/incomeCategory')
}


export function loanTypeList(){
    return getApi().get('transaction/loanTypes')
}

export function memberList(){
    return getApi().get('member')
}

export const transactionError = (error) => {
    return {
        type: TRANSACTION_ERROR,
        payload: error
    }
}
