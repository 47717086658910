import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers';
import rootSaga from './sagas';
import {setAuthorizationToken} from "../services/httpService";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);
let authData = JSON.parse(localStorage.getItem('authUser'));

if (authData != null) {
  // console.log(authData)
     setAuthorizationToken(authData.data.token);
    // store.dispatch(setCurrentUser(jwt.decode(localStorage.getItem(LOGIN_TOKEN_NAME))));
  }
export default store;