import React, { Component } from "react";
import { CardImg,Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { createCourier,editCourier,courierList} from '../../store/courier/actions';

const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }

class CourierCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                courier: {
                    
                },
                displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchcourierList();
    }

    fetchcourierList = () => {
        this.setState({
            displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        });
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        courierList(options).then((response) => {
            this.setState({
                courierList :response.data, 
            });
            this.state.courierList = response.data;
            this.setState({
                displayHide: { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            });
        })
        .catch((err) => {
            console.log(err);
            this.setState({
                displayHide: { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            });
        })
    }

    //modal
    tog_scroll = (courier) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.courier = courier;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            this.props.createCourier(values);
            this.fetchcourierList();
            this.form && this.form.reset();
            // window.location.reload(); 
        }
       
       handleEditSubmit(event, values) {  
            this.props.editCourier(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchcourierList();
            this.form && this.form.reset();
            // window.location.reload();
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchcourierList();
        });
       }

       handlePageClick = (e, index) => {
        this.setState({
            displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        });
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchcourierList();
                this.setState({
                    displayHide: { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
                });
            });
        }

    render() {
        let courierListMap = [];
        let paginator;
        if (this.state.hasOwnProperty('courierList')) {
            courierListMap = this.state.courierList;
            
            paginator = {
                'currentPage':this.state.courierList.current_page,
                'pagesCount':this.state.courierList.last_page,
                'total':this.state.courierList.total,
                'to':this.state.courierList.to,
                'from':this.state.courierList.from,
            }
        }
        const {name,email,phone,address,contact_person_address,contact_person_name,contact_person_phone,contact_person_email,id} = this.state.courier;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
          console.log("this.props.success",this.props.success)
          if(this.props.success != ''){
            setTimeout(function() {
                window.location.reload();
            }, 1000);
             
          }
        return (
            <React.Fragment>
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary" style={{ margin: '25% 50%' }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="courier" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="8" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Courier List</CardTitle>
                                        <Link to={'/courierPricingCreate'}><button className="btn btn-sm btn-primary waves-effect waves-light"  type="button">Courier Pricing</button></Link>
                                        <div className="table-responsive-sm table-responsive-mb">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Address</th>
                                                        <th>Phone</th>
                                                        <th>Contact Person Name</th>
                                                        <th>Contact Person Email</th>
                                                        <th>Contact Person Phone</th>
                                                        <th>Contact Person Address</th>
                                                        <th>action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {courierListMap.map((courier, index) =>
                                                    <tr key={courier.id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{courier.name}</td>
                                                        <td>{courier.email}</td>
                                                        <td>{courier.address}</td>
                                                        <td>{courier.phone}</td>
                                                        <td>{courier.contact_person_name}</td>
                                                        <td>{courier.contact_person_email}</td>
                                                        <td>{courier.contact_person_phone}</td>
                                                        <td>{courier.contact_person_address}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(courier) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{name}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }} ref={c => (this.form = c)}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Courier Information Updated Successfully!
                                           </Alert>
                                           ) : null}
                                           <div className="form-group ">
                                                <AvField className="form-control" type="text" label='name' name="name" value={name}/>
                                                <AvField name="id" id="id" value={id}  type="hidden"  />
                                           </div>
                                           <div className="form-group">
                                                <AvField className="form-control" type="text" label='Email' name="email" value={email}/> 
                                           </div>
                                           <div className="form-group">
                                                <AvField className="form-control" type="text" label='address' name="address" value={address}/> 
                                           </div>
                                           <div className="form-group">
                                                <AvField className="form-control" type="text" label='Phone' name="phone" value={phone}/> 
                                           </div>
                                           <div className="form-group">
                                                <AvField className="form-control" type="text" label='Contact Person Name' name="contact_person_name" value={contact_person_name}/> 
                                           </div>
                                           <div className="form-group">
                                                <AvField className="form-control" type="text" label='Contact Person Phone' name="contact_person_phone" value={contact_person_phone}/> 
                                           </div>
                                           <div className="form-group">
                                                <AvField className="form-control" type="text" label='Contact Person Email' name="contact_person_email" value={contact_person_email}/> 
                                           </div>
                                           <div className="form-group">
                                                <AvField className="form-control" type="text" label='Contact Person Address' name="contact_person_address" value={contact_person_address}/> 
                                           </div>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="4" md="2">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Courier</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label='Name' id="name" name="name" required />
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label='Email' id="email" name="email"/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label='Address' id="address" name="address" />
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label='Phone' id="phone" name="phone" required/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label='Contact Person Name' id="contact_person_name" name="contact_person_name" required />
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label='Contact Person Email' id="contact_person_email" name="contact_person_email"/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label='Contact Person Phone' id="contact_person_phone" name="contact_person_phone"/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label='Contact Person Address' id="contact_person_address" name="contact_person_address"/>
                                                 </div>
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Courier;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createCourier,editCourier,courierList })(CourierCreate));
