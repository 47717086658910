import { WAREHOUSE_ERROR,WAREHOUSE_SUCCESS,EDIT_WAREHOUSE,SHOW_WAREHOUSE,CREATE_WAREHOUSE } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createWarehouse = (warehouse) => {
    return {
        type: CREATE_WAREHOUSE,
        payload: { warehouse }
    }
}

export const showWarehouse = (warehouse_id) => {
    return {
        type: SHOW_WAREHOUSE,
        payload: { warehouse_id }
    }
}

export const editWarehouse = (warehouse) => {
    return {
        type: EDIT_WAREHOUSE,
        payload: { warehouse }
    }
}



export const warehouseSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: WAREHOUSE_SUCCESS,
        payload: msg
    }
}


export function warehouseShow(warehouse_id){
    return getApi().get('users/id/'+warehouse_id)
}


export function warehouseList(params){
    return getApi().get('warehouse/list',{
        params: params
    })
}

export const warehouseError = (error) => {
    return {
        type: WAREHOUSE_ERROR,
        payload: error
    }
}
