import React, { Component } from "react";
import {CardImg,Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createFuelPumpMap,fuelPumpMapList,editFuelPumpMap} from '../../../store/fuelPumpMap/actions';
import { fuelList} from '../../../store/fuel/actions';
import { pumpList} from '../../../store/petrolPump/actions';

const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>*</sup></>;
  }

class FuelPumpMapCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                fuel: {
                    
                },

            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchfuelPumpMapList();     
            this.fetchfuelList();     
            this.fetchPumpList();     
    }

    fetchfuelPumpMapList = () => {
        this.state.displayHide= { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        fuelPumpMapList(options).then((response) => {
            this.setState({
                fuelPumpMapList :response.data,
            });
            this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000} 
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchfuelList = () => {
        this.state.displayHide= { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000} 
        let options = {
            page: this.state.page,
            per_page: 200,
            search: this.state.search
        }
        fuelList(options).then((response) => {
            this.setState({
                fuelList :response.data.data, 
            });
            this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000} 
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchPumpList = () => {
        this.state.displayHide= { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000} 
        let options = {
            page: this.state.page,
            per_page: 200,
            search: this.state.search
        }
        pumpList(options).then((response) => {
            this.setState({
                pumpList :response.data.data, 
            });
            this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000} 
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (fuel) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.fuel = fuel;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            this.props.createFuelPumpMap(values);
            this.fetchfuelPumpMapList();     
        }

       
       handleEditSubmit(event, values) {  
            this.props.editFuelPumpMap(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchfuelPumpMapList();     
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchfuelPumpMapList();
        });
       }

       handlePageClick = (e, index) => {
        this.state.displayHide= { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000} 
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchfuelPumpMapList();
                this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000} 
            });
        }

    render() {
        let fuelPumpMapListMap = [];
        let fuelList = [];
        let pumpList = [];
        let paginator;
        if (this.state.hasOwnProperty('fuelPumpMapList')) {
            fuelPumpMapListMap = this.state.fuelPumpMapList.data;
            paginator = {
                'currentPage':this.state.fuelPumpMapList.current_page,
                'pagesCount':this.state.fuelPumpMapList.last_page,
                'total':this.state.fuelPumpMapList.total,
                'to':this.state.fuelPumpMapList.to,
                'from':this.state.fuelPumpMapList.from,
            }
        }

        if (this.state.hasOwnProperty('fuelList')) { fuelList = this.state.fuelList;}
        if (this.state.hasOwnProperty('pumpList')) { pumpList = this.state.pumpList;}

        const {fuel_pump_id,map_code,f_petrol_pump_id,f_fuel_id} = this.state.fuel;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                    <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="fuel" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Fuel List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Fuel Name</th>
                                                        <th>Fuel Name (Bangla)</th>
                                                        <th>Code</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {fuelPumpMapListMap.map((fuelPumpMAp, index) =>
                                                    <tr key={fuelPumpMAp.fuel_pump_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{fuelPumpMAp.map_code}</td>
                                                        <td>{fuelPumpMAp.pump == null?fuelPumpMAp.f_petrol_pump_id:fuelPumpMAp.pump.petrol_pump_name_bn}</td>
                                                        <td>{fuelPumpMAp.fuel == null?fuelPumpMAp.f_fuel_id:fuelPumpMAp.fuel.fuel_name_en}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(fuelPumpMAp) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">Details</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Fuel Updated Successfully!
                                           </Alert>
                                           ) : null}
                                                <div className="col-md-12">
                                                    <AvField name="map_code" label="Map Code" value={map_code} className="form-control"  type="text" />
                                                    <AvField name="fuel_pump_id"  value={fuel_pump_id} className="form-control"  type="hidden" />
                                                </div>
                                                <div className="col-md-12">
                                                     <AvField type="select"className="custom-select" label={reqLabel("Petrol Pump")} name="f_petrol_pump_id" id="f_petrol_pump_id" value={f_petrol_pump_id}>
                                                                <option defaultValue>Select Pump</option>
                                                            {pumpList.map((pump) =>
                                                                <option value={pump.petrol_pump_id}>{pump.petrol_pump_name_bn}</option>
                                                            )}
                                                    </AvField>
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField type="select"className="custom-select" label={reqLabel("Fuel")} name="f_fuel_id" id="f_fuel_id" value={f_fuel_id}>
                                                                <option defaultValue>Select Fuel</option>
                                                            {fuelList.map((fuel) =>
                                                                <option value={fuel.fuel_id}>{fuel.fuel_name_en}</option>
                                                            )}
                                                    </AvField>
                                                 </div>

                                                 <div className="text-center mt-4">
                                                        <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                        <button type="submit" className="btn btn-success save-event">Save</button>
                                                 </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Pump  Fuel Mapping</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }}>
                                                 <div className="col-md-12">
                                                     <AvField type="select"className="custom-select" label={reqLabel("Petrol Pump")} name="f_petrol_pump_id" id="f_petrol_pump_id" required>
                                                                <option defaultValue>Select Pump</option>
                                                            {pumpList.map((pump) =>
                                                                <option value={pump.petrol_pump_id}>{pump.petrol_pump_name_bn}</option>
                                                            )}
                                                    </AvField>
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField type="select"className="custom-select" label={reqLabel("Fuel")} name="f_fuel_id" id="f_fuel_id" required>
                                                                <option defaultValue>Select Fuel</option>
                                                            {fuelList.map((fuel) =>
                                                                <option value={fuel.fuel_id}>{fuel.fuel_name_en}</option>
                                                            )}
                                                    </AvField>
                                                 </div>
                                            
                                             
                                                 
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Fuel;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createFuelPumpMap,editFuelPumpMap })(FuelPumpMapCreate));
