import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createMember,memberList,editMember} from '../../../store/member/actions';

class MemberCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                member: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchmemberList();     
    }

    fetchmemberList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        memberList(options).then((response) => {
            this.setState({
                memberList :response, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (member) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.member = member;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createMember(values);
            this.fetchmemberList();     
        }

       
       handleEditSubmit(event, values) {  
            this.props.editMember(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchmemberList();     
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchmemberList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchmemberList();
            });
        }

    render() {
        let memberList = [];
        let paginator;
        if (this.state.hasOwnProperty('memberList')) {
            memberList = this.state.memberList.data;
            paginator = {
                'currentPage':this.state.memberList.currentPage,
                'pagesCount':this.state.memberList.pagesCount,
                'total':this.state.memberList.total,
                'to':this.state.memberList.to,
                'from':this.state.memberList.from,
            }
        }
        const {member_id,relationship,member_name_bn,member_name} = this.state.member;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="member" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Member List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Member Name</th>
                                                        <th>Member Name (Bangla)</th>
                                                        <th>Relation</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {memberList.map((member, index) =>
                                                    <tr key={member.member_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{member.member_name}</td>
                                                        <td>{member.member_name_bn}</td>
                                                        <td>{member.relationship}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(member) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{member_name}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Member Updated Successfully!
                                           </Alert>
                                           ) : null}
                                           <div className="form-group ">
                                               <AvField name="member_name" label="Member Name" value={member_name} className="form-control "  type="text" />
                                               <AvField name="member_id" id="member_id" value={member_id}  type="hidden"  />
                                           </div>
                                           <div className="form-group">
                                               <AvField name="member_name_bn" label="Member Name (Bangla)" value={member_name_bn} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group">
                                                <AvField type="select" className="custom-select" label="Relation" name="relationship" id="relationship" value={relationship}>
                                                        <option defaultValue>Select</option>
                                                        <option value="Friend">Friend</option>
                                                        <option value="Family">Family</option>
                                                </AvField>
                                           </div>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create member</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }}>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Member Name"  id="member_name" name="member_name" />
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Member Name (Bangla)"  type="text" name="member_name_bn" id="member_name_bn" />
                                                 </div>
                                            
                                             
                                                 <div className="form-group">
                                                    <AvField type="select"className="custom-select" label="Relation" name="relationship" id="relationship">
                                                            <option defaultValue>Select</option>
                                                            <option value="Friend">Friend</option>
                                                            <option value="Family">Family</option>
                                                    </AvField>
                                                </div>
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Member;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createMember,editMember })(MemberCreate));
