import { CREATE_MERCHANT_BRANCH,EDIT_MERCHANT_BRANCH, SHOW_MERCHANT_BRANCH, MERCHANT_BRANCH_SUCCESS, MERCHANT_BRANCH_ERROR} from './actionTypes';
import {getApi} from "../../services/httpService";

export const createMerchantBranch= (merchantBranch) => {
   // console.log("three",merchantBranch)
    return {
        type: CREATE_MERCHANT_BRANCH,
        payload: { merchantBranch }
    }
}


export const showMerchantBranch = (merchantBranch_id) => {
    return {
        type: SHOW_MERCHANT_BRANCH,
        payload: { merchantBranch_id }
    }
}

export const editMerchantBranch = (merchantBranch) => { 
    //console.log("action", merchantBranch.id);
    return {
        type: EDIT_MERCHANT_BRANCH,
        payload: { merchantBranch }
    }
}



export const merchantBranchSuccess = (msg) => {
    return {
        type: MERCHANT_BRANCH_SUCCESS,
        payload: msg
    }
}

export const merchantBranchError = (msg) => {
    return {
        type: MERCHANT_BRANCH_ERROR,
        payload: msg
    }
}

export const daleteMerchantBranch = (marchant_branch_id, params) => {
  //  console.log("two00",marchant_branch_id)
    return getApi().delete('marchant/branch/'+marchant_branch_id,{
        // params: params
    })
}

export function MerchantBranchList(params){
    return getApi().get('marchant/branch/list',{
        params: params
    })
}

export function marchantList(){
    return getApi().get('list/marchant',{
      //  params: params
    })
}

export function marchantName(id){ 
    //console.log("dataawe", id)
    return getApi().get('marchant/show/'+id,{
      //  params: params
    })
}



