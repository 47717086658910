
import {CREATE_VOUCHER,EDIT_VOUCHER,VOUCHER_SUCCESS,SHOW_VOUCHER,VOUCHER_ERROR } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createVoucher = (voucher) => {
  //  console.log("Voucher",voucher)
    return {
        type: CREATE_VOUCHER,
        payload: { voucher }
    }
}
export function deleteVoucher(voucherId){
    return getApi().delete('vouchar/destroy/'+voucherId)
}
export const showVoucher = (voucher_id) => {
    return {
        type: SHOW_VOUCHER,
        payload: { voucher_id }
    }
}

export const editVoucher = (voucher) => {
    return {
        type: EDIT_VOUCHER,
        payload: { voucher }
    }
}
export function voucharList(params){
    return getApi().get('vouchar/list',{
        params: params
    })
}

export function voucharGenerateList(params){
    //console.log("ok action")
    return getApi().get('vouchar/generates/list',{
        params: params
    })
}



export const voucherSuccess = (msg) => {
    return {
        type: VOUCHER_SUCCESS,
        payload: msg
    }
}

export const voucherError = (msg) => {
    return {
        type: VOUCHER_ERROR,
        payload: msg
    }
}

export function orderShow(order_id){
    return getApi().get('vouchar/generates/show/'+order_id)
}


