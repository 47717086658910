import { all } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
import TransactionSaga from './transaction/saga';
import CategorySaga from './category/saga';
import OrderSaga from './order/saga';
import PersonalLoanSaga from './personalLoan/saga';
import LoanTypeSaga from './loanType/saga';
import MemberSaga from './member/saga';
import PetrolPumpSaga from './petrolPump/saga';
import FuelPumpMapSaga from './fuelPumpMap/saga';
import FuelSaga from './fuel/saga';
import BrandSaga from './brand/saga';
import BannerSaga from './banner/saga';
import ProductSaga from './product/saga';
import LayoutSaga from './layout/saga';
import SupplierSaga from './productSupplier/saga';
import WarehouseSaga from "./warehouse/saga";
import WarehouseSupplierMapSaga from "./warehouseSupplierMap/saga";
import productStockSaga from "./productStock/saga";
import HomeFeaturesSaga from "./homefeatures/saga";
import PrivacyPolicySaga from "./privacyPolicy/saga";
import CourierSaga from "./courier/saga";
import RoleSaga from './auth/role/saga';
import MerchantSaga from './merchant/saga';
import MerchantBranchSaga from './merchantBranch/saga';
import VoucherSaga from './voucher/saga';

export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        AuthSaga(),
        ForgetSaga(),
        CategorySaga(),
        OrderSaga(),
        PersonalLoanSaga(),
        LoanTypeSaga(),
        MemberSaga(),
        ProfileSaga(),
        RoleSaga(),
        TransactionSaga(),
        PetrolPumpSaga(),
        FuelPumpMapSaga(),
        FuelSaga(),
        BrandSaga(),
        BannerSaga(),
        ProductSaga(),
        SupplierSaga(),
        WarehouseSaga(),
        WarehouseSupplierMapSaga(),
        productStockSaga(),
        LayoutSaga(),
        HomeFeaturesSaga(),
        PrivacyPolicySaga(),
        CourierSaga(),
        MerchantSaga(),
        MerchantBranchSaga(),
        VoucherSaga(),
    ])
}