import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  FormGroup,
  Button,
  Modal,
  Alert,
  FormText,
} from "reactstrap";

//Import Image
import logoAutoT from "../../assets/images/AutoT-Logo-Color.png";
import {
  intToEnglish,
} from "../../store/order/actions";

//voucher generate detials start
import {
  orderShow,
} from "../../store/voucher/actions";
//voucher generate detials end

import { searchProduct } from "../../store/productStock/actions";
import {
  thanaList,
  cityList,
  districtList,
} from "../../store/petrolPump/actions";
import AsyncSelect from "react-select/async";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import order from "../../store/order/reducer";

const reqLabel = (label) => {
  return (
    <>
      {label}
      <sup style={{ color: "red" }}>(*)</sup>
    </>
  );
};

class VoucherGenerateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VoucherGenerateDetails: {},
      divisionBody: {},
    };
    this.printInvoice.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.fetchorderDetails(this.props.match.params.id);
    }
    // if (this.props.match.params.id) {
    //   this.fetchconfirmOrder(this.props.match.params.id);
    // }
    // this.fetchstatusList();
    // this.fetchCityList();
  }

  printInvoice() {
    var content = document.getElementById('Order-Print').innerHTML;
    var title = document.title;
    var printWindow = window.open("", "_blank", "width=800,height=600");
    printWindow.document.open();
    printWindow.document.write('<html><head><title>' + title + '</title><style>@media print { @page {size: A4 portrait;}  footer { position: fixed; bottom: 0;} .content-block, p { page-break-inside: avoid;} html, body { width:297mm  !important; height:210mm !important; font-size:25px; } .btn{display:none !important} .table{ margin-top:50px !important;  } thead{text-align:left !important; font-size:25px;} tbody{font-size:25px;} .table-bordered th, .table-bordered td {border: 1px solid #181b1e !important; }.table th, .table td {border-top: 1px solid #EFF2F7 !important; } h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {margin-bottom: 0 !important; font-weight: 800 !important;  line-height: 1 !important;} .table th,.table td { width:297mm;  padding: 0.25rem !important; } .action-td{display:none !important} img{width:150px !important; height:150px !important;} tfoot{text-align: left;} .info{font-weight:bold; display:flex; justify-content:flex-start !important} .p_code{color:#24ADF3 !important} .autoTLogo{ width:180px!important; height:60px !important; margin-top:100px;!important; object-fit:contain!important} .line-break{color: #181b1e !important;} .p_name{text-align:left !important;} .p_total{text-align:right !important} .product_details{margin:0px 60px} .orderImg{text-align:left!important;} .order-code{margin-left:650px !important; margin-top:120px !important;} .userInfo{margin-top:50px !important;margin-left:80px !important} .In-Word{text-align:center !important}  .tfoot_info{font-size:35px!important;}.p_main_name{font-size:25px !important} </style></head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    setTimeout(function () {
      printWindow.print();
      printWindow.close();
    }, 500);
  }

  fetchorderDetails = (order_id) => {
    orderShow(order_id)
      .then((response) => { console.log("@@@@@@@@@@@@@@",response.data.VoucherGenerateList)
        this.setState({
          VoucherGenerateDetails: response.data.VoucherGenerateList,
          marchantDetails: response.data.VoucherGenerateList.marchant,

        });

        this.state.VoucherGenerateDetails = response.data.VoucherGenerateList;
        this.state.marchantDetails = response.data.VoucherGenerateList.marchant;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // fetchconfirmOrder = (order_id) => {
  //   orderConfirm(order_id)
  //     .then((response) => {
  //       this.setState({
  //         confirmOrders: response.data,
  //       });
  //       this.state.confirmOrders = response.data;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // fetchstatusList = () => {
  //   let options = {
  //     page: this.state.page,
  //     per_page: 100,
  //     search: this.state.search,
  //   };
  //   statusList(options)
  //     .then((response) => {
  //       this.setState({
  //         statusList: response.data,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  loadOptions = (inputValue, callback) => {
    searchProduct(inputValue)
      .then((response) => {
        const requestResults = response.data;

        this.state.productList = response.data;
        callback(requestResults);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleProductSearch(seletedProduct) {
    if (seletedProduct == null) {
      var product_id = seletedProduct == null ? null : seletedProduct.value;
    } else {
      var product_id = seletedProduct.value;
      let product = this.state.productList.filter(
        (obj) => obj.value === product_id
      );
      this.state.unit_price = product[0].product_buy_price;
      this.state.sale_price = product[0].product_sale_price;
      this.state.market_price = product[0].product_market_price;
      this.state.f_product_id = product_id;
      this.setState({
        stock_quantity: product[0].quantity,
      });
    }
  }
  //modal
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      // // this.fetchorderDetails();
      this.fetchconfirmOrder();
    });
    if (e.target.name == "quantity") {
      this.setState({ quantity: e.target.value });
      this.state.quantity = e.target.value;
    }
  };

  handleAddressInputChange = (e) => {
    if (e.target.name == "f_division_id") {
      this.fetchDistrictList(e.target.value);
    }
    if (e.target.name == "f_district_id") {
      this.fetchThanaList(e.target.value);
    }
  };

  handlePageClick = (e, index) => {
    e.preventDefault();
    this.setState(
      {
        page: index,
      },
      () => {
        this.fetchorderDetails();
        this.fetchconfirmOrder();
      }
    );
  };

  tog_scroll = (VoucherGenerateDetails) => {
    this.setState((prevState) => ({
      modal_scroll: !prevState.modal_scroll,
    }));
    this.state.VoucherGenerateDetails = VoucherGenerateDetails;
    this.removeBodyCss();
    this.fetchorderDetails(this.props.match.params.id);
  };

  tog_scroll1 = (address) => {
    this.setState((prevState) => ({
      modal_scroll1: !prevState.modal_scroll1,
    }));
    this.state.address = address;
    this.removeBodyCss();
  };

  handleValidSubmit(event, values) {
    this.props.createOrderStatus(values);
    this.form && this.form.reset();
  }

  handleAddProductSubmit(event, values) {
    this.props.createAddProductOrder(values);
    this.form && this.form.reset();
  }

  deleteOrder(e, v) {
    this.props.deleteOrder(v);
    this.fetchorderDetails(this.props.match.params.id);
  }

  handleAddressEditSubmit(event, values) {
    this.props.createOrderStatus(values);
    this.setState((prevState) => ({
      modal_scroll1: !prevState.modal_scroll1,
    }));
    this.form && this.form.reset();
    alert("Address Updated Successfully");
    // this.fetchorderDetails(this.props.match.params.id);
  }

  fetchCityList = () => {
    cityList()
      .then((response) => {
        this.setState({
          cityList: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchDistrictList = (city_id) => {
    districtList(city_id)
      .then((response) => {
        this.setState({
          districtList: response.data,
        });
        console.log("ssf", districtList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchThanaList = (district_id) => {
    thanaList(district_id)
      .then((response) => {
        this.setState({
          thanaList: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      id,
      user_name,
      user_email,
      user_phone,
      user_address,
      status,
      code,
      order_type,
      insert_date_time,
      f_user_id,
      voucher_name,
      voucher_buy_price,
      final_amount,
      f_marchant_id,
      name_en,
      is_closed
    } = this.state.VoucherGenerateDetails;

    const imageLink = process.env.REACT_APP_IMAGE_URL;
    const getBadge = (status) => {
      return status == 1 ? "success" : "secondary";
    };
    const getStatusValue = (status) => {
      return status == 1 ? "Active" : "In-active";
    };

    if (this.props.success != "") {
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
    let orderDetailsMap = [];
    if (this.state.hasOwnProperty("VoucherGenerateDetails")) {
      orderDetailsMap = this.state.VoucherGenerateDetails; console.log("orderDetailsMap",orderDetailsMap)
    }

    let divisionMap = [];
    if (this.state.hasOwnProperty("divisionBody")) {
      divisionMap = this.state.divisionBody;
    }

    let districtMap = [];
    if (this.state.hasOwnProperty("districtBody")) {
      districtMap = this.state.districtBody;
    }

    let thanaMap = [];
    if (this.state.hasOwnProperty("thanaBody")) {
      thanaMap = this.state.thanaBody;
    }

    let statusListMap = [];
    if (this.state.hasOwnProperty("statusList")) {
      statusListMap = this.state.statusList;
    }

    let productList = [];
    if (this.state.hasOwnProperty("productList")) {
      productList = this.state.productList;
    }

    let confirmOrderStatus = [];
    if (this.state.hasOwnProperty("confirmOrders")) {
      confirmOrderStatus = this.state.confirmOrders;
    }

    let cityList = [];
    let districtList = [];
    let thanaList = [];
    if (this.state.hasOwnProperty("cityList")) {
      cityList = this.state.cityList;
    }
    let marchantListDetails=[];
    if (this.state.hasOwnProperty("marchantDetails")) {
      marchantListDetails = this.state.marchantDetails;
    }

    console.log("marchantListDetails",marchantListDetails);

    console.log("cityList", cityList);
    if (this.state.hasOwnProperty("districtList")) {
      districtList = this.state.districtList;
    }
    console.log("districtList", districtList);
    if (this.state.hasOwnProperty("thanaList")) {
      thanaList = this.state.thanaList;
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Invoices" breadcrumbItem="Detail" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="Order-Print">
                      <div className="invoice-title d-flex justify-content-between info">
                        <img src={logoAutoT} alt="logo" height="50" className="autoTLogo" />
                        <h4 className="font-size-16 mt-2 order-code">
                          Voucher Code : {code}
                        </h4>
                      </div>
                      <hr />
                      <Row className="info">
                        <Col xs="6" md="6">
                          <p className="font-weight-bold mb-2">Billed To:</p>
                          <p className="mb-2 font-weight-bold"> Mr./Mrs. <span className="text-primary p_code">{user_name}</span></p>
                          <p className="mb-2 font-weight-bold">Phone : <span className="text-primary p_code"> {user_phone}</span></p>
      
                        </Col>
                        <Col xs="6" md="6" className="userInfo" >
                          <p className="mb-2 font-weight-bold mt-4"> Payment Method: <span className="text-primary p_code" style={{ color: "red" }}>{order_type}</span></p>
                          <p className="mb-2 font-weight-bold"> Current Status: <span className="text-primary p_code" style={{ color: "blue" }}>  {status == 1 ? 'confirm' : '' }</span></p>
                          <p className="mb-2 font-weight-bold"> purchase Date: <span className="text-primary p_code" style={{ color: "red" }}> {insert_date_time}</span></p>
                        </Col>
                      </Row>

                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          Voucher summary{" "}
                        </h3>
                                 
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <th>Code</th>
                              <th>Name</th>
                              <th>Marchant Name</th>
                              <th>Status</th>
                              <th className="text-right">Voucher Price</th>
                              <th className="text-right">Paid Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            
                                  <tr>
                                    <td>{code}</td>
                                    <td>
                                      {voucher_name} 
                                    </td>
                                    <td>
                                      {marchantListDetails.name_en} 
                                    </td>
                                    <td>
                                      {is_closed == 1 ? 'used' : 'not used'} 
                                    </td>
                                    <td className="border-0 text-right">
                                      {voucher_buy_price} BDT
                                    </td>
                                    <td className="border-0 text-right">
                                      {final_amount} BDT
                                    </td>
                                  </tr>
                               
                            <tr>
                              <td colSpan="5" className="text-right p_total">
                                Sub Total
                              </td>
                              <td className="text-right">
                                {final_amount} BDT
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0 text-right">
                                {/* {total_delivery_charge} BDT */}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="5" className="border-0 text-right p_total">
                                <strong>Total</strong>
                              </td>
                              <td className="border-0 text-right">
                                <h4 className="m-0">
                                  {final_amount} BDT
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <h5 className="text-center In-Word" style={{ color: "#239DD1" }}>
                          <b style={{ color: "black" }}>In Word :</b>{" "}
                          {intToEnglish(final_amount)}
                        </h5>
                      </div>
                    </div>
                    <div className="d-print-none">
                      <div className="float-right">
                        <Link
                          to="#"
                          onClick={this.printInvoice}
                          className="btn btn-success waves-effect waves-light mr-2"
                        >
                          <i className="fa fa-print"></i>
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  {/* <Col lg="12">
                    <AvForm
                      style={{ marginTop: "20px" }}
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        this.handleValidSubmit(e, v);
                      }}
                      ref={(c) => (this.form = c)}
                    >
                      <AvField
                        type="select"
                        className="custom-select"
                        value={orderDetailsMap.f_action_status}
                        label="Status List Type"
                        name="f_status_id"
                        id="f_status_id"
                      >
                        <option>Status List Type</option>
                        {statusListMap.map((order_status, index) => (
                          <option value={order_status.id}>
                            {order_status.action_name}
                          </option>
                        ))}
                      </AvField>
                      <div className="form-group">
                        <AvField
                          name="remarks"
                          label="remarks"
                          value={remarks}
                          className="form-control"
                          type="textarea"
                        />
                      </div>

                      <div className="form-group ">
                        <AvField
                          name="f_user_id"
                          value={f_user_id}
                          type="hidden"
                        />
                      </div>

                      <div className="form-group ">
                        <AvField
                          name="f_order_id"
                          value={id}
                          type="hidden"
                        />
                      </div>

                      <div className="form-group ">
                        <AvField
                          name="f_status_id"
                          value={status}
                          type="hidden"
                        />
                      </div>

                      <div className="float-right">
                        <Button type="submit" color="success">
                          Submit
                        </Button>
                      </div>
                    </AvForm>
                  </Col> */}
                  {/* <Col>
                    <div xs="6" className="mt-3 text-center">
                      <h3 className="font-size-15 font-weight-bold">
                        Order Confirmation Status
                      </h3>
                    </div>
                    <Table
                      className="table-nowrap"
                      style={{ marginTop: "20px" }}
                    >
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Order Id</th>
                          <th>Date and Time</th>
                          <th>Order Process By</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {confirmOrderStatus != undefined &&
                          confirmOrderStatus != null &&
                          confirmOrderStatus.map((confirm, index) => (
                            <tr>
                              <td>
                                {confirm.status == null
                                  ? confirm.order_current_status_id
                                  : confirm.order_current_status.action_name}
                              </td>
                              <td>{confirm.f_order_id}</td>
                              <td>{confirm.insert_date_time}</td>
                              <td>{confirm.insert_user.name}</td>
                              <td>{confirm.remarks}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    );
  }
}

const mapStatetoProps = (state) => {
  const { msg, error, success } = state.Order;
  return { msg, error, success };
};

export default withRouter(
  connect(mapStatetoProps, {
    
    orderShow,
    
  })(VoucherGenerateDetails)
);
