import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_SUPPLIER,SUPPLIER_SUCCESS,EDIT_SUPPLIER,SHOW_SUPPLIER } from './actionTypes';

import { supplierSuccess, supplierError,supplierShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createSupplier({ payload: { supplier } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'supplier/create',supplier);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(supplierError(response.message.message));
            }else if(response.success == true){
                yield put(supplierSuccess(response.data.message));
            }
          }
    } catch (error) {
        yield put(supplierError(error));

    }
}


function* editSupplier({ payload: { supplier } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'supplier/'+supplier.supplier_id, supplier);
             yield put(supplierSuccess(response));
          }
          
          
    } catch (error) {
        yield put(supplierError(error));
    }
}


function* showSupplier({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);
             yield put(supplierShow(response));
          }
          
          
    } catch (error) {
        yield put(supplierError(error));
    }
}

export function* watchSupplier() {
    yield takeEvery(EDIT_SUPPLIER, editSupplier)
    yield takeEvery(CREATE_SUPPLIER, createSupplier)
    yield takeEvery(SHOW_SUPPLIER, showSupplier)
}

function* SupplierSaga() {
    yield all([
        fork(watchSupplier),
    ]);
}

export default SupplierSaga;