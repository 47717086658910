import React, { Component } from "react";
import { CardImg,Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal,FormText,Label,Input ,FormGroup,CustomInput} from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createProductStock,productStockList,editProductStock,searchProduct,searchSupplier} from '../../../store/productStock/actions';
import { createPump,pumpList,editPump,thanaList,cityList,districtList} from '../../../store/petrolPump/actions';
import { createWarehouse,warehouseList,editWarehouse} from '../../../store/warehouse/actions';
import { createSupplier,supplierList,editSupplier} from '../../../store/productSupplier/actions';
import AsyncSelect from "react-select/async";

const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }

class ProductStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                is_receive_back: false,
                is_receive: true,
                productStock: {
                    
                },
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchproductStockList();  
            this.fetchwarehouseList();     
            this.fetchCityList();
            this.fetchsupplierList();     
    }


    fetchwarehouseList = () => {
        let options = {
            page: this.state.page,
            per_page: 50,
            search: this.state.search
        }
        warehouseList(options).then((response) => {
            this.setState({
                warehouseList :response.data.data, 
            });
            this.state.warehouseList = response.data.data;
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchsupplierList = () => {
        let options = {
            page: this.state.page,
            per_page: 50,
            search: this.state.search
        }
        supplierList(options).then((response) => {
            this.setState({
                supplierList :response.data.data, 
            });
            this.state.supplierList = response.data.data;
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchproductStockList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        productStockList(options).then((response) => {
            this.setState({
                productStockList :response.data, 
            });
            this.state.productStockList = response.data;
        })
        .catch((err) => {
            console.log(err);
        })
    }


    fetchCityList = () => {
        cityList().then((response) => {
            this.setState({
                cityList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchDistrictList = (city_id) => {
        districtList(city_id).then((response) => {
            this.setState({
                districtList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchThanaList = (district_id) => {
        thanaList(district_id).then((response) => {
            this.setState({
                thanaList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (productStock) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.productStock = productStock;
        this.state.productStock.old_item_quantity = productStock.item_quantity;
        this.state.productStock.product_stock_balance = productStock.stock_quantity;
        this.state.productStock.editProduct = { label: productStock.product.product_name_en , value: productStock.product.product_id};
        // this.fetchWarehouseSupplierList(productStock.f_warehouse_id);
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            var values = Object.entries(values).reduce((a,[k,v]) => (v == null || v == '' ? a : (a[k]=v, a)), {})
            this.props.createProductStock(values);
            this.fetchproductStockList();
            window.location.reload();     
        }

       
       handleEditSubmit(event, values) {  
            var values = Object.entries(values).reduce((a,[k,v]) => (v == null || v == '' ? a : (a[k]=v, a)), {})
            this.props.editProductStock(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchproductStockList();     
       }

      
        handleInputChange = (e) => {
            
            this.setState({[e.target.name]: e.target.value},() => {
                this.fetchproductStockList();
            });

            if(e.target.name == 'item_quantity' ){
                this.setState({item_quantity: e.target.value});
                this.state.item_quantity = e.target.value;
            }

            if(e.target.id == 'is_receive' && e.target.value == 'on'){
                this.setState({is_receive: true});
                this.setState({is_receive_back: false});
                this.state.is_receive = true;
                this.state.is_receive_back = false;
            }

            if(e.target.id == 'is_receive_back' && e.target.value == 'on' ){
                this.setState({is_receive_back: true});
                this.setState({is_receive: false});
                this.state.is_receive = false;
                this.state.is_receive_back = true;
            }

            // if(e.target.name == 'f_warehouse_id'){
            //     this.fetchWarehouseSupplierList(e.target.value);
            // }

            
            if(this.state.is_receive == true){
                this.setState({ stock_quantity: this.state.product_stock_balance*1  + this.state.item_quantity*1});
                this.state.stock_quantity = this.state.product_stock_balance*1  + this.state.item_quantity*1;
            }else if(this.state.is_receive_back == true){
                this.setState({ stock_quantity: this.state.product_stock_balance*1  - this.state.item_quantity*1});
                this.state.stock_quantity = this.state.product_stock_balance*1  - this.state.item_quantity*1;
            }

        }

        updateStockObject = (name, value) => {
            let productStockCopy= Object.assign({}, this.state.productStock);
            productStockCopy[name] = value;
    
            this.setState({
                productStock: productStockCopy
            });
            this.state.productStock = productStockCopy;
        }
        
        handleEditInputChange = (e) => {
            
            this.updateStockObject(e.target.name, e.target.value);

            if(e.target.name == 'item_quantity' ){
                this.updateStockObject('item_quantity', e.target.value);
                this.state.productStock.item_quantity = e.target.value;
            }

            if(e.target.id == 'is_receive' && e.target.value == 'on'){
                this.updateStockObject('is_receive', true);
                this.updateStockObject('is_receive_back', false);
                this.state.productStock.is_receive = true;
                this.state.productStock.is_receive_back = false;
            }

            if(e.target.id == 'is_receive_back' && e.target.value == 'on' ){
                this.updateStockObject('is_receive', false);
                this.updateStockObject('is_receive_back', true);
                this.state.productStock.is_receive = false;
                this.state.productStock.is_receive_back = true;
            }

            // if(e.target.name == 'f_warehouse_id'){
            //     this.fetchWarehouseSupplierList(e.target.value);
            // }

            
            if(this.state.productStock.is_receive == true){
                this.updateStockObject('stock_quantity', this.state.productStock.product_stock_balance*1 - this.state.productStock.old_item_quantity*1 + this.state.productStock.item_quantity*1);
                this.state.productStock.stock_quantity = this.state.productStock.product_stock_balance*1 - this.state.productStock.old_item_quantity*1 + this.state.productStock.item_quantity*1;
                console.log('error',this.state.productStock.old_item_quantity);
            }else if(this.state.productStock.is_receive_back == true){
                this.updateStockObject('stock_quantity', this.state.productStock.product_stock_balance*1 + this.state.productStock.old_item_quantity*1 - this.state.productStock.item_quantity*1);
                this.state.productStock.stock_quantity = this.state.productStock.product_stock_balance*1 + this.state.productStock.old_item_quantity*1 - this.state.productStock.item_quantity*1;
            }

        }


        loadOptions = (inputValue, callback) => {

            searchProduct(inputValue).then((response) => {
    
                const requestResults = response.data;
    
                this.state.productList = response.data;
                callback(requestResults);
            })
            .catch((err) => {
                console.log(err);
            })
            
          }
          
        //   fetchWarehouseSupplierList = (wareshouse_id ) => {
        //     searchSupplier(wareshouse_id).then((response) => {
        //         this.state.supplierList = response.data.warehouseSupplierMap;
        //         this.setState({
        //             supplierList: response.data.warehouseSupplierMap
        //         });

        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     })
            
        //   }

        handleProductSearch( seletedProduct){
            if(seletedProduct == null){
                var product_id = (seletedProduct == null)?null:seletedProduct.value;
            }else{
            var product_id = seletedProduct.value;
            let product = this.state.productList.filter(obj=>obj.value ===product_id);
            this.state.stock_quantity = product[0].product_stock_balance;
            this.state.buy_price = product[0].product_buy_price;
            this.state.sale_price = product[0].product_sale_price;
            this.state.market_price = product[0].product_market_price;
            this.state.f_product_id = product_id;
                this.setState({
                    stock_quantity : product[0].product_stock_balance,
                    product_stock_balance : product[0].product_stock_balance,
                })
            }
        }

        

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchproductStockList();
            });
        }

    render() {
        let productStockListMap = [];
        let productList = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let supplierList = [];
        let warehouseList = [];
        let paginator;
        if (this.state.hasOwnProperty('productStockList')) {
            productStockListMap = this.state.productStockList.data;
            paginator = {
                'currentPage':this.state.productStockList.current_page,
                'pagesCount':this.state.productStockList.last_page,
                'total':this.state.productStockList.total,
                'to':this.state.productStockList.to,
                'from':this.state.productStockList.from,
            }
        }
        const {stock_id,f_supplier_id,f_product_id,f_warehouse_id,buy_price,item_quantity,stock_quantity,sale_price,market_price,total_buy_price,is_receive,is_receive_back,stock_date,remarks,editProductList,editProduct,product_name_en} = this.state.productStock;
        if(this.state.hasOwnProperty('cityList')){cityList = this.state.cityList;}
        if(this.state.hasOwnProperty('districtList')){districtList = this.state.districtList;}
        if(this.state.hasOwnProperty('thanaList')){thanaList = this.state.thanaList;}
        if(this.state.hasOwnProperty('supplierList')){supplierList = this.state.supplierList;}
        if(this.state.hasOwnProperty('warehouseList')){warehouseList = this.state.warehouseList;}
        if(this.state.hasOwnProperty('productList')){productList = this.state.productList;}

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Product" breadcrumbItem="Stock" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create New Stock</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }}>

                                                 <div className="col-md-12">
                                                        <FormGroup>
                                                            <div>
                                                                <CustomInput type="radio" id="is_receive" name="stock_type" label="Receive" onChange={e=>this.handleInputChange(e)} defaultChecked />
                                                                <CustomInput type="radio" id="is_receive_back" name="stock_type" label="Return" onChange={e=>this.handleInputChange(e)} />
                                                                <AvField type="hidden" name="is_receive" id="is_receive" value={this.state.is_receive} />
                                                                <AvField  type="hidden" name="is_receive_back" id="is_receive_back" value={this.state.is_receive_back} />
                                                            </div>
                                                        </FormGroup>

                                                 </div>

                                                <div className="col-md-12">
                                                    <Row>
                                                        <Col lg="6" md="6">
                                                            <label>Product</label>
                                                            <AsyncSelect 
                                                                loadOptions={this.loadOptions}
                                                                onChange={(value) => this.handleProductSearch(value)}
                                                                isClearable={true}
                                                                cacheOptions
                                                                isSearchable={true}
                                                                defaultOptions={productList}
                                                            />
                                                            <FormText style={{background: 'yellow'}}>Search By Product Name </FormText>
                                                            <AvField   type="hidden" value={this.state.f_product_id} name="f_product_id" id="f_product_id" required/>

                                                        </Col>
                                                        <Col lg="6" md="6">
                                                            <AvField className="form-control" label={reqLabel("Current Stock")}  type="number" name="stock_quantity" id="stock_quantity" value={this.state.stock_quantity*1 } readOnly={true}/>
                                                        </Col>

                                                    </Row>
                                                 </div>
                                             
                                                 

                                                 <div className="col-md-12">
                                                        <Row>
                                                        <Col lg="6" md="6">
                                                                <AvField className="form-control" label={reqLabel("Quantity")}  type="number" name="item_quantity" id="item_quantity" onChange={e=>this.handleInputChange(e)} required/>
                                                        </Col>
                                                        <Col lg="6" md="6">
                                                                <AvField className="form-control" label={reqLabel("Buy Price")}  type="number" name="buy_price" id="buy_price" value={this.state.buy_price} onChange={e=>this.handleInputChange(e)} required/>
                                                            </Col>
                                                        </Row>
                                                 </div>

                                                 <div className="col-md-12">
                                                    <Row>
                                                        <Col lg="6" md="6">
                                                                <AvField className="form-control" label="Sale Price"  type="number" name="sale_price" id="sale_price" value={this.state.sale_price} onChange={e=>this.handleInputChange(e)} />
                                                        </Col>
                                                        <Col lg="6" md="6">
                                                                <AvField className="form-control" label="Market Price"  type="number" name="market_price" id="market_price" value={this.state.market_price} onChange={e=>this.handleInputChange(e)} />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                 <div className="col-md-12">
                                                        <Row>
                                                            <Col lg="6" md="6">
                                                                <AvField className="form-control" label={reqLabel("Total Price")}  type="number" name="total_buy_price" id="total_buy_price"  value={this.state.item_quantity*this.state.buy_price} readOnly={true}/>
                                                            </Col>
                                                            <Col lg="6" md="6">
                                                                <AvField className="form-control" label="Date"  type="date" name="stock_date" id="stock_date" value={new Date().toISOString().substr(0,10)} />
                                                            </Col>
                                                        </Row>
                                                 </div>

                                                 <div className="col-md-12">
                                                    <Row>
                                                        <Col lg="6" md="6">
                                                            <AvField type="select" className="custom-select" label={reqLabel("Warehouse")}   name="f_warehouse_id" id="f_warehouse_id"  required >
                                                                    <option defaultValue>Select Warehouse</option>
                                                                    {warehouseList.map((warehouse,index)=>
                                                                        <option value={warehouse.warehouse_id}>{warehouse.warehouse_name_en}</option>
                                                                    )}
                                                            </AvField>
                                                        </Col>
                                                        <Col lg="6" md="6">
                                                            <AvField type="select" className="custom-select" label={reqLabel("Supplier")}   name="f_supplier_id" id="f_supplier_id"  required >
                                                                <option defaultValue>Select Supplier</option>
                                                                {supplierList.map((supplier,index)=>
                                                                    <option value={supplier.supplier_id}>{supplier.supplier_name_en}</option>
                                                                )}
                                                            </AvField>
                                                        </Col>
                                                    </Row>
                                                 </div>

                                                 
                                            
                                                 <div className="col-md-12">
                                                        <AvField className="form-control" label="Remarks"  type="textarea" name="remarks" id="remarks"  />
                                                 </div>
                                                 
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Stock List</CardTitle>
                                        <div className="table-responsive">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Code</th>
                                                        <th>Type</th>
                                                        <th>Product</th>
                                                        <th>Quantity</th>
                                                        <th>Current Stock</th>
                                                        <th>Buy Price</th>
                                                        <th>Warehouse</th>
                                                        <th>Supplier</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {productStockListMap.map((productStock, index) =>
                                                    <tr key={productStock.stock_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{productStock.stock_date}</td>
                                                        <td>{productStock.stock_code}</td>
                                                        <td><Badge color={(productStock.is_receive == 1)?'success':'danger'}>{(productStock.is_receive == 1)?'Receive':'Return'}</Badge></td>
                                                        <td>{productStock.product == null? productStock.f_product_id:productStock.product.product_name_en }</td>
                                                        <td>{productStock.item_quantity}</td>
                                                        <td>{productStock.stock_quantity}</td>
                                                        <td>{productStock.buy_price}</td>
                                                        <td>{productStock.supplier == null?productStock.f_supplier_id:productStock.supplier.supplier_name_en}</td>
                                                        <td>{productStock.warehouse == null?productStock.f_warehouse_id:productStock.warehouse.warehouse_name_en}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(productStock) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">Edit</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>

                                                 <div className="col-md-12">
                                                        <FormGroup>
                                                            <div>
                                                                <CustomInput type="radio" id="is_receive" name="edit_stock_type" label="Receive" onChange={e=>this.handleEditInputChange(e)} checked={is_receive}  disabled/>
                                                                <CustomInput type="radio" id="is_receive_back" name="edit_stock_type" label="Return" onChange={e=>this.handleEditInputChange(e)} checked={is_receive_back}  disabled />
                                                                <AvField type="hidden" name="is_receive" id="is_receive" value={is_receive} />
                                                                <AvField  type="hidden" name="is_receive_back" id="is_receive_back" value={is_receive_back} />
                                                                <AvField  type="hidden" name="stock_id" id="stock_id" value={stock_id} />
                                                            </div>
                                                        </FormGroup>

                                                 </div>

                                                <div className="col-md-12">
                                                    <Row>
                                                        <Col lg="6" md="6">
                                                            <label>Product</label>
                                                            <AsyncSelect 
                                                                defaultValue={editProduct}
                                                            />
                                                            <FormText style={{background: 'yellow'}}>Search By Product Name </FormText>
                                                            <AvField   type="hidden" value={f_product_id} name="f_product_id" id="f_product_id" required/>

                                                        </Col>
                                                        <Col lg="6" md="6">
                                                            <AvField className="form-control" label={reqLabel("Current Stock")}  type="number" name="stock_quantity" id="stock_quantity" value={stock_quantity} readOnly={true}/>
                                                        </Col>
                                                    </Row>
                                                 </div>
                                             
                                                 

                                                 <div className="col-md-12">
                                                        <Row>
                                                            <Col lg="6" md="6">
                                                                <AvField className="form-control" label={reqLabel("Quantity")}  type="number" name="item_quantity" id="item_quantity" value={item_quantity} onChange={e=>this.handleEditInputChange(e)} required/>
                                                            </Col>
                                                            <Col lg="6" md="6">
                                                                <AvField className="form-control" label={reqLabel("Buy Price")}  type="number" name="buy_price" id="buy_price" onChange={e=>this.handleEditInputChange(e)} value={buy_price} required/>
                                                            </Col>
                                                        </Row>
                                                 </div>

                                                 <div className="col-md-12">
                                                        <Row>
                                                            <Col lg="6" md="6">
                                                                <AvField className="form-control" label={reqLabel("Total Price")}  type="number" name="total_buy_price" id="total_buy_price"  value={item_quantity*buy_price} readOnly={true}/>
                                                            </Col>
                                                            <Col lg="6" md="6">
                                                                <AvField className="form-control" label="Date"  type="date" name="stock_date" id="stock_date" value={stock_date == null?'':new Date(stock_date).toISOString().substr(0,10)} />
                                                            </Col>
                                                        </Row>
                                                 </div>
                                                 <div className="col-md-12">
                                                        <Row>
                                                            <Col lg="6" md="6">
                                                                <AvField className="form-control" label="Sale Price"  type="number" name="sale_price" id="sale_price"  value={sale_price}/>
                                                            </Col>
                                                            <Col lg="6" md="6">
                                                                <AvField className="form-control" label="Market Price"   type="number" name="market_price" id="market_price" value={market_price} />
                                                            </Col>
                                                        </Row>
                                                 </div>

                                                 <div className="col-md-12">
                                                    <Row>
                                                        <Col lg="6" md="6">
                                                            <AvField type="select" className="custom-select" label={reqLabel("Warehouse")}   name="f_warehouse_id" id="f_warehouse_id"  value={f_warehouse_id} required >
                                                                    <option defaultValue>Select Warehouse</option>
                                                                    {warehouseList.map((warehouse,index)=>
                                                                        <option value={warehouse.warehouse_id}>{warehouse.warehouse_name_en}</option>
                                                                    )}
                                                            </AvField>
                                                        </Col>
                                                        <Col lg="6" md="6">
                                                            <AvField type="select" className="custom-select" label={reqLabel("Supplier")}   name="f_supplier_id" id="f_supplier_id" value={f_supplier_id} required >
                                                                <option defaultValue>Select Supplier</option>
                                                                {supplierList.map((supplier,index)=>
                                                                    <option value={supplier.supplier_id}>{supplier.supplier_name_en}</option>
                                                                )}
                                                            </AvField>
                                                        </Col>
                                                    </Row>
                                                 </div>

                                                 
                                            
                                                 <div className="col-md-12">
                                                        <AvField className="form-control" label="Remarks"  type="textarea" name="remarks" value={remarks} id="remarks"  />
                                                 </div>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ProductStock;
    console.log('sssss',state.ProductStock);
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createProductStock,editProductStock })(ProductStock));
