import { ROLE_ERROR,ROLE_SUCCESS,EDIT_ROLE } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const Role = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_ROLE:
            state = { ...state }
            break;
        case ROLE_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case ROLE_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Role;