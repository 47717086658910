import React, { Component } from "react";
// import { Card, CardBody, Col, Row, , ,Container,,,,, } from "reactstrap";
import { CardImg,Row, Col, Card, CardBody,CardTitle,Alert,Button,Table,Modal,CardFooter,Badge, TabContent, TabPane, NavItem, NavLink, Label , Input, Form, FormGroup, Progress, Container, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
import classnames from 'classnames';
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createPump,pumpList,editPump,pumpShow} from '../../../store/petrolPump/actions';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                activeTab: 1,
                activeTabProgress: 1,
                progressValue : 25,
                pump: {
                    owner: [],
                    bank_account: []
                }
            }
              this.toggleTab.bind(this);
              this.toggleTabProgress.bind(this);
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
        this.fetchpumpList();   
    }

    fetchpumpList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        pumpList(options).then((response) => {
            this.setState({
                pumpList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchpumpData = (petrol_pump_id) => {
        pumpShow(petrol_pump_id).then((response) => {
            this.setState({
                pump :response.data.registeredPetrolPump, 
                bank :response.data.bank, 
                owner :response.data.owner, 
            });
        console.log('sss',this.state); 

        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (pump) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
          }));
        this.setState({
            pump : pump, 
        });
        // this.fetchpumpData(pump.petrol_pump_id);
        this.removeBodyCss();
      }

        toggleTab(tab) {
            if (this.state.activeTab !== tab) {
                if(tab >= 1 && tab <=4 ){
                    this.setState({
                        activeTab: tab
                    });
                }
            }
        }

        toggleTabProgress(tab) {
            if (this.state.activeTabProgress !== tab) {
                if(tab >= 1 && tab <=6 ){
                    this.setState({
                    activeTabProgress: tab
                    });

                if(tab === 1) { this.setState({progressValue : 17}) }
                if(tab === 2) { this.setState({progressValue : 34}) }
                if(tab === 3) { this.setState({progressValue : 50}) }
                if(tab === 4) { this.setState({progressValue : 67}) }
                if(tab === 5) { this.setState({progressValue : 84}) }
                if(tab === 6) { this.setState({progressValue : 100}) }
                }
            }
        }

    
        removeBodyCss() {
            document.body.classList.add("no_padding");
        }

      // handleValidSubmit
        

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchpumpList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchpumpList();
            });
        }

    render() {
        let pumpListMap = [];
        let paginator;
        if (this.state.hasOwnProperty('pumpList')) {
            pumpListMap = this.state.pumpList.data;
            paginator = {
                'currentPage':this.state.pumpList.currentPage,
                'pagesCount':this.state.pumpList.pagesCount,
                'total':this.state.pumpList.total,
                'to':this.state.pumpList.to,
                'from':this.state.pumpList.from,
            }
        }
        const {petrol_pump_id,f_division_id,f_zilla_id,f_thana_id,petrol_pump_name_en,pump_manager_name,pump_manager_mobile,pump_addess_en,tin_number,trade_license_number,vat_registration_number,division,district,thana,owner,bank_account} = this.state.pump;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          } 
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Petrol Pump" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12">
                               
                                    <Card>
                                    <CardHeader>
                                        <Link to={'/petrolPumpCreate'}><Button className="btn btn-success pull-right">Create New Petrol Pump Account</Button></Link>
                                    </CardHeader>
                                    <CardBody>
                                        <CardTitle>Petrol Pump List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Petrol Pump Name</th>
                                                        <th>Manager Name </th>
                                                        <th>Mobile No</th>
                                                        <th>Address</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {pumpListMap.map((pump, index) =>
                                                    <tr key={pump.petrol_pump_id.toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{pump.petrol_pump_name_en}</td>
                                                        <td>{pump.pump_manager_name}</td>
                                                        <td>{pump.pump_manager_mobile}</td>
                                                        <td>{pump.pump_addess_en}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-success waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(pump) }} 
                                                               data-toggle="modal">
                                                                VIEW
                                                             </button>
                                                        </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">PETROL PUMP DETAILS</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                        <section>
                                            <div class="media-body">
                                                <h1 >{petrol_pump_name_en}</h1>
                                            </div>
                                        </section>

                                        <section>
                                        <div class="row">
                                            <div class="col-6">
                                            <div class=" card">
                                                <div class=" card-header">
                                                    <div >
                                                        <div class="my-text-overline">Address</div>
                                                        <h5 class="my-text-headline">{pump_addess_en}</h5>
                                                    </div>
                                                </div>
                                                <div class=" card-body">
                                                    <div style={{color:'burlywood'}}><h3>Information</h3></div><br></br>
                                                    <dl class="my-list my-list--definitions my-dl">
                                                        <dt>Manager</dt>
                                                        <dd>{pump_manager_name}</dd>
                                                        <dt>Contact No</dt>
                                                        <dd>0{pump_manager_mobile}</dd>
                                                        <dt>Division</dt>
                                                        <dd>{division == null?f_division_id:division.division_name}</dd>
                                                        <dt>District</dt>
                                                        <dd>{district == null?f_zilla_id:district.district_name}</dd>
                                                        <dt>Thana</dt>
                                                        <dd>{thana == null?f_thana_id:thana.thana_name}</dd>
                                                    </dl>
                                                    <ul class="my-list my-list--simple list-inline mb-0">
                                                        <li class="my-list-item">
                                                            <span class="list__icon">TIN Number</span><br></br>
                                                            <a class="my-list-item__text">{tin_number}</a>
                                                        </li>
                                                        <li class="my-list-item">
                                                            <span class="my-list-item__graphic list__icon">VAT Registraion Number</span><br></br>
                                                            <a class="my-list-item__text">{trade_license_number}</a>
                                                        </li>
                                                        <li class="my-list-item">
                                                            <span class="list__icon">Trade License Number</span><br></br>
                                                            <a class="my-list-item__text">{vat_registration_number}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="my-card card">
                                                <div class="my-text-overline" style={{paddingLeft: '20px'}}><h5>PETROL PUMP OWNER</h5></div>
                                                {owner.map((owner)=>
                                                    <ul class="my-list list-group list-group-flush">
                                                        <li class="my-list-item list-group-item">
                                                            <div class="my-list-item__text">
                                                                <h4 class="my-list-item__text-title">{owner.pump_owner_name}</h4>
                                                                <p class="my-list-item__text-description">Contact No: {owner.pump_owner_phone}</p>
                                                            </div>
                                                        </li>
                                                        <li class="my-list-item list-group-item">
                                                            <div class="my-list-item__text">
                                                                <strong class="my-list-item__text-title">Email</strong>
                                                                <p class="my-list-item__text-description">{owner.pump_owner_email}</p>
                                                                <strong class="my-list-item__text-title">NID</strong>
                                                                <p class="my-list-item__text-description">{owner.pump_owner_nid_no}</p>
                                                            </div>
                                                            <div class="my-list-item__text">
                                                                <strong class="my-list-item__text-title">Address</strong>
                                                                <p class="my-list-item__text-description">{owner.owner_address}</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    )}
                                                </div>

                                            
                                            </div>

                                            <div class="col-6">
                                                <div class="my-alert alert alert-info">
                                                    <h1>COVER PICTURE</h1>
                                                </div>
                                                <div class="my-card card">
                                                    <div class=" card-body">
                                                    <div class="my-text-overline"><h5>Bank Accounts and Cards</h5></div>
                                                    {bank_account.map((bank)=>
                                                        <ul class="my-list my-list--simple list-inline mb-0">
                                                            <li class="my-list-item">
                                                                <strong class="list__icon">Bank Name</strong><br></br>
                                                                <span class="my-list-item__text">{bank.bank_name}</span>
                                                            </li>
                                                            <li class="my-list-item">
                                                                <strong class="my-list-item__graphic list__icon">Branch Name</strong><br></br>
                                                                <span class="my-list-item__text">{bank.bank_branch}</span>
                                                            </li>
                                                            <li class="my-list-item">
                                                                <strong class="list__icon">Account Holder</strong><br></br>
                                                                <span class="my-list-item__text">{bank.account_name}</span>
                                                            </li>
                                                            <li class="my-list-item">
                                                                <strong class="list__icon">Account No</strong><br></br>
                                                                <span class="my-list-item__text">{bank.account_no}</span>
                                                            </li>
                                                            <li class="my-list-item">
                                                                <strong class="list__icon">Swift Code</strong><br></br>
                                                                <span class="my-list-item__text">{bank.swift_code}</span>
                                                            </li>
                                                            <li class="my-list-item">
                                                                <strong class="list__icon">Routing Number</strong><br></br>
                                                                <span class="my-list-item__text">{bank.routing_number}</span>
                                                            </li>
                                                        </ul>
                                                        )}
                                                        <ul class="my-list-inline list-inline mb-0">
                                                            <li><a href="#">Add a Bank Account or Card →</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            </div>
                                        </section>
                                   </div>
                               </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Member;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createPump,editPump })(Index));
