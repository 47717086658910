import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createBanner,bannerList,editBanner,sliderUpload, categoryList} from '../../../store/banner/actions';

const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }
class BannerCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                banner: {
                    
                },
                // banner_image:{}
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchbannerList();
            this.fetchCategoryList();  
    }

    fetchbannerList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        bannerList(options).then((response) => {
            this.setState({
                bannerList :response.data, 
            });
            this.state.bannerList = response.data;
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchCategoryList = () => {
        let options = {
            page: this.state.page,
            per_page: 50,
            search: this.state.search
        }
        categoryList(options).then((response) => { 
            this.setState({
                categoryList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (banner) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.banner = banner;

        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            this.props.createBanner(values);
            this.fetchbannerList(); 
            this.form && this.form.reset();
            // alert("Banner Created Successfully");
        }

           handleImageUpload = (e) => {
            var id = e.target.id;
            var id = this.state.banner.id;
            let files = e.target.files;
    
                let reader = new FileReader(); 
                reader.readAsDataURL(files[0]); 
                reader.onload = (e)  => {
                    const formData = {file: e.target.result, name:files[0].name, type:files[0].type ,id:id};
    
                    sliderUpload(formData,formData.id).then((response) => {
                        this.state.banner_image = response.data.banner;
                        this.fetchCategoryList();     
                        this.setState({
                            banner_image : response.data.banner, 
                        });
                        // alert('Logo Uploaded Successfully');
    
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                }
           }
        

           handleEditImageSubmit(event, values) {  
            this.props.editBanner(values);
            this.setState(prevState => ({
             image_modal_scroll: !prevState.image_modal_scroll,
           })); 
    }
       
       handleEditSubmit(event, values) {  
            this.props.editBanner(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchbannerList();
            this.form && this.form.reset();
       }

      
        handleInputChange = (e) => {
            this.setState({[e.target.name]: e.target.value},() => {
                this.fetchbannerList();
                this.fetchCategoryList();
            });
        }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchbannerList();
            });
        }

    render() {
        let bannerListMap = [];
        let categoryList = [];
        let paginator;
        if (this.state.hasOwnProperty('categoryList')) { categoryList = this.state.categoryList; }
        if (this.state.hasOwnProperty('bannerList')) {
            bannerListMap = this.state.bannerList.data;
            paginator = {
                'currentPage':this.state.bannerList.current_page,
                'pagesCount':this.state.bannerList.last_page,
                'total':this.state.bannerList.total,
                'to':this.state.bannerList.to,
                'from':this.state.bannerList.from,
            }
        }
        const imageLink =process.env.REACT_APP_IMAGE_URL;
        const {f_category_id,title,description,banner_image,link} = this.state.banner;
 
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
          console.log("this.props.success",this.props.success)
          if(this.props.success != ''){
            setTimeout(function() {
                window.location.reload();
            }, 1000);
             
          }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="banner" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="8" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Banner List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Banner Image</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {bannerListMap.map((banner, index) =>
                                                    <tr key={banner.id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{banner.title}</td>
                                                        <td>{banner.description}</td>
                                                        <td><img src={imageLink+'banner/'+banner.banner_image} alt="" height="100" width="200"/></td>
                                                        <td>
                                                            <button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(banner) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{title}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }} ref={c => (this.form = c)}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Banner Updated Successfully!
                                           </Alert>
                                           ) : null}
                                           <div className="form-group">
                                               <AvField name="title" label="Banner title" value={title} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group ">
                                               <AvField name="description" label="Banner Description" value={description} className="form-control "  type="text" />
                                           </div>
                                           <div className="form-group ">
                                               <AvField name="link" label="Link" value={link} className="form-control "  type="text" />
                                           </div>
                                           <div className="form-group">
                                                    <AvField type="select"className="custom-select" label={reqLabel('Category')} value={f_category_id} name={"f_category_id"} id={"f_category_id"} >
                                                        <option defaultValue>Select Category</option>
                                                            {categoryList.map((category,index)=>
                                                                 <option value={category.category_id}>{category.category_name_en}</option>
                                                            )}
                                                    </AvField>
                                            </div>
                                            <div>
                                           <img src={imageLink+'banner/'+banner_image} alt="" height="50" />
                                                <AvField  type="hidden" value={this.state.banner_image} name="banner_image" id="banner_image"/>
                                                <AvField label="Banner Image" type="file" className="form-control" name="banner_image" id="banner_image" onChange={(e) =>this.handleImageUpload(e)}/>
                                            </div>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="4" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Banner</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)} >
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label="Banner"  id="title" name="title" required/>
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Decription"  type="text" name="description" id="description" required/>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Link"  type="text" name="link" id="link" />
                                                 </div>

                                                 <div className="col-md-12">
                                                    <AvField type="select"className="custom-select" label={reqLabel('Category')} name={"f_category_id"} id={"f_category_id"} >
                                                        <option defaultValue>Select Category</option>
                                                            {categoryList.map((category,index)=>
                                                                 <option value={category.category_id}>{category.category_name_en}</option>
                                                            )}
                                                    </AvField>
                                                 </div> 

                                                 <div>
                                                 {/* <img src={imageLink+'banner/'+banner_image} alt="" height="50" /> */}
                                                 {/* <AvField  type="hidden" value={this.state.banner_image} name="banner_image" id="banner_image"/> */}
                                                 <AvField label="Banner Image" type="file" className="form-control" name="banner_image_0" onChange={(e) =>this.handleImageUpload(e)} id="banner_image" required/>
                                                 </div>
                                             
                                                 
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Fuel;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createBanner,editBanner })(BannerCreate));
