import { FUEL_ERROR,FUEL_SUCCESS,EDIT_FUEL,SHOW_FUEL,CREATE_FUEL } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createFuel = (fuel) => {
    return {
        type: CREATE_FUEL,
        payload: { fuel }
    }
}

export const showFuel = (fuel_id) => {
    return {
        type: SHOW_FUEL,
        payload: { fuel_id }
    }
}

export const editFuel = (fuel) => {
    return {
        type: EDIT_FUEL,
        payload: { fuel }
    }
}



export const fuelSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: FUEL_SUCCESS,
        payload: msg
    }
}


export function fuelShow(fuel_id){
    return getApi().get('users/id/'+fuel_id)
}


export function fuelList(params){
    return getApi().get('fuel/list',{
        params: params
    })
}

export const fuelError = (error) => {
    return {
        type: FUEL_ERROR,
        payload: error
    }
}
