import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_MERCHANT_BRANCH,EDIT_MERCHANT_BRANCH, SHOW_MERCHANT_BRANCH, MERCHANT_BRANCH_SUCCESS, MERCHANT_BRANCH_ERROR } from './actionTypes';

import { brandError,brandShow, merchantBranchSuccess, merchantBranchError } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createMerchantBranch({ payload: { merchantBranch } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'marchant/branch/create',merchantBranch);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(merchantBranchError(response.message.message));
            }else if(response.success == true){
                yield put(merchantBranchSuccess(response.data.message));
            } 
          }
    } catch (error) {
        yield put(merchantBranchError("hello"));
    }
}


function* editMerchantBranch({ payload: { merchantBranch } }) {
   // console.log("kjashdkjhkasjdk",merchantBranch)
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'marchant/branch/'+merchantBranch.marchant_branch_id, merchantBranch);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(merchantBranchError(response.message.message));
            }else if(response.success == true){
                yield put(merchantBranchSuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(merchantBranchError(error));
    }
}


// function* showMerchantBranch({ payload: { user_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtData, 'users/id/'+user_id);
//              yield put(brandShow(response));
//           }
          
          
//     } catch (error) {
//         yield put(brandError(error));
//     }
// }

export function* watchMerchantBranch() {
    yield takeEvery(EDIT_MERCHANT_BRANCH, editMerchantBranch)
    yield takeEvery(CREATE_MERCHANT_BRANCH, createMerchantBranch)
    // yield takeEvery(SHOW_MERCHANT, showMerchant)
}

function* MerchantBranchSaga() {
    yield all([
        fork(watchMerchantBranch),
    ]);
}

export default MerchantBranchSaga;