import React, { Component } from 'react';
import { Container, Row, Col, Card,CardBody, Button,Label,FormGroup,CustomInput,CardHeader,Form,Input,FormText,CardFooter} from "reactstrap";
import { Link } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';
// actions
import {createOrUpdateRole,getRoleDetails,getPermissionGroupList} from '../../store/auth/role/actions';
import {checkStatus} from "../../services/httpService";

function PermissionGroupRow(props) {
    const group = props.permission_group
    const sadminCheck =(name) =>{
        let allpermissions = [];
        if( localStorage.getItem("permissions") == null){
            allpermissions = [];
        }else{
            allpermissions = localStorage.getItem("permissions");
            allpermissions = JSON.parse(allpermissions);
            // allItems = JSON.parse(allItems);
        }       
        if(allpermissions.indexOf(name) !== -1){
            return {display:''};
        } else{
            return {display:'none'};

        }
    }

    return (
        <div >
            <div>
                <Label for="exampleEmail">{group.name.toUpperCase()} Permission</Label>
            </div>
            <Row className="border-top pt-4 pb-4">
                {group.permissions.map((permission) => {
                    let id = `permission-${permission.id}`

                    let check_permission = props.role_permissions.filter((permission_id) => {
                        return permission.id === permission_id
                    })
                    let status = false
                    if (check_permission.length) {
                        status = true
                    }

                    return (
                        <Col sm="3" key={permission.id} style={sadminCheck(permission.name)}>
                            <FormGroup check inline>
                                <CustomInput type="switch" id={id} name={id} data-id={permission.id}
                                             label={permission.name}
                                             onChange={(e) => props.handleChange(e, permission.name)} checked={status}/>
                            </FormGroup>
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

class CreateRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            role: {
                permissions: []
            },
            permission_group:[]
      };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            console.log('dhfhf',this.props.match.params.id)
            this.fetchRoleDetails(this.props.match.params.id);
        }

        this.fetchPermissionGroups()
    }

    fetchRoleDetails = (role_id) => {

        getRoleDetails(role_id)
            .then((response) => {
                let role = response.data.role;
                let permissions = response.data.permission.map((permission) => permission.id);
                role["permissions"] = permissions;

                this.setState({
                    role: role
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchPermissionGroups = () => {

        checkStatus();
        getPermissionGroupList()
            .then((response) => {
                this.setState({
                    permission_group: response.data
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    autoSearchInputChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;

        let roleCopy= Object.assign({}, this.state.role);
        roleCopy[name] = value;

        this.setState({
            role: roleCopy
        });
    }

    handleChange = (e, name) => {
        let permissions= this.state.role.permissions;

        if (e.target.checked === true) {
            permissions.push(parseInt(e.target.dataset.id));
        } else {
            permissions =  permissions.filter(function(permission) {
                return permission !== parseInt(e.target.dataset.id);
            });
        }

        let roleCopy= Object.assign({}, this.state.role);
        roleCopy["permissions"] = permissions;
        this.setState({
            role: roleCopy
        });

    }

    onHandleSubmit = (event) => {
        event.preventDefault();

        const {id, name, permissions} = this.state.role

        const data = {
            id: id,
            name: name,
            permissions: permissions
        };

        createOrUpdateRole(id,data)
            .then((response) => {
                if (response.data.status === true) {
                    this.props.history.push({
                        pathname: '/roles',
                        state: { message: response.data.message }
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                this.props.handleErrorResponse(err);
            });
    }

    render() {

        const {role, permission_group} = this.state;
        return (
           <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Skote" breadcrumbItem="Role" />
                         <Row>
                    <Col xs="12" sm="12">
                    <Form onSubmit={this.onHandleSubmit}>
                        <Card>
                            <CardBody>
                                    <Row>
                                        <Col xs="12" sm="6" md={{size: 6, offset: 3}}>
                                            <Card>
                                                <CardHeader>
                                                    <strong>Role Information</strong>
                                                    <small> Form</small>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup row>
                                                        <Col md="3">
                                                            <Label htmlFor="name">Role Name</Label>
                                                        </Col>
                                                        <Col xs="12" md="9">
                                                            <Input type="text" id="name" name="name"
                                                                   placeholder="Enter Role Name..." value={role.name || ''} onChange={(e) => this.autoSearchInputChange(e)}/>
                                                            <FormText className="help-block">Please enter Role
                                                                Name</FormText>
                                                        </Col>
                                                    </FormGroup>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <Card>
                                                <CardHeader>
                                                    <strong>Permission Information</strong>
                                                    <small> Assign Permission</small>
                                                </CardHeader>
                                                <CardBody>
                                                    {permission_group.map((permission, index) =>
                                                        <PermissionGroupRow key={index} permission_group={permission} handleChange={this.handleChange} role_permissions={role.permissions}/>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                            </CardBody>
                            <CardFooter>
                                <Button type="submit" size="sm" color="primary"
                                        className="mr-2 pull-right"><i
                                    className="fa fa-dot-circle-o"/> Submit</Button>
                                <Link to="/role">
                                    <Button type="reset" size="sm" color="danger"
                                            className="mr-2 pull-right"><i
                                        className="fa fa-ban"/> Cancel</Button>
                                </Link>
                            </CardFooter>
                        </Card>
                        </Form>
                    </Col>
                </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
            
const mapStatetoProps = state => {
    const { msg,error,success } = state.Role;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps,{createOrUpdateRole,getRoleDetails,getPermissionGroupList})(CreateRole));

