import { PUMP_ERROR,PUMP_SUCCESS,EDIT_PUMP,SHOW_PUMP,CREATE_PUMP } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createPump = (pump) => {
    return {
        type: CREATE_PUMP,
        payload: { pump }
    }
}

export const showPump = (pump_id) => {
    return {
        type: SHOW_PUMP,
        payload: { pump_id }
    }
}

export const editPump = (pump) => {
    return {
        type: EDIT_PUMP,
        payload: { pump }
    }
}



export const pumpSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: PUMP_SUCCESS,
        payload: msg
    }
}


export function pumpShow(pump_id){
    return getApi().get('petrolPump/'+pump_id)
}


export function pumpList(params){
    return getApi().get('petrolPump/list',{
        params: params
    })
}

export function cityList(params){
    return getApi().get('list/city',{
        params: params
    })
}

export function districtList(district){
    return getApi().get('list/district/'+district)
}

export function thanaList(thana){
    return getApi().get('list/thana/'+thana)
}

export const pumpError = (error) => {
    return {
        type: PUMP_ERROR,
        payload: error
    }
}
