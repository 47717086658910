import { LOAN_TYPE_ERROR,LOAN_TYPE_SUCCESS,EDIT_LOAN_TYPE,SHOW_LOAN_TYPE,CREATE_LOAN_TYPE } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createLoanType = (loanType) => {
    return {
        type: CREATE_LOAN_TYPE,
        payload: { loanType }
    }
}

export const showLoanType = (loanType_id) => {
    return {
        type: SHOW_LOAN_TYPE,
        payload: { loanType_id }
    }
}

export const editLoanType = (loanType) => {
    return {
        type: EDIT_LOAN_TYPE,
        payload: { loanType }
    }
}



export const loanTypeSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: LOAN_TYPE_SUCCESS,
        payload: msg
    }
}


export function loanTypeShow(loanType_id){
    return getApi().get('users/id/'+loanType_id)
}


export function loanTypeList(params){
    return getApi().get('loanType',{
        params: params
    })
}

export const loanTypeError = (error) => {
    return {
        type: LOAN_TYPE_ERROR,
        payload: error
    }
}
