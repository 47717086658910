import { COURIER_ERROR,COURIER_SUCCESS,EDIT_COURIER,SHOW_COURIER,CREATE_COURIER,EDIT_COURIER_PRICING,CREATE_COURIER_PRICING } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createCourier = (courier) => {
    console.log("two",courier)
    return {
        type: CREATE_COURIER,
        payload: { courier }
    }
}

export const showcCourier = (courier_id) => {
    return {
        type: SHOW_COURIER,
        payload: { courier_id }
    }
}

export const editCourier = (courier) => {
    return {
        type: EDIT_COURIER,
        payload: { courier }
    }
}

export const createCourierPricing = (courierPricing) => {
    console.log("two",courierPricing)
    return {
        type: CREATE_COURIER_PRICING,
        payload: { courierPricing }
    }
}

export const editCourierPricing = (courierPricing) => {
    return {
        type: EDIT_COURIER_PRICING,
        payload: { courierPricing }
    }
}

export const courierSuccess = (msg) => {
    return {
        type: COURIER_SUCCESS,
        payload: msg
    }
}


export function courierShow(courier_id){
    return getApi().get('users/id/'+courier_id)
}


export function courierList(params){
    return getApi().get('Courier/lists',{
        params: params
    })
}

export function courierPricingList(params){
    return getApi().get('courier/pricing/list',{
        params: params
    })
}

export const courierError = (error) => {
    return {
        type: COURIER_ERROR,
        payload: error
    }
}



