import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PRODUCT_STOCK,PRODUCT_STOCK_SUCCESS,EDIT_PRODUCT_STOCK,SHOW_PRODUCT_STOCK } from './actionTypes';

import { productStockSucess, productStockError,productStockShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createProductStock({ payload: { productStock } }) {
console.log('productStock',productStock);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'productStock/create',productStock);
             if(response.success == false){
                yield put(productStockError(response.message));
            }else if(response.success == true){
                yield put(productStockSucess(response.data.message));
            }
          }
    } catch (error) {
        yield put(productStockError(error));
    }
}


function* editProductStock({ payload: { productStock } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'productStock/'+productStock.stock_id, productStock);

             if(response.success == false){
                yield put(productStockError(response.message));
            }else if(response.success == true){
                yield put(productStockSucess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(productStockError(error));
    }
}


function* showProductStock({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);

             if(response.success == false){
                yield put(productStockError(response.message));
            }else if(response.success == true){
                yield put(productStockSucess(response.data.message));
            }
          }
          
          
    } catch (error) {
        yield put(productStockError(error));
    }
}

export function* watchProductStock() {
    yield takeEvery(EDIT_PRODUCT_STOCK, editProductStock)
    yield takeEvery(CREATE_PRODUCT_STOCK, createProductStock)
    yield takeEvery(SHOW_PRODUCT_STOCK, showProductStock)
}

function* productStockSaga() {
    yield all([
        fork(watchProductStock),
    ]);
}

export default productStockSaga;