import React, { Component } from "react";
// import { Card, CardBody, Col, Row, , ,Container,,,,, } from "reactstrap";
import { Row, Col, Card, CardBody,CardTitle,Alert,Button,Table,Modal,CardFooter,Badge, TabContent, TabPane, NavItem, NavLink, Label , Input, Form, FormGroup, Progress, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
import classnames from 'classnames';
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createPump,pumpList,editPump,thanaList,cityList,districtList} from '../../../store/petrolPump/actions';

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                activeTab: 1,
                activeTabProgress: 1,
                progressValue : 25,
                pump: {
                    
                }
            }
              this.toggleTab.bind(this);
              this.toggleTabProgress.bind(this);
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchpumpList();     
            this.fetchCityList();     
    }

    fetchpumpList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        pumpList(options).then((response) => {
            this.setState({
                pumpList :response, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchCityList = () => {
        cityList().then((response) => {
            this.setState({
                cityList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchDistrictList = (city_id) => {
        districtList(city_id).then((response) => {
            this.setState({
                districtList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchThanaList = (district_id) => {
        thanaList(district_id).then((response) => {
            this.setState({
                thanaList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (member) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.member = member;
        this.removeBodyCss();
      }

        toggleTab(tab) {
            if (this.state.activeTab !== tab) {
                if(tab >= 1 && tab <=4 ){
                    this.setState({
                        activeTab: tab
                    });
                }
            }
        }

        toggleTabProgress(tab) {
            if (this.state.activeTabProgress !== tab) {
                if(tab >= 1 && tab <=6 ){
                    this.setState({
                    activeTabProgress: tab
                    });

                if(tab === 1) { this.setState({progressValue : 17}) }
                if(tab === 2) { this.setState({progressValue : 34}) }
                if(tab === 3) { this.setState({progressValue : 50}) }
                if(tab === 4) { this.setState({progressValue : 67}) }
                if(tab === 5) { this.setState({progressValue : 84}) }
                if(tab === 6) { this.setState({progressValue : 100}) }
                }
            }
        }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {  
            var confirm = window.confirm("Are you sure to submit?");
            if(confirm == true){
                this.props.createPump(values);
            }
            // this.fetchpumpList();     
        }

       
       handleEditSubmit(event, values) {  
            this.props.editPump(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchpumpList();     
       }

       handleInputChange = (e) => {
           if(e.target.name == 'f_division_id'){
                this.fetchDistrictList(e.target.value);
           }
           if(e.target.name == 'f_zilla_id'){
                this.fetchThanaList(e.target.value);
           }
        // this.setState({[e.target.name]: e.target.value},() => {
        //     // this.fetchpumpList();
        // });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchpumpList();
            });
        }

        submitForm (e) {
            e.preventDefault()
            this.props.history.push('/petrolPump'); // <--- The page you want to redirect your user to.
          }

    render() {
        let pumpList = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let paginator;
        if (this.state.hasOwnProperty('pumpList')) {
            pumpList = this.state.pumpList.data;
            paginator = {
                'currentPage':this.state.pumpList.currentPage,
                'pagesCount':this.state.pumpList.pagesCount,
                'total':this.state.pumpList.total,
                'to':this.state.pumpList.to,
                'from':this.state.pumpList.from,
            }
        }

        if(this.state.hasOwnProperty('cityList')){cityList = this.state.cityList;}
        if(this.state.hasOwnProperty('districtList')){districtList = this.state.districtList;}
        if(this.state.hasOwnProperty('thanaList')){thanaList = this.state.thanaList;}

        const {member_id,relationship,member_name_bn,member_name} = this.state.pump;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Petrol Pump" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-4">Create New Petrol Pump Account</h4>

                                        <div id="progrss-wizard" className="twitter-bs-wizard">
                                            <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                                            <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 1 })} onClick={() => { this.toggleTabProgress(1); }} >
                                                                        <span className="step-number mr-2">01</span>
                                                                         Registration
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 2 })} onClick={() => { this.toggleTabProgress(2); }} >
                                                                        <span className="step-number mr-2">02</span>
                                                                        <span> Location</span>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 3 })} onClick={() => { this.toggleTabProgress(3); }} >
                                                                        <span className="step-number mr-2">03</span>
                                                                         Documents
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 4 })} onClick={() => { this.toggleTabProgress(4); }} >
                                                                        <span className="step-number mr-2">04</span>
                                                                         Owner 
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 5 })} onClick={() => { this.toggleTabProgress(5); }} >
                                                                        <span className="step-number mr-2">05</span>
                                                                         Bank Account
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 6 })} onClick={() => { this.toggleTabProgress(6); }} >
                                                                    <span className="step-number mr-2">06</span>
                                                                    Confirm Detail
                                                                    </NavLink>
                                                                </NavItem>
                                            </ul>

                                            <div id="bar" className="mt-4">
                                                <Progress color="success" striped animated value={this.state.progressValue} />
                                                <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                                            </div>
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }}>
                                            <TabContent activeTab={this.state.activeTabProgress} className="twitter-bs-wizard-tab-content">
                                                <TabPane tabId={1}>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField type="text" label="Petrol Pump Name" className="form-control" name="petrol_pump_name_en" id="petrol_pump_name_en" required/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="Petrol Pump Name (Bangla)" type="text" className="form-control" name="petrol_pump_name_bn" id="petrol_pump_name_bn"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="Pump Manager" type="text" className="form-control" name="pump_manager_name" id="pump_manager_name" required/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="Mobile No" type="number" className="form-control" name="pump_manager_mobile" id="pump_manager_mobile" required/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="Password" type="password" className="form-control" name="password" id="password" required/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="Confirm Password" type="password" className="form-control" name="confirm_password" id="confirm_password" required/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                  <div>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <AvField label="Cover Picture" type="file" className="form-control" name="profile_image" id="profile_image"/>
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>
                                                        
                                                        <Row>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <AvField type="select"className="custom-select" label="Division" name="f_division_id" id="f_division_id" onChange={(e) =>this.handleInputChange(e)}>
                                                                                <option defaultValue>Select Division</option>
                                                                            {cityList.map((city,index)=>
                                                                                <option value={city.division_id}>{city.division_name}</option>
                                                                            )}
                                                                    </AvField>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <AvField type="select"className="custom-select" label="District" name="f_zilla_id" id="f_zilla_id" onChange={(e) =>this.handleInputChange(e)}>
                                                                            <option defaultValue>Select District</option>
                                                                            {districtList.map((district,index)=>
                                                                                <option value={district.district_id}>{district.district_name}</option>
                                                                            )}
                                                                    </AvField>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <AvField type="select"className="custom-select" label="Thana" name="f_thana_id" id="f_thana_id">
                                                                            <option defaultValue>Select Thana</option>
                                                                            {thanaList.map((thana,index)=>
                                                                                <option value={thana.thana_id}>{thana.thana_name}</option>
                                                                            )}
                                                                    </AvField>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <Label for="pump_addess_en">Address</Label>
                                                                    <textarea name="firstName" id="pump_addess_en" name="pump_addess_en" className="form-control" rows="2"></textarea>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                  </div>
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                    <div>
                                                          <Row>
                                                              <Col lg="6">
                                                                  <FormGroup>
                                                                      <Label for="tin_number"></Label>
                                                                      <AvField label="Tin Number" type="text" className="form-control" name="tin_number" id="tin_number"/>
                                                                  </FormGroup>
                                                              </Col>
  
                                                              
                                                              <Col lg="6">
                                                                  <FormGroup>
                                                                      <AvField label="Vat Registration Number" type="text" className="form-control" name="vat_registration_number" id="vat_registration_number"/>
                                                                  </FormGroup>
                                                              </Col>
                                                          </Row>
                                                          <Row>
                                                              <Col lg="6">
                                                                  <FormGroup>
                                                                      <AvField label="Trade License Number" type="text" className="form-control" name="trade_license_number" id="trade_license_number"/>
                                                                  </FormGroup>
                                                              </Col>
                                                          
                                                          
                                                              <Col lg="6">
                                                                  <FormGroup>
                                                                      <AvField label="Verification Remarks" type="text" className="form-control" name="verification_remarks" id="verification_remarks"/>
                                                                  </FormGroup>
                                                              </Col>
                                                          </Row>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={4}>
                                                    <div>
                                                      <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="Owner Name" type="text" className="form-control" name="pump_owner_name" id="pump_owner_name" required/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="Mobile No" type="text" className="form-control" name="pump_owner_phone" id="pump_owner_phone" required/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="Email" type="text" className="form-control" name="pump_owner_email" id="pump_owner_email"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="NID" type="number" className="form-control" name="pump_owner_nid_no" id="pump_owner_nid_no"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <AvField label="Address" id="owner_address" name="owner_address" className="form-control" rows="2"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="Password" type="password" className="form-control" name="owner_password" id="owner_password" required/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="Confirm Password" type="password" className="form-control" name="owner_confirm_password" id="owner_confirm_password" required/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={5}>
                                                    <div>
                                                          <Row>
                                                              <Col lg="6">
                                                                  <FormGroup>
                                                                      <AvField label="Bank Name" type="text" className="form-control" name="bank_name" id="bank_name" required/>
                                                                  </FormGroup>
                                                              </Col>
  
                                                              
                                                              <Col lg="6">
                                                                  <FormGroup>
                                                                      <AvField label="Bank Branch" type="text" className="form-control" name="bank_branch" id="bank_branch" required/>
                                                                  </FormGroup>
                                                              </Col>
                                                          </Row>
                                                          <Row>
                                                              <Col lg="6">
                                                                  <FormGroup>
                                                                      <AvField label="Account Holder" type="text" className="form-control" name="account_name" id="account_name"/>
                                                                  </FormGroup>
                                                              </Col>
                                                          
                                                              <Col lg="6">
                                                                  <FormGroup>
                                                                      <AvField label="Bank Account No" type="text" className="form-control" name="account_no" id="account_no" required/>
                                                                  </FormGroup>
                                                              </Col>
                                                          </Row>
                                                          <Row>
                                                              <Col lg="6">
                                                                  <FormGroup>
                                                                      <AvField label="Swift Code" type="text" className="form-control" name="swift_code" id="swift_code"/>
                                                                  </FormGroup>
                                                              </Col>
                                                          
                                                              <Col lg="6">
                                                                  <FormGroup>
                                                                      <AvField label="Routing Number" type="text" className="form-control" name="routing_number" id="routing_number"/>
                                                                  </FormGroup>
                                                              </Col>
                                                          </Row>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={6}>
                                                    <div className="row justify-content-center">
                                                        <Col lg="6">
                                                            <div className="text-center">
                                                                <div className="mb-4">
                                                                    <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                                                </div>
                                                                <div>
                                                                    <h5>Confirm Detail</h5>
                                                                    <p className="text-muted">If several languages coalesce, the grammar of the resulting</p>
                                                                    <div>
                                                                        <form onSubmit={this.submitForm.bind(this)}>
                                                                            <Button type="submit" color="success" >Submit</Button>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </AvForm>

                                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                <li className={this.state.activeTabProgress === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTabProgress(this.state.activeTabProgress - 1);} }>Previous</Link></li>
                                                <li className={this.state.activeTabProgress === 4 ? "next disabled" : "next"}><Link to="#" onClick={() => { this.toggleTabProgress(this.state.activeTabProgress + 1);} }>Next</Link></li>
                                            </ul>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Member;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createPump,editPump })(Create));
