import React, { Component } from "react";
import { CardImg,Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createPrivacyPolicy,privacyPolicyShow,editPrivacyPolicy} from '../../../store/privacyPolicy/actions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorAboutUs from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorContactUs from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorFaq from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorPrivacyPolicy from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorReturnRefund from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorTermsCOnditions from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorHowToBuy from '@ckeditor/ckeditor5-build-classic';

const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>*</sup></>;
  }

class privacyPolicyCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount()
    {
            this.fetchPrivacyPolicyShow();     
    }

    fetchPrivacyPolicyShow = () => {
        privacyPolicyShow(1).then((response) => {
            console.log("response",response)
            this.setState({
                about_us :response.data[0].about_us, 
                contact_us :response.data[0].contact_us, 
                faq :response.data[0].faq, 
                privacy_policy :response.data[0].privacy_policy, 
                returns_refunds :response.data[0].returns_refunds, 
                terms_conditions :response.data[0].terms_conditions, 
                how_to_buy :response.data[0].how_to_buy, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
       handleEditSubmit(event, values) {  
           console.log("handle",values)
            this.props.editPrivacyPolicy(values);

       }

    render() {
         console.log("settingsDataShow",this.state.about_us)

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="privacyPolicy" breadcrumbItem="privacyPolicy" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Update Privacy Policy and other info</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                        <Row>
                                             <Col lg="6" md="6">
                                                 <div className="col-md-12">
                                                         <AvField className="form-control"  type="hidden"  value={this.state.about_us} label={reqLabel("About Us")}  id="about_us" name="about_us" />
                                                        <CKEditor
                                                            editor={ ClassicEditorAboutUs }
                                                            data={this.state.about_us}
                                                            onReady={ editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                console.log( 'Editor is ready to use!', editor );
                                                            } }
                                                            onChange={ ( event, editor ) => {
                                                                const data = editor.getData();
                                                                console.log("okoko",data );
                                                                this.setState({
                                                                    about_us :data, 
                                                                });
                                                            } }
                                                            onBlur={ ( event, editor ) => {
                                                                console.log( 'Blur.', editor );
                                                            } }
                                                            onFocus={ ( event, editor ) => {
                                                                console.log( 'Focus.', editor );
                                                            } }
                                                 
                                                        />
                                                 </div>                                             
                                                 <div className="col-md-12">
                                                    <br></br>
                                                 <AvField className="form-control" value={this.state.contact_us} label={reqLabel("Contact Us")}  type="hidden" name="contact_us" id="contact_us" />
                                                 <CKEditor
                                                            editor={ ClassicEditorContactUs }
                                                            data={this.state.contact_us}
                                                            onReady={ editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                console.log( 'Editor is ready to use!', editor );
                                                            } }
                                                            onChange={ ( event, editor ) => {
                                                                const data = editor.getData();
                                                                console.log("okoko",data );
                                                                this.setState({
                                                                    contact_us :data, 
                                                                });
                                                            } }
                                                            onBlur={ ( event, editor ) => {
                                                                console.log( 'Blur.', editor );
                                                            } }
                                                            onFocus={ ( event, editor ) => {
                                                                console.log( 'Focus.', editor );
                                                            } }
                                                        />
                                                 </div>
                                             </Col>  
                                             <Col lg="6" md="6">
                                             <div className="col-md-12">
                                             <AvField className="form-control" value={this.state.faq}  type="hidden" label={reqLabel("FAQ")}  id="faq" name="faq" />
                                             <CKEditor
                                                            editor={ ClassicEditorFaq }
                                                            data={this.state.faq}
                                                            onReady={ editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                console.log( 'Editor is ready to use!', editor );
                                                            } }
                                                            onChange={ ( event, editor ) => {
                                                                const data = editor.getData();
                                                                console.log("okoko",data );
                                                                this.setState({
                                                                    faq :data, 
                                                                });
                                                            } }
                                                            onBlur={ ( event, editor ) => {
                                                                console.log( 'Blur.', editor );
                                                            } }
                                                            onFocus={ ( event, editor ) => {
                                                                console.log( 'Focus.', editor );
                                                            } }
                                                        />
                                                 </div>                                             
                                                 <div className="col-md-12">
                                                    <br></br>
                                                 <AvField className="form-control" value={this.state.privacy_policy} label={reqLabel("Privacy Policy")}  type="hidden" name="privacy_policy" id="privacy_policy" />
                                                 <CKEditor
                                                            editor={ ClassicEditorPrivacyPolicy }
                                                            data={this.state.privacy_policy}
                                                            onReady={ editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                console.log( 'Editor is ready to use!', editor );
                                                            } }
                                                            onChange={ ( event, editor ) => {
                                                                const data = editor.getData();
                                                                console.log("okoko",data );
                                                                this.setState({
                                                                    privacy_policy :data, 
                                                                });
                                                            } }
                                                            onBlur={ ( event, editor ) => {
                                                                console.log( 'Blur.', editor );
                                                            } }
                                                            onFocus={ ( event, editor ) => {
                                                                console.log( 'Focus.', editor );
                                                            } }
                                                        />
                                                 </div>
                                             </Col> 
                                             <Col lg="12">
                                             <br></br>
                                                 <AvField className="form-control" value={this.state.returns_refunds} label={reqLabel("Return Refund")}  type="hidden" name="returns_refunds" id="returns_refunds" />
                                                 <CKEditor
                                                            editor={ ClassicEditorReturnRefund }
                                                            data={this.state.returns_refunds}
                                                            onReady={ editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                console.log( 'Editor is ready to use!', editor );
                                                            } }
                                                            onChange={ ( event, editor ) => {
                                                                const data = editor.getData();
                                                                console.log("okoko",data );
                                                                this.setState({
                                                                    returns_refunds :data, 
                                                                });
                                                            } }
                                                            onBlur={ ( event, editor ) => {
                                                                console.log( 'Blur.', editor );
                                                            } }
                                                            onFocus={ ( event, editor ) => {
                                                                console.log( 'Focus.', editor );
                                                            } }
                                                        />
                                             </Col>
                                             <Col lg="12">
                                             <br></br>
                                                 <AvField className="form-control" value={this.state.terms_conditions} label={reqLabel("Terms and Conditions")}  type="hidden" name="terms_conditions" id="terms_conditions" />
                                                 <CKEditor
                                                            editor={ ClassicEditorTermsCOnditions }
                                                            data={this.state.terms_conditions}
                                                            onReady={ editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                console.log( 'Editor is ready to use!', editor );
                                                            } }
                                                            onChange={ ( event, editor ) => {
                                                                const data = editor.getData();
                                                                console.log("okoko",data );
                                                                this.setState({
                                                                    terms_conditions :data, 
                                                                });
                                                            } }
                                                            onBlur={ ( event, editor ) => {
                                                                console.log( 'Blur.', editor );
                                                            } }
                                                            onFocus={ ( event, editor ) => {
                                                                console.log( 'Focus.', editor );
                                                            } }
                                                        />
                                             </Col>
                                             <Col lg="12">
                                             <br></br>
                                                 <AvField className="form-control" value={this.state.how_to_buy} label={reqLabel("How to Buy")}  type="hidden" name="how_to_buy" id="how_to_buy" />
                                                 <CKEditor
                                                            editor={ ClassicEditorHowToBuy }
                                                            data={this.state.how_to_buy}
                                                            onReady={ editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                console.log( 'Editor is ready to use!', editor );
                                                            } }
                                                            onChange={ ( event, editor ) => {
                                                                const data = editor.getData();
                                                                console.log("okoko",data );
                                                                this.setState({
                                                                    how_to_buy :data, 
                                                                });
                                                            } }
                                                            onBlur={ ( event, editor ) => {
                                                                console.log( 'Blur.', editor );
                                                            } }
                                                            onFocus={ ( event, editor ) => {
                                                                console.log( 'Focus.', editor );
                                                            } }
                                                        />
                                             </Col>
                                        </Row>  
                                             <div className=" mt-4" >
                                                 <Button type="submit" style={{float:'right'}} color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.PrivacyPolicy;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { editPrivacyPolicy })(privacyPolicyCreate));
