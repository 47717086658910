import { CREATE_MERCHANT, EDIT_MERCHANT, SHOW_MERCHANT, MERCHANT_SUCCESS, MERCHANT_ERROR, EDIT_LOGO} from './actionTypes';
import {getApi} from "../../services/httpService";

export const createMerchant = (merchant) => {
   // console.log("two",merchant)
    return {
        type: CREATE_MERCHANT,
        payload: { merchant }
    }
}

export function bankList(params){
    return getApi().get('list/bank',{
        params: params
    })
}

export const daleteMerchant = (marchant_id, params) => {
  //  console.log("two",marchant_id)
    return getApi().delete('marchant/'+marchant_id,{
        // params: params
    })
}

export const showMerchant = (merchant_id) => {
    return {
        type: SHOW_MERCHANT,
        payload: { merchant_id }
    }
}

export const editMarchant = (merchant) => { 
    //console.log("action"+merchant);
    return {
        type: EDIT_MERCHANT,
        payload: { merchant }
    }
}

export function marchantList(params){
    return getApi().get('marchant/list',{
        params: params
    })
}

export const merchantSuccess = (msg) => {
    return {
        type: MERCHANT_SUCCESS,
        payload: msg
    }
}

export const merchantError = (msg) => {
    return {
        type: MERCHANT_ERROR,
        payload: msg
    }
}

export function logoUpload(params,id){
   // console.log("image_id",id)
    if(id){
        return getApi().put('marchant/logo/edit/'+id,params)
    }else{
        return getApi().post('marchant/logo/upload',params);
    }
}

export const editBanner = (banner) => {
  //  console.log("edit img", banner);
    return {
        type: EDIT_LOGO,
        payload: { banner }
    }
}

