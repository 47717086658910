import { PRIVACY_POLICY_ERROR,PRIVACY_POLICY_SUCCESS,EDIT_PRIVACY_POLICY,SHOW_PRIVACY_POLICY,CREATE_PRIVACY_POLICY } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createPrivacyPolicy = (PrivacyPolicy) => {
    return {
        type: CREATE_PRIVACY_POLICY,
        payload: { PrivacyPolicy }
    }
}

export const showPrivacyPolicy = (id) => {
    return {
        type: SHOW_PRIVACY_POLICY,
        payload: { id }
    }
}

export const editPrivacyPolicy = (PrivacyPolicy) => {
    console.log('action',PrivacyPolicy)
    return {
        type: EDIT_PRIVACY_POLICY,
        payload: { PrivacyPolicy }
    }
}



export const privacyPolicySuccess = (msg) => {
    return {
        type: PRIVACY_POLICY_SUCCESS,
        payload: msg
    }
}




export const privacyPolicyError = (error) => {
    return {
        type: PRIVACY_POLICY_ERROR,
        payload: error
    }
}

export function privacyPolicyShow(id){
    return getApi().get('user/privacy/policy/update/'+id)
}

