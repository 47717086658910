import { FUELPUMPMAP_ERROR,FUELPUMPMAP_SUCCESS,EDIT_FUELPUMPMAP,SHOW_FUELPUMPMAP,CREATE_FUELPUMPMAP } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createFuelPumpMap = (fuelPumpMap) => {
    return {
        type: CREATE_FUELPUMPMAP,
        payload: { fuelPumpMap }
    }
}

export const showFuelPumpMap = (fuel_id) => {
    return {
        type: SHOW_FUELPUMPMAP,
        payload: { fuel_id }
    }
}

export const editFuelPumpMap = (fuelPumpMap) => {
    return {
        type: EDIT_FUELPUMPMAP,
        payload: { fuelPumpMap }
    }
}


export const fuelPumpMapSuccess = (msg) => {
    return {
        type: FUELPUMPMAP_SUCCESS,
        payload: msg
    }
}


export function fuelPumpMapShow(fuel_id){
    return getApi().get('users/id/'+fuel_id)
}


export function fuelPumpMapList(params){
    return getApi().get('fuelPumpMap/list',{
        params: params
    })
}

export const fuelPumpMapError = (error) => {
    return {
        type: FUELPUMPMAP_ERROR,
        payload: error
    }
}
