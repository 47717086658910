import React, { Component, Suspense } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, Progress, TabContent, TabPane, NavItem, NavLink, FormGroup, Label } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../../Settings/Common/DataTabeSearch'
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
// import {AppSwitch} from "@coreui/react";
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import Switch from 'react-switch';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createProduct, productList, discountList, editProduct, searchBrandwithCategory } from '../../../store/product/actions';
import { brandList, brandListAll } from '../../../store/brand/actions';
import { categoryListAll, subcategoryList } from '../../../store/category/actions';
import ToggleSwitch from '../../../store/toogleSwitch/index';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class ProductCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            status: true,
            isFree : false,
            edittoggleSwitch: false,
            modal_scroll: false,
            activeTab: 1,
            hide: true,
            activeTabProgress: 1,
            progressValue: 25,
            feature_image: {},
            product: {

            },
            product_name_en: '',
            indexsub: 0,
            SUbCategory: '',
            // editorState: EditorState.createEmpty(),
            // descriptionState: EditorState.createEmpty(),
            // displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}

        };

        this.toggleTab.bind(this);
        this.toggleTabProgress.bind(this);
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleIsFreeChange = this.handleIsFreeChange.bind(this);
    }

    componentDidMount() {
        this.fetchproductList();
        this.fetchbrandList();
        this.fetchCategoryList();
        this.fetchdiscountList();
    }
    fetchbrandList = () => {
        brandListAll().then((response) => {
            this.setState({
                brandList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchdiscountList = () => {
        let options = {
            page: this.state.page,
            per_page: 100000,
            search: this.state.search
        }
        discountList(options).then((response) => {
            this.setState({
                discountList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    handleChange(status) {
        this.setState({ status });
    }

    handleIsFreeChange(isFree) { console.log(isFree)
        this.setState({ isFree });
    }

    fetchCategoryList = () => {
        // this.state.displayHide= { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        let options = {
            page: this.state.page,
            per_page: 50,
            search: this.state.search
        }
        categoryListAll(options).then((response) => {
            this.setState({
                categoryList: response.data.data,
            });
            // this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}

        })
            .catch((err) => {
                console.log(err);
            })
    }


    fetchSubCategoryList = (e) => {
        let options = {
            page: this.state.page,
            per_page: 500,
            search: this.state.search
        }
        subcategoryList(options, e.target.value).then((response) => {
            console.log("subcategory", response)
            this.setState({
                subcategoryList: response.data.data,
            });
            this.state.subcategoryList = response.data.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }
    setFinalId = (e) => {
       // console.log('index', e.target.value);
        this.state.f_category_info_id = e.target.value;

    }

    fetchSubSubCategoryList = (e) => {
        // this.state.f_category_info_id =e.target.value;

        let options = {
            page: this.state.page,
            per_page: 500,
            search: this.state.search
        }
        subcategoryList(options, e.target.value).then((response) => {
            console.log("Sub-subcategory", response)
            this.setState({
                subsubcategoryList: response.data.data,
            });
            this.state.subsubcategoryList = response.data.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    fetchproductList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        productList(options).then((response) => {
            this.setState({
                productList: response.data,
            });

        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (product) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.product = product;
        this.fetchBrandCategoryList(product.f_category_info_id);
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }


    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                this.setState({
                    activeTab: tab
                });
            }
        }
    }

    toggleTabProgress(tab) {
        if (tab === 2 && document.getElementById('product_name_en').value == '' || document.getElementById('product_name_en').value == null || document.getElementById('product_name_en').value == undefined) {
            alert("Product Name Is Required !");
        } else if (tab === 2 && document.getElementById('f_category_info_id').value == 'Select Category' || document.getElementById('f_category_info_id').value == null || document.getElementById('f_category_info_id').value == undefined) {
            alert("Product Category Is Required !");
        } else if (tab === 2 && document.getElementById('f_brand_id').value == 'Select Brand' || document.getElementById('f_brand_id').value == null || document.getElementById('f_brand_id').value == undefined) {
            alert("Product Brand Is Required !");
        } else if (tab === 3 && document.getElementById('product_specification').value == '' || document.getElementById('product_specification').value == null || document.getElementById('product_specification').value == undefined) {
            alert("Product Specification Is Required !");
        } else if (tab === 3 && document.getElementById('product_weight').value == '' || document.getElementById('product_weight').value == null || document.getElementById('product_weight').value == undefined) {
            alert("Product Weight Is Required !");
        } else if (tab === 3 && document.getElementById('f_unit_id').value == 'Select Unit' || document.getElementById('f_unit_id').value == null || document.getElementById('f_unit_id').value == undefined) {
            alert("Product Unit Is Required !");
        } else if (tab === 3 && document.getElementById('product_buy_price').value == '' || document.getElementById('product_buy_price').value == null || document.getElementById('product_buy_price').value == undefined) {
            alert("Product Buy Price Is Required  !");
        } else if (tab === 3 && document.getElementById('product_sale_price').value == '' || document.getElementById('product_sale_price').value == null || document.getElementById('product_sale_price').value == undefined) {
            alert("Product Sale Price Is Required  !");
        } else if (tab === 3 && document.getElementById('product_market_price').value == '' || document.getElementById('product_market_price').value == null || document.getElementById('product_market_price').value == undefined) {
            alert("Product Market Price Is Required  !");
        } else if (tab === 3 && document.getElementById('f_discount_type').value == 'Discount Type' || document.getElementById('f_discount_type').value == null || document.getElementById('f_discount_type').value == undefined) {
            alert("Product Discount Type Is Required  !");
        } else if (tab === 3 && document.getElementById('discount').value == '' || document.getElementById('discount').value == null || document.getElementById('discount').value == undefined) {
            alert("Product Discount Is Required  !");
        } else if (tab === 4 && document.getElementById('feature_image_0').value == '' || document.getElementById('feature_image_0').value == null || document.getElementById('feature_image_0').value == undefined) {
            alert("Featured Image Is Required  !");
        } else {
            if (this.state.activeTabProgress !== tab) {
                if (tab >= 1 && tab <= 6) {
                    this.setState({
                        activeTabProgress: tab
                    });

                    if (tab === 1) { this.setState({ progressValue: 25 }) }
                    if (tab === 2) { this.setState({ progressValue: 50 }) }
                    if (tab === 3) { this.setState({ progressValue: 75 }) }
                    if (tab === 4) { this.setState({ progressValue: 100 }) }
                }
            }
         }

     }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.createProduct(values);
        // setTimeout(this.props.history.push('/products'), 2000);
        this.form && this.form.reset();
        this.fetchproductList();
    }


    handleEditSubmit(event, values) {
        this.props.editProduct(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchproductList();
    }

    handleImageUpload = (e) => {
        var id = e.target.name;
        // var value = e.target.files[0];
        id = id[id.length - 1];
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type };
            this.state.feature_image[id] = formData;

        }
    }

    //    onEditorStateChange = (editorState) => {
    //     this.setState({
    //       editorState,
    //     });
    //     console.log(editorState.getCurrentContent());
    //     this.state.product_specification = stateToHTML(editorState.getCurrentContent());
    //   };

    //   onDescriptionStateChange = (descriptionState) => {
    //     this.setState({
    //       descriptionState,
    //     });
    //     this.state.product_description = stateToHTML(descriptionState.getCurrentContent());
    //   };




    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchproductList();
        });
    }

    submitForm(e) {
        e.preventDefault()
        setTimeout(this.props.history.push('/products'), 3000);
        // this.props.history.push('/products'); // <--- The page you want to redirect your user to.
        // window.location.reload()
    }

    loadOptions = inputValue => {
        return new Promise((resolve, reject) => {
            // using setTimeout to emulate a call to server
              setTimeout(() => {
                resolve(this.filter(inputValue));
              }, 1000);
        });
    };

    filter = inputValue =>
        this.state.brandList.filter(option =>
            option.brand_name_en.toLowerCase().includes(inputValue.toLowerCase())
        );

    handleBrandSearch(selectedBrand) {
        if (selectedBrand == null) {
            var brand_id = (selectedBrand == null) ? null : selectedBrand.value;
        } else {
            var brand_id = selectedBrand.brand_id;
            // this.state.brand_id = brand_id;
            this.setState({
                brand_id:brand_id
            })
        }
    }


    render() {
        let categoryList = [];
        let subcategoryList = [];
        let subsubcategoryList = [];
        let brandList = [];
        let discountListMap = [];
        let subcategoryListShow = "none";
        let subsubcategoryListShow = "none";
        if (this.state.hasOwnProperty('categoryList')) { categoryList = this.state.categoryList; }
        if (this.state.hasOwnProperty('subcategoryList')) { subcategoryList = this.state.subcategoryList; }
        if (this.state.hasOwnProperty('subsubcategoryList')) { subsubcategoryList = this.state.subsubcategoryList; }
        if (this.state.hasOwnProperty('subcategoryList')) {
            console.log("this.state.subcategoryList.length", this.state.subcategoryList.length);
            console.log("this.state.categoryList----", this.state.f_category_info_id);

            if (this.state.subcategoryList.length == 0) {
                subcategoryListShow = "none";
            } else {
                subcategoryListShow = "";
            }
        }
        if (this.state.hasOwnProperty('subsubcategoryList')) {
            console.log("this.state.subsubcategoryList.length", this.state.subsubcategoryList.length);
            if (this.state.subsubcategoryList.length == 0) {
                subsubcategoryListShow = "none";
            } else {
                subsubcategoryListShow = "";
            }
        }

        if (this.state.hasOwnProperty('brandList')) {
            brandList = this.state.brandList;
            // console.log('brandList', brandList)
        }
        if (this.state.hasOwnProperty('discountList')) { discountListMap = this.state.discountList; }

        const { product_id, product_code, product_name_bn, product_name_en, status } = this.state.product;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }

        //   console.log("this.props.success",this.props.success)
        //   if(this.props.success != ''){
        //     setTimeout(function() {
        //         window.location.reload();
        //     }, 1000);

        //   }

         

        return (
            <React.Fragment>
                <div className="page-content">
                    {/* <div style={this.state.displayHide}> 
                                <h6 align="center">Loding...</h6> 
                                <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="product" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Product</CardTitle>
                                        <div id="progrss-wizard" className="twitter-bs-wizard">
                                            <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 1 })} onClick={() => { this.toggleTabProgress(1); }} >
                                                        <span className="step-number mr-2">01</span>
                                                        Product
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 2 })} onClick={() => { this.toggleTabProgress(2); }} >
                                                        <span className="step-number mr-2">02</span>
                                                        <span> Information</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 3 })} onClick={() => { this.toggleTabProgress(3); }} >
                                                        <span className="step-number mr-2">03</span>
                                                        Featured Image
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTabProgress === 4 })} onClick={() => { this.toggleTabProgress(4); }} >
                                                        <span className="step-number mr-2">04</span>
                                                        Confirm Details
                                                    </NavLink>
                                                </NavItem>
                                            </ul>

                                            <div id="bar" className="mt-4">
                                                <Progress color="success" striped animated value={this.state.progressValue} />
                                                <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                                            </div>
                                            <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)} >
                                                <TabContent activeTab={this.state.activeTabProgress} className="twitter-bs-wizard-tab-content">
                                                    <TabPane tabId={1}>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField type="select" className="custom-select" label={reqLabel('Category')} value={this.state.f_category_info_id} name={"f_category_info_id"} id={"f_category_info_id"} onChange={e => this.fetchSubCategoryList(e)} >
                                                                        <option defaultValue>Select Category</option>
                                                                        {categoryList.map((category, index) =>
                                                                            <option value={category.category_id}>{category.category_name_en}</option>
                                                                        )}
                                                                    </AvField>
                                                                </FormGroup>

                                                            </Col>
                                                            <Col lg="6" style={{ display: subcategoryListShow }}>
                                                                <FormGroup >
                                                                    <AvField type="select" className="custom-select" label={reqLabel('Sub Category')} value={this.state.f_category_info_id2} name={"f_category_info_id2"} id={"f_category_info_id2"} onChange={e => this.fetchSubSubCategoryList(e)} >
                                                                        <option defaultValue>Select Sub Category</option>
                                                                        {subcategoryList != [] &&
                                                                            subcategoryList.map((Subcategory, index) =>
                                                                                <option value={Subcategory.category_id}>{Subcategory.category_name_en}</option>
                                                                            )}
                                                                    </AvField>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6" style={{ display: subsubcategoryListShow }}>
                                                                <FormGroup >
                                                                    <AvField type="select" className="custom-select" label={reqLabel('Child Category')} value={this.state.f_category_info_id4} name={"f_category_info_id4"} id={"f_category_info_id4"} onChange={e => this.setFinalId(e)}>
                                                                        <option defaultValue>Select Sub Category</option>
                                                                        {subsubcategoryList != [] &&
                                                                            subsubcategoryList.map((Subcategory, index) =>
                                                                                <option value={Subcategory.category_id}>{Subcategory.category_name_en}</option>
                                                                            )}
                                                                    </AvField>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <label>Brand</label>
                                                                <AsyncSelect
                                                                    loadOptions={this.loadOptions}
                                                                    onChange={(value) => this.handleBrandSearch(value)}
                                                                    isClearable={true}
                                                                    cacheOptions
                                                                    isSearchable={true}
                                                                    defaultOptions={brandList}
                                                                    required
                                                                />
                                                                <AvField type="hidden" value={this.state.brand_id} name="f_brand_id" id="f_brand_id" required />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField name="product_name_en" label={reqLabel('Product Name')} value={this.state.product_name_en} id={"product_name_en"} className="form-control " type="text" required />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField name="product_name_bn" label="Product Name (Bangla)" className="form-control" type="text" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField name="product_bar_code" label="Product Bar Code" className="form-control" type="text" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField label="Bar Code Image" type="file" className="form-control" name="product_bar_code_img" id="product_bar_code_img" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField name="product_sku_code" label="SKU Code" className="form-control" type="text" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <AvField name="minimum_oder_quantity" label="Minimum Order Quantity" defaultValue={0} className="form-control" type="number" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <AvField name="product_meta_tag" label="Meta tag" className="form-control" type="textarea" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        
                                                    </TabPane>
                                                    <TabPane tabId={2}>
                                                        <div>

                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <AvField name="product_description" label="Description" value={this.state.product_description} className="form-control" type="hidden" />
                                                                        <CKEditor
                                                                            editor={ClassicEditorDescription}
                                                                            data={this.state.product_description}
                                                                            onReady={editor => {
                                                                                // You can store the "editor" and use when it is needed.
                                                                                // console.log( 'Editor is ready to use!', editor );
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                this.setState({
                                                                                    product_description: data,
                                                                                });
                                                                            }}
                                                                            onBlur={(event, editor) => {
                                                                                // console.log( 'Blur.', editor );
                                                                            }}
                                                                            onFocus={(event, editor) => {
                                                                                // console.log( 'Focus.', editor );
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <AvField name="product_specification" label={reqLabel("Specification")} value={this.state.product_specification} className="form-control" type="hidden" required />
                                                                        <CKEditor
                                                                            editor={ClassicEditorSpecification}
                                                                            data={this.state.product_specification}
                                                                            onReady={editor => {
                                                                                // You can store the "editor" and use when it is needed.
                                                                                // console.log( 'Editor is ready to use!', editor );
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                this.setState({
                                                                                    product_specification: data,
                                                                                });
                                                                            }}
                                                                            onBlur={(event, editor) => {
                                                                                // console.log( 'Blur.', editor );
                                                                            }}
                                                                            onFocus={(event, editor) => {
                                                                                // console.log( 'Focus.', editor );
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <AvField name="product_weight" placeholder={"Weight in gm"} label={reqLabel("Weight(In gm)")} defaultValue={0} className="form-control" type="number" required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <AvField type="select" className="custom-select" label={reqLabel('Unit')} name="f_unit_id" id="f_unit_id" required >
                                                                            <option defaultValue>Select Unit</option>
                                                                            <option value="1">Piece</option>
                                                                            <option value="2">Ltr</option>
                                                                            <option value="2">ml</option>
                                                                            <option value="3">kg</option>
                                                                            <option value="4">gm</option>
                                                                            <option value="2">ml</option>
                                                                        </AvField>
                                                                    </FormGroup>
                                                                </Col>

                                                                {/* <Col lg="6">
                                                                <FormGroup>
                                                                      <AvField name="product_stock_balance" label="Current Stock"  className="form-control"  type="number" readonly />           
                                                                </FormGroup>
                                                            </Col> */}
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <AvField name="product_buy_price" label={reqLabel('Buy Price')} id="product_buy_price" className="form-control" type="number" required />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <AvField name="product_sale_price" label={reqLabel('Sale Price')} id="product_sale_price" className="form-control" type="number" required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <AvField name="product_market_price" label={reqLabel('Market Price')} id="product_market_price" className="form-control" type="number" required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <AvField type="select" className="custom-select" label={reqLabel('Discount Type')} name="f_discount_type" id="f_discount_type" required>
                                                                            <option defaultValue>Discount Type</option>
                                                                            {discountListMap.map((discount, index) =>
                                                                                <option value={discount.discount_id}>{discount.discount_type_name}</option>
                                                                            )}
                                                                        </AvField>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <AvField name="discount" label={reqLabel('Discount')} className="form-control" type="number" required />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tabId={3}>
                                                        <div>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup>
                                                                        <AvField label={reqLabel('Featured Picture')} type="file" className="form-control" name="feature_image_0" onChange={(e) => this.handleImageUpload(e)} id="feature_image_0" required />
                                                                        <AvField type="hidden" value={this.state.feature_image} name="feature_image" id="feature_image" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup>
                                                                        <AvField type="file" className="form-control" onChange={(e) => this.handleImageUpload(e)} label="Featured Picture 1" name="feature_image_1" id="feature_image_1" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup>
                                                                        <AvField type="file" label="Featured Picture 2" className="form-control" name="feature_image_2" onChange={(e) => this.handleImageUpload(e)} id="feature_image_2" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup>
                                                                        <AvField type="file" label="Featured Picture 3" className="form-control" name="feature_image_3" onChange={(e) => this.handleImageUpload(e)} id="feature_image_3" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup>
                                                                        <AvField type="file" label="Featured Picture 4" className="form-control" name="feature_image_4" onChange={(e) => this.handleImageUpload(e)} id="feature_image_4" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                {/* <Col lg="12">
                                                                    <div className="custom-control custom-switch mb-2" dir="ltr">
                                                                        <AvInput  type="checkbox" className="custom-control-input" name="status" id="status" />
                                                                        <label className="custom-control-label" htmlFor="status" >Status</label>
                                                    
                                                                    </div>
                                                                </Col> */}   
                                                                <Col lg="6">
                                                                    <Switch
                                                                        name="status"
                                                                        id="status"
                                                                        onChange={this.handleChange}
                                                                        checked={this.state.status}
                                                                        className="react-switch"
                                                                        id="normal-switch"
                                                                    />
                                                                    <span>{this.state.status ? ' Is Active' : ' Is Inactive'}</span>
                                                                    <AvField name="status" value={this.state.status} type="hidden" />
                                                                </Col>
                                                                <Col lg="6">
                                                                    {/* <div className="form-group custom-checkbox" > */}
                                                                        {/* <AvField onChangeText={this.handleIsFreeChange}
                                                                        checked={this.state.isFree} type="checkbox" className="Custom-control-input " name="IsDeliveryChargeFree" label="Is Delivery Charge Free"/> */}
                                                                    {/* </div> */}
                                                                    <Switch
                                                                        name="DeliveryChargeFree"
                                                                        id="DeliveryChargeFree"
                                                                        onChange={this.handleIsFreeChange}
                                                                        checked={this.state.isFree}
                                                                        className="react-switch"
                                                                        id="normal-switch"
                                                                    />
                                                                    <span>{this.state.isFree ? 'Delivery Charge Free' : 'Delivery Charge Not Free'}</span>
                                                                    <AvField name="DeliveryChargeFree" value={this.state.isFree} type="hidden" />
                                                                </Col>  
                                                            </Row>

                                                        </div>
                                                    </TabPane>
                                                    <TabPane tabId={4}>
                                                        <div className="row justify-content-center">
                                                            <Col lg="6">
                                                                <div className="text-center">
                                                                    <div className="mb-4">
                                                                        <i className="fas fa-question-circle display-4"></i>
                                                                    </div>
                                                                    <div>
                                                                        {/* <h5>Confirm Details</h5> */}
                                                                        <h5 className="text-muted">Are You Sure ? To Confirm Create!!</h5>
                                                                        <div>
                                                                            <form style={{ marginTop: '10px' }} onSubmit={this.submitForm.bind(this)}>
                                                                                <Button type="submit" color="success" >Submit</Button>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                            </AvForm>

                                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                <li className={this.state.activeTabProgress === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTabProgress(this.state.activeTabProgress - 1); }}>Previous</Link></li>
                                                <li className={this.state.activeTabProgress === 4 ? "hide" : "next"}><Link to="#" onClick={() => { this.toggleTabProgress(this.state.activeTabProgress + 1); }}>Next</Link></li>
                                            </ul>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.Product;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createProduct, editProduct })(ProductCreate));
