import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch';
// import '../ProductCategory/TreeDesign.scss';
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Switch from 'react-switch';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createCategory,categoryList,editCategory,categoryTreeList,showCategory,categoryShow,productCategoryImageUpload,daleteCatagory} from '../../../store/category/actions';

class CategoryCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 100,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                status:true,
                parentCategory: 0,
                selectedOptions: {},
                category_icon:{},
                category: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this); 
    }

    componentDidMount()
    {
            this.fetchCategoryList();    
            this.fetchCategoryTreeList();
    }

    fetchCategoryList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        categoryList(options).then((response) => {
            this.setState({
                categoryList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchCategoryTreeList = () => {
        categoryTreeList().then((response) => {
            this.setState({
                rootCategories :response.data.rootCategories, 
                categoryTree :response.data.childTree, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchCategoryDetails = (category_id) => {
        categoryShow(category_id).then((response) => {
            this.setState({
                category :response.data.productCategory, 
            });
            this.state.category = response.data.productCategory;
            console.log(this.state.category);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (category_id) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchCategoryDetails(category_id);
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createCategory(values);
            this.fetchCategoryList();     
            this.form && this.form.reset();
            // window.location.reload();
        }

        handleImageUpload = (e) => {
            var id = e.target.id;
            var category_id = this.state.category.category_id;
            let files = e.target.files;
    
                let reader = new FileReader(); 
                reader.readAsDataURL(files[0]); 
                reader.onload = (e)  => {
                    const formData = {file: e.target.result, name:files[0].name, type:files[0].type ,category_id: category_id};
    
                    productCategoryImageUpload(formData,formData.category_id).then((response) => {
                        this.state.category_icon = response.data.productCategoryImage.category_icon;
                        this.fetchCategoryList();     
                        this.setState({
                            category_icon : response.data.productCategoryImage.category_icon, 
                        });
                        // alert('Logo Uploaded Successfully');
    
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                }
           }

           handleEditImageSubmit(event, values) {  
            this.props.editCategory(values);
            this.setState(prevState => ({
             image_modal_scroll: !prevState.image_modal_scroll,
           }));
            this.fetchCategoryList();     
    }
       
       handleEditSubmit(event, values) {  
            this.props.editCategory(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchCategoryDetails(values.category_id);
            this.fetchCategoryList();   
            // alert("Category Updated Successfully");
            // this.form && this.form.reset();
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchCategoryList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchCategoryList();
            });
        }

        handleChange(status) {
            this.setState({ status });
          }

        handleDeleteSubmit( values) {
            alert("Are You Want To Delete This?");
            this.fetchCategoryList();    
            this.fetchCategoryTreeList();  
            daleteCatagory(values)
            .then((response) => {
              if (response.data.status == true) {
               //   console.log(response)
                alert(response.data.message);
                this.fetchCategoryList();    
                this.fetchCategoryTreeList();  
                window.location.reload();
              }
              else if (response.data.status == true) {
                alert(response.data.message);
              }
            })
            .catch((err) => {
              this.props.handleErrorResponse(err);
            //  console.log('err', this.props.handleErrorResponse(err));
            });
        }

    render() {
        let categoryList = [];
        let categoryTree = [];
        let paginator;
        if (this.state.hasOwnProperty('categoryList')) {
            categoryList = this.state.categoryList.data;
            paginator = {
                'currentPage':this.state.categoryList.currentPage,
                'pagesCount':this.state.categoryList.pagesCount,
                'total':this.state.categoryList.total,
                'to':this.state.categoryList.to,
                'from':this.state.categoryList.from,
            }
        }
        const imageLink =process.env.REACT_APP_IMAGE_URL;
        if (this.state.hasOwnProperty('categoryTree')) {  categoryTree = this.state.categoryTree; }
        const {category_id,category_name_en,category_name_bn,f_category_info_id,is_menu,category_icon} = this.state.category;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
          console.log("this.props.success",this.props.success)
          if(this.props.success != ''){
            setTimeout(function() {
                window.location.reload();
            }, 1000);
             
          }
          const treeStyle = {background: '#2d98f8',color: 'white',borderRadius: '8px',padding: '4px',fontFamily: 'arial', fontSize: '16px'};
        
          const OptionsList = ({ options, selectedOptions, onChange }) => {
 
            const handleCheckboxClicked = (selectedOptionId) => {
                this.state.parentCategory = selectedOptionId;
              // is currently selected
              if(selectedOptions[selectedOptionId]){
                // remove selected key from options list
                delete selectedOptions[selectedOptionId]; 
              } else { // is not currently selected
                // Add selected key to optionsList
                selectedOptions[selectedOptionId] = {} 
              }
              // call onChange function given by parent
              onChange(selectedOptions) 
            }
            
            const handleSubOptionsListChange = (optionId, subSelections) => {
              // add sub selections to current optionId
              selectedOptions[optionId] = subSelections;
              // call onChange function given by parent
              onChange(selectedOptions);
            }
            // console.log('ss',selectedOptions);
            
            return (
              <div>
                {options.map(option => (
                  <ul>
                    <Checkbox 
                      selected={option.category_id} 
                      label={option.category_name_en} 
                      children={(option.hasOwnProperty('children'))?1:0} 
                      onChange={() => {handleCheckboxClicked(option.category_id)}}
                     />
                    {(option.children != null && selectedOptions[option.category_id]) &&
                      <OptionsList
                        options={option.children}
                        selectedOptions={selectedOptions[option.category_id]} 
                        onChange={(subSelections) => handleSubOptionsListChange(option.category_id, subSelections)}
                       />
                    }
                  </ul>
                ))}
              </div>
            )
          }
          
          // Dumb checkbox component, completly controlled by parent
          const Checkbox = ({ selected, label, children, onChange }) => {
              console.log("selected",selected);
            return (
                
              <div className="form-group custom-checkbox" > 
                <input type="checkbox" className="custom-control-input"  onClick={() => onChange(!selected)}  checked={this.state.parentCategory == selected}/>
                 <label className="custom-control-label"  onClick={() => onChange(!selected)} >{label} <i style={children?{paddingLeft: '5px'}:{display: 'none'}} className={this.state.parentCategory == selected?"fa fa-angle-down":"fa fa-angle-right"} aria-hidden="true"></i></label>
                 {this.state.parentCategory == selected &&
                        <button type="button"    
                            className="btn btn-sm btn-success waves-effect waves-light"
                            onClick={() => { this.tog_scroll(selected) }} 
                            data-toggle="modal"
                            style={{marginLeft: '1rem'}}
                        >Edit</button>
                 }
                 {this.state.parentCategory == selected &&
                       <button type="button"    
                       className="btn btn-sm btn-danger waves-effect waves-light"
                       onClick={() => { this.handleDeleteSubmit( selected) }} 
                       data-toggle="modal"
                       style={{marginLeft: '1rem'}}
                   >Delete</button>
                 }
              </div>
            )
          }
        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="category" />

                        <Row>
                        
                        <Col lg="6" md="6">
                                <Card >
                                    <CardBody>
                                        <CardTitle >Category List</CardTitle>
                                        
                                        <div className="col-md-10" >
                                                    <OptionsList 
                                                        options={categoryTree} 
                                                        onChange={(selectedOptions) => this.setState({selectedOptions})}
                                                        selectedOptions={this.state.selectedOptions} 
                                                    />
                                        </div>
                                        {/* <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Category Name</th>
                                                        <th>Category Name (Bangla)</th>
                                                        <th>Parent Category</th>
                                                        <th>Is Menu?</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {categoryList.map((category, index) =>
                                                    <tr key={category.category_id.toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{category.category_name_en}</td>
                                                        <td>{category.category_name_bn}</td>
                                                        <td>{ category.parent_category == null?category.f_category_info_id:category.parent_category.category_name_en}</td>
                                                        <td><Badge color={getBadge(category.is_menu)}>{getStatusValue(category.is_menu)}</Badge></td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(category) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div> */}
                                    </CardBody>
                                    {/* {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      } */}
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{category_name_en}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }} ref={c => (this.form = c)}>
                                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    Category Created Successfully!
                                                </Alert>
                                                ) : null}
                                           <div className="form-group ">
                                               <AvField name="category_name_en" label="Category Name" value={category_name_en} className="form-control " placeholder="Enter UserName" type="text" />
                                               <AvField name="category_id" id="category_id" value={category_id}  type="hidden"  />
                                           </div>
                                           <div className="form-group">
                                               <AvField name="category_name_bn" label="Category Name (Bangla)" value={category_name_bn} className="form-control" placeholder="Enter category_name_bn" type="text" />
                                           </div>
                                           <div>
                                           <img src={imageLink+'category/'+category_icon} alt="" height="50" />
                                                <AvField  type="hidden" value={this.state.category_icon} name="category_icon" id="category_icon"/>
                                                <AvField label="Category icon" type="file" className="form-control" name="category_icon" id="category_icon" onChange={(e) =>this.handleImageUpload(e)}/>
                                            </div>
                                               <AvField type="select"className="custom-select" label="Parent" value={f_category_info_id} name="f_category_info_id" id="f_category_info_id">
                                                   <option defaultValue>Select</option>
                                                   {categoryList.map((category, index) =>
                                                        <option value={category.category_id}>{category.category_name_en}</option>
                                                    )}
                                               </AvField>
                                           <div className="custom-control custom-checkbox mb-3">
                                               <input type="checkbox" className="custom-control-input" id="is_menu" onChange={() => false} checked={is_menu} />
                                               <label className="custom-control-label" onClick={() => { this.setState({ customchk: !this.state.customchk }) }} >Is Menu?</label>
                                           </div>
                                           <div className="text-center mt-4">
                                               <Button type="submit" color="danger">Update</Button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Category</CardTitle>
                                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    Category Created Successfully!
                                                </Alert>
                                                ) : null}
                                            <div className="form-group row">
                                                <label htmlFor="example-text-input" className="col-md-2 col-form-label" >Category Name<sup style={{color:'red'}}>(*)</sup></label>
                                                <div className="col-md-10">
                                                    <AvField className="form-control" type="text" name="category_name_en" id="category_name_en"  required/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="example-search-input" className="col-md-2 col-form-label" >Category Name (Bangla)</label>
                                                <div className="col-md-10">
                                                    <AvField className="form-control" type="text" name="category_name_bn" id="category_name_bn" />
                                                </div>
                                            </div>
                                            <div>
                                                 <AvField  type="hidden" value={this.state.category_icon} name="category_icon" id="category_icon"/>
                                                 <AvField label="Category icon" type="file" className="form-control" name="category_icon_0" onChange={(e) =>this.handleImageUpload(e)} id="category_icon"/>
                                            </div>
                                            <div className="form-group row mb-0">
                                                <label className="col-md-2 col-form-label" >Parent Category </label>
                                                <div className="col-md-10">
                                                    <AvField type="select" className="custom-select" name="f_category_info_id" id="f_category_info_id" value={this.state.parentCategory}>
                                                            <option defaultValue>Select</option>
                                                        {categoryList.map((category, index) =>
                                                            <option value={category.category_id}>{category.category_name_en}</option>
                                                        )}
                                                    </AvField>
                                                </div>
                                               {/* <div className="col-md-10">
                                                    <OptionsList 
                                                        options={categoryTree} 
                                                        onChange={(selectedOptions) => this.setState({selectedOptions})}
                                                        selectedOptions={this.state.selectedOptions} 
                                                    />
                                                </div>  */}
                                            </div>
                                            {/* <div className="custom-control custom-switch mb-2" dir="ltr">
                                                <AvInput  type="checkbox" className="custom-control-input" name="is_menu" id="is_menu" />
                                                <label className="custom-control-label" htmlFor="is_menu" onClick={(e) => { this.setState({ toggleSwitch: !this.state.toggleSwitch }) }}>Is Menu?</label>
                                                
                                            </div> */}
                                            <Row>
                                                <Switch
                                                    name="status"
                                                    id="status"
                                                    onChange={this.handleChange}
                                                    checked={this.state.status}
                                                    className="react-switch"
                                                    id="normal-switch"
                                                />
                                                <span>{this.state.status ? ' Is Active' : ' Is Inactive'}</span>
                                                <AvField name="status" value={this.state.status}  type="hidden" />

                                            </Row>
                                            <div className=" mt-4">
                                                <Button type="submit" color="success">Submit</Button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.ProductCategory;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createCategory,editCategory,showCategory,daleteCatagory })(CategoryCreate));
