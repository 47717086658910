import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }

    initMenu() {
        var pathName = this.props.location.pathname;

        let menubar = JSON.parse(localStorage.getItem("menubar"));
        menubar = Object.keys(menubar).map(key => menubar[key]);

        menubar.map(function (menu, i) {

            new MetisMenu("#sideMenu" + i);

            var matchingMenuItem = null;
            var ul = document.getElementById("sideMenu" + i);
            console.log('ul', ul);
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            // if (matchingMenuItem) {
            //     this.activateParentDropdown(matchingMenuItem);
            // }

        });

    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        let menubar = JSON.parse(localStorage.getItem("menubar"));
        menubar = Object.keys(menubar).map(key => menubar[key]);
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    {menubar.map((menu, i) => (
                        <ul className="metismenu list-unstyled " id={"sideMenu" + i} >
                            <li className="menu-title color-white">{this.props.t(menu.module_name_en)}</li>
                            {(Object.keys(menu.group).map(key => menu.group[key])).map((group, j) => (
                                <li className="color-white">
                                    <Link to={(group.is_children == 0) ? group.children[0].react_url : '/#'} className="waves-effect">
                                        <i className="bx bx-home-circle"></i>
                                        <span>{this.props.t(group.main_menu_name)}</span>
                                    </Link>
                                    {group.is_children == 1 &&
                                        <ul className="sub-menu " aria-expanded="false" >
                                            {Object.keys(group.children).map(key => group.children[key]).map((children, k) => (
                                                <li className="color-white"><Link to={children.react_url}>{this.props.t(children.menu_name)}</Link></li>
                                            ))}
                                        </ul>
                                    }
                                </li>
                            ))}
                            {/* <li>
                                <Link to="/merchant" className="waves-effect">
                                    <i className="bx bx-home-circle"></i>
                                    <span>{this.props.t("Merchant")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/merchantBranch" className="waves-effect">
                                    <i className="bx bx-home-circle"></i>
                                    <span>{this.props.t("Merchant Branch")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/voucher" className="waves-effect">
                                    <i className="bx bx-home-circle"></i>
                                    <span>{this.props.t("Voucher")}</span>
                                </Link>
                            </li> */}
                        </ul>
                    ))}

                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(SidebarContent));
