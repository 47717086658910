import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// import { createSupplier, supplierList, editSupplier, supplierError } from '../../store/productSupplier/actions';
import {  MerchantBranchList, daleteMerchantBranch , marchantList , editMerchantBranch , marchantName} from '../../store/merchantBranch/actions';
import {  bankList } from '../../store/merchant/actions';
import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class MerchantBranch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            supplier: {

            },
            marchant_branch: {

            },

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }
    fetchMerchantBranchList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        MerchantBranchList(options).then((response) => {
          //  console.log(response);
            this.setState({
                merchantBranchList: response.data,
            });
            this.state.merchantBranchList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }

    componentDidMount() {
        this.fetchMerchantBranchList();  
        this.fetchCityList();     
    }



    fetchCityList = () => {
        cityList().then((response) => {
            this.setState({
                cityList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchDistrictList = (city_id) => {
        districtList(city_id).then((response) => {
            this.setState({
                districtList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchThanaList = (district_id) => {
        thanaList(district_id).then((response) => {
            this.setState({
                thanaList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchBankList = (f_bank_id) => {
        bankList(f_bank_id).then((response) => {
            this.setState({
                bankList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchMarchantList = () => {
        marchantList().then((response) => {
            this.setState({
                marchantList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    

    //modal
    tog_scroll = (marchant_branch) => {
       // console.log("marchant branch ====",marchant_branch);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.marchant_branch = marchant_branch;
        this.fetchDistrictList(marchant_branch.f_division_id);
        this.fetchThanaList(marchant_branch.f_zilla_id);
        this.fetchBankList(marchant_branch.f_bank_id);
        this.fetchMarchantList(marchant_branch.f_marchat_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    // handleValidSubmit(event, values) {

    //     this.props.createSupplier(values);
    //     this.form && this.form.reset();
    //     this.fetchMerchantBranchList();
    //     // alert("Supplier Create Successfully");
    //     // window.location.reload();
    // }

    handleDeleteSubmit(values) {
        alert("Are You Want To Delete This?");
        daleteMerchantBranch(values)
        .then((response) => {
          if (response.data.status == true) {
           //   console.log(response)
            alert(response.data.message);
            this.fetchMerchantBranchList();  
            window.location.reload();
          }
          else if (response.data.status == true) {
            alert(response.data.message);
          }
        })
        .catch((err) => {
          this.props.handleErrorResponse(err);
         // console.log('err', this.props.handleErrorResponse(err));
        });

    }


    handleEditSubmit(event, values) {

       // console.log("submit  -----",values);
        this.props.editMerchantBranch(values);
       // this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchMerchantBranchList();
        // window.location.reload();
    }

    getMarchantName = (id) => {
        marchantName(id).then((response) => {
            this.setState({
                marchant_name: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') { 
            //console.log("f_division_id"+e.target.value);
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_zilla_id') { 
            //console.log("zillllla"+e.target.value);
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchMerchantBranchList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchMerchantBranchList();
        });
    }

    render() {
        let MerchantBranchMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let bankList = [];
        let marchantList = [];
        let marchant_name = [];
        let paginator;
        if (this.state.hasOwnProperty('merchantBranchList')) {
            MerchantBranchMap = this.state.merchantBranchList.data;
            paginator = {
                'currentPage': this.state.merchantBranchList.current_page,
                'pagesCount': this.state.merchantBranchList.last_page,
                'total': this.state.merchantBranchList.total,
                'to': this.state.merchantBranchList.to,
                'from': this.state.merchantBranchList.from,
            }
        }
       // console.log("marchantListMap----",this.state.marchant_branch)
        const { id, f_marchat_id, branch_name_en, branch_name_bn, code, mobile, email, address, f_bank_id, f_division_id, f_zilla_id, f_thana_id, bank_branch_name, bank_branch_address, bank_branch_acc_no, bank_branch_route_id } = this.state.marchant_branch;
        if (this.state.hasOwnProperty('cityList')) { cityList = this.state.cityList; }
        if (this.state.hasOwnProperty('districtList')) { districtList = this.state.districtList; }
        if (this.state.hasOwnProperty('thanaList')) { thanaList = this.state.thanaList; }
        if (this.state.hasOwnProperty('bankList')) { bankList = this.state.bankList; }
        if (this.state.hasOwnProperty('marchantList')) { marchantList = this.state.marchantList; }
        if (this.state.hasOwnProperty('marchant_name')) { marchant_name = this.state.marchant_name; }
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        //console.log("this.props.success", this.props.success)
        if (this.props.success != '') {
            setTimeout(function () {
                window.location.reload();
            }, 1000);

        }

        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="MerchantBranch" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader>
                                        <Link to={'/merchantBranchCreate'}><Button className="btn btn-success pull-right">Add New Merchant Branch</Button></Link>
                                    </CardHeader>
                                    <CardBody>
                                        <CardTitle>Merchant Branch List</CardTitle>
                                        <div className="table-responsive-sm">
                                            <DataTabeSearch handleInputChange={this.handleInputChange} />
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Merchant Name</th>
                                                        <th>Merchant Branch Name</th>
                                                        <th>Merchant Branch Name (Bangla)</th>
                                                        <th>Code</th>
                                                        <th>Address</th>
                                                        <th>Mobile</th>
                                                        <th>Email</th>
                                                        <th>Bank A/C No.</th>
                                                        <th colspan="2" >Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {MerchantBranchMap.map((merchant_branch, index) =>
                                                        <tr key={merchant_branch.id.toString()}>
                                                            <td scope="row">{index + 1}</td>
                                                          
                                                            <td>{merchant_branch.marchant.name_en}</td>

                                                            <td>{merchant_branch.branch_name_en}</td>
                                                            <td>{merchant_branch.branch_name_bn}</td>
                                                            <td>{merchant_branch.code}</td>
                                                            <td>{merchant_branch.address}</td>
                                                            <td>{merchant_branch.mobile}</td>
                                                            <td>{merchant_branch.email}</td>
                                                            <td>{merchant_branch.bank_branch_acc_no}</td>
                                                            <td>
                                                            <button type="button"
                                                                className="btn btn-sm btn-primary waves-effect waves-light"
                                                                onClick={() => { this.tog_scroll(merchant_branch) }}
                                                                data-toggle="modal"
                                                            >Edit</button>
                                                            </td>

                                                            <td>
                                                            <button type="button"
                                                                className="btn btn-sm btn-danger waves-effect waves-light"
                                                                onClick={() => { this.handleDeleteSubmit( merchant_branch.id) }}
                                                                >Delete</button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                        <CardFooter className="p-6">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    }
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">{branch_name_en}</h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} >
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    Merchant Branch Updated Successfully!
                                                </Alert>
                                            ) : null}
                                            <div className="col-md-12">
                                                <AvField name="code" label="Marchant Branch Code" value={code} className="form-control" type="text" readOnly />
                                            </div>

                                            <div className="col-md-12">
                                                    <AvField type="select" className="custom-select" label={reqLabel('Merchant Name')} value={f_marchat_id} name="f_marchat_id" id="f_merchant_id" onChange={(e) => this.handleInputChange(e)} required >
                                                        <option defaultValue>Select Merchant Name</option>
                                                        {marchantList.map((marchant, index) =>
                                                           <option value={marchant.id}>{marchant.name_en}</option>
                                                        )}
                                                        
                                                    </AvField>
                                            </div>

                                            <div className="col-md-12">
                                                <AvField name="branch_name_en" label={reqLabel("Marchant Branch Name")} value={branch_name_en} className="form-control " type="text" required/>
                                                <AvField name="marchant_branch_id" id="marchant_branch_id" value={id} type="hidden" />
                                            </div>
                                            <div className="col-md-12">
                                                <AvField name="branch_name_bn" label="Marchant Branch Name (Bangla)" value={branch_name_bn} className="form-control" type="text" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label="Address" type="text" value={address} name="address" id="address" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label={reqLabel("Mobile")} type="number" value={mobile} name="mobile" id="mobile" required/>
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label={reqLabel("Email")} type="email" value={email} name="email" id="supplier_mobile" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField type="select" className="custom-select" label={reqLabel("Bank Name")} value={f_bank_id} name="f_bank_id" id="f_division_id" >
                                                    <option defaultValue>Select Bank</option>
                                                    {bankList.map((bank, index) =>
                                                        <option value={bank.parent_bank_id}>{bank.parent_bank_name}</option>
                                                    )}
                                                </AvField>
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label={reqLabel("Bank Branch Name")} type="text" value={bank_branch_name} name="bank_branch_name" id="bank_branch_name" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label={reqLabel("Bank Branch Address")} type="text" value={bank_branch_address} name="bank_branch_address" id="bank_branch_address" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label={reqLabel("Bank A/C No.")} type="text" value={bank_branch_acc_no} name="bank_branch_acc_no" id="bank_branch_acc_no" />
                                            </div>

                                            <div className="col-md-12">
                                                <AvField className="form-control" label={reqLabel("Bank Route NO.")} type="number" value={bank_branch_route_id} name="bank_branch_route_id" id="bank_branch_route_id" />
                                            </div>






                                            <div className="col-md-12">
                                                <AvField type="select" className="custom-select" label={reqLabel("Division")} value={f_division_id} name="f_division_id" id="f_division_id" onChange={(e) => this.handleInputChange(e)} >
                                                    <option defaultValue>Select Division</option>
                                                    {cityList.map((city, index) =>
                                                        <option value={city.division_id}>{city.division_name}</option>
                                                    )}
                                                </AvField>
                                            </div>

                                            <div className="col-md-12">
                                                <AvField type="select" className="custom-select" label={reqLabel("District")} value={f_zilla_id} name="f_zilla_id" id="f_zilla_id" onChange={(e) => this.handleInputChange(e)}>
                                                    <option defaultValue>Select District</option>
                                                    {districtList.map((district, index) =>
                                                        <option value={district.district_id}>{district.district_name}</option>
                                                    )}
                                                </AvField>
                                            </div>

                                            <div className="col-md-12">
                                                <AvField type="select" className="custom-select" label={reqLabel("Thana")} value={f_thana_id} name="f_thana_id" id="f_thana_id" >
                                                    <option defaultValue>Select Thana</option>
                                                    {thanaList.map((thana, index) =>
                                                        <option value={thana.thana_id}>{thana.thana_name}</option>
                                                    )}
                                                </AvField>
                                            </div>
                                            <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.MerchantBranch;
    return { msg, error, success };
}

// export default withRouter(connect(mapStatetoProps, { createSupplier, editSupplier, supplierError })(MerchantBranch));
export default withRouter(connect(mapStatetoProps, {daleteMerchantBranch, editMerchantBranch})(MerchantBranch));