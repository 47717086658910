import { FUEL_ERROR,FUEL_SUCCESS,EDIT_FUEL } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const fuel = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_FUEL:
            state = { ...state }
            break;
        case FUEL_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case FUEL_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default fuel;