import React, { Component } from 'react';
import {Row,Col,Card,CardBody,CardHeader,CardFooter,Table,Container } from "reactstrap";
import { Link } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// actions
import {getRoleList} from '../../store/auth/role/actions';
import {DataTabeSearch} from '../Settings/Common/DataTabeSearch'
import {checkPermission,permissionEnum} from '../../services/service-provider';

// import DocumentTitle from 'react-document-title';
import {checkStatus} from "../../services/httpService";
import {CustomPagination} from "../Settings/Common/CustomPagination";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

function RoleRow(props) {
    const role = props.role;
    const roleLink = `/roles/${role.id}`;
  
    return (
      <tr key={role.id.toString()}>
          <td scope="row">{props.index}</td>
        <td>{role.name}</td>
        <td>{role.guard_name}</td>
        <td>{role.permissions_count}</td>
          {checkPermission(permissionEnum.ROLE_UPDATE) &&
              <td><Link to={roleLink} className="btn btn-info float-center">
                  <i className="fa fa-edit"></i>
              </Link></td>
          }
      </tr>
    )
  }

class Roles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            per_page: 15,
            search: '',
            data: {}
        };
    }

    fetchRoleList = () => {

        checkStatus();
          let options = {
              page: this.state.page,
              per_page: this.state.per_page,
              search: this.state.search
          }
  
          getRoleList(options)
              .then((response) => {
                  this.setState({
                      data: response.data
                  })
              })
              .catch((err) => {
                  console.log(err);
              })
  
      }
        componentDidMount() {
              this.fetchRoleList();
        }
  
      handlePageClick = (e, index) => {
          e.preventDefault()
          this.setState({
              page: index
          },() => {
              this.fetchRoleList();
          });
      }
  
      handleInputChange = (e) => {
          this.setState({[e.target.name]: e.target.value},() => {
              this.fetchRoleList();
          });
      }
        render() {

            let roleList = [];
            let paginator
      
            const {data, page, per_page} = this.state;
      
            if (data.hasOwnProperty('data')) {
                roleList = data.data;
                paginator = {
                    'currentPage':data.current_page,
                    'pagesCount':data.last_page,
                    'total':data.total,
                    'to':data.to,
                    'from':data.from,
                };
            }
      
            return (
                <React.Fragment>
                <div className="page-content">
                    <Container fluid>
    
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Roles" breadcrumbItem="Roles for User" />
                        <Row>
                <Col xl={12}>
                  <Card>
                    <CardHeader>
                      <i className="fa fa-align-justify"></i> Roles <small className="text-muted">List</small>
                        {checkPermission(permissionEnum.ROLE_CREATE) &&
                            <Link to="/create/role">
                                <button className="btn btn-primary float-right">Create Role</button>
                            </Link>
                        }
                    </CardHeader>
                    <CardBody>
                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                        <Table responsive striped>
                        <thead>
                          <tr>
                            <th scope="col">s.no</th>
                            <th scope="col">name</th>
                            <th scope="col">guard_name</th>
                            <th scope="col">permissions</th>
                              {checkPermission(permissionEnum.ROLE_UPDATE) &&
                                  <th scope="col">action</th>
                              }
                          </tr>
                        </thead>
                        <tbody>
                          {roleList.map((role, index) =>
                            <RoleRow key={index} role={role} index={index + 1 + ((page-1) * per_page)}/>
                          )}
                        </tbody>
                      </Table>
                    </CardBody>
                      {paginator &&
                          <CardFooter className="p-4">
                              <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                          </CardFooter>
                      }
                  </Card>
                </Col>
              </Row>
    
                    </Container>
                </div>
            </React.Fragment>
            );
        }
}
            
const mapStatetoProps = state => {
    const { msg,error,success } = state.Role;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps,{getRoleList})(Roles));

