import { BRAND_ERROR,BRAND_SUCCESS,EDIT_BRAND,SHOW_BRAND,CREATE_BRAND } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createBrand = (brand) => {
    console.log("two",brand)
    return {
        type: CREATE_BRAND,
        payload: { brand }
    }
}

export const showBrand = (brand_id) => {
    return {
        type: SHOW_BRAND,
        payload: { brand_id }
    }
}

export const editBrand = (brand) => {
    return {
        type: EDIT_BRAND,
        payload: { brand }
    }
}



export const brandSuccess = (msg) => {
    return {
        type: BRAND_SUCCESS,
        payload: msg
    }
}


export function brandShow(brand_id){
    return getApi().get('users/id/'+brand_id)
}


export function brandList(params){
    return getApi().get('brand/list',{
        params: params
    })
}

export function brandListAll(){
    return getApi().get('brand/list/all')
}
export const brandError = (error) => {
    return {
        type: BRAND_ERROR,
        payload: error
    }
}

export function productBrandImageUpload(params,image_id){
        console.log("image_id",image_id)
        if(image_id){
            return getApi().put('brand/images/edit/'+image_id,params)
        }else{
            return getApi().post('brand/images/upload',params);
        }
        // return getApi().put('brand/images/edit/'+image_id)
}


export function voucherImageUpload(params,image_id){
    console.log("voucherImageUpload",image_id)
    if(image_id){
        return getApi().put('vouchar/images/edit/'+image_id,params)
    }else{
        return getApi().post('vouchar/images/upload',params);
    }
    // return getApi().put('brand/images/edit/'+image_id)
}

export const daleteBrand = (brand_id, params) => {
    //  console.log("two",marchant_id)
      return getApi().delete('brand/'+brand_id,{
          // params: params
      })
  }

