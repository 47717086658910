import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_ROLE,ROLE_SUCCESS,EDIT_ROLE} from './actionTypes';

import { roleSuccess, roleError} from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../../helpers/self_accounts_settings_helper';

// function* createRole({ payload: { role } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//               console.log('bnsdsdb',role);
//              const response = yield call(postJwtData, 'user/role/create',role);
//              if(response.success == false){
//                 // alert(response.message['message']);
//                 yield put(roleError(response.message.message));
//             }else if(response.success == true){
//                 yield put(roleSuccess(response.data.message));
//             } 
//           }
//     } catch (error) {
//         yield put(roleError(error));
//     }
// }



// function* editRole({ payload: { role } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(editJwtData, 'user/role/'+role.role_id, role);
//              if(response.success == false){
//                 // alert(response.message['message']);
//                 yield put(roleError(response.message.message));
//             }else if(response.success == true){
//                 yield put(roleSuccess(response.data.message));
//             } 
//           }
          
          
//     } catch (error) {
//         yield put(roleError(error));
//     }
// }


export function* watchRole() {
    // yield takeEvery(EDIT_ROLE, editRole)
    // yield takeEvery(CREATE_ROLE, createRole)
}

function* RoleSaga() {
    yield all([
        fork(watchRole),
    ]);
}

export default RoleSaga;