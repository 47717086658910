import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.label`
position : relative,
display :inline-block,
width:40px,
height:16px
   >input{
    display:none;
}
`;

const Slider = styled.span`
  position : absolute;
  cursor : pointer;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: #ddd;
  transition:0.4s;
  border-radius:15px;

&:before{
    position : absolute;
    content:' ';
    height:15px;
    width:15px;
    background-color:#999;
    transition:0.2s;
    border_radius:50%; 
}`;

const SliderInput = styled.input`
&:checked + ${Slider}{
background-color:#0365b2;
&:before{
    transform : translateX{15px};
    background-color:white;

}
}`;

export default class ToggleSwitch extends React.Component{
render(){
    return(       
<Container>
    <SliderInput type='checkbox'  checked={this.props.status} onChange={(e)=> this.props.onChange(e.target.status)}/>
</Container>
    );
}
}

ToggleSwitch.propTypes ={
    checked:PropTypes.bool,
    onchange:PropTypes.func,

}