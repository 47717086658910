import { MEMBER_ERROR,MEMBER_SUCCESS,EDIT_MEMBER,SHOW_MEMBER,CREATE_MEMBER } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createMember = (member) => {
    return {
        type: CREATE_MEMBER,
        payload: { member }
    }
}

export const showMember = (member_id) => {
    return {
        type: SHOW_MEMBER,
        payload: { member_id }
    }
}

export const editMember = (member) => {
    return {
        type: EDIT_MEMBER,
        payload: { member }
    }
}



export const memberSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: MEMBER_SUCCESS,
        payload: msg
    }
}


export function memberShow(member_id){
    return getApi().get('users/id/'+member_id)
}


export function memberList(params){
    return getApi().get('member',{
        params: params
    })
}

export const memberError = (error) => {
    return {
        type: MEMBER_ERROR,
        payload: error
    }
}
