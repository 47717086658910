import { PRODUCT_STOCK_ERROR,PRODUCT_STOCK_SUCCESS,EDIT_PRODUCT_STOCK,SHOW_PRODUCT_STOCK,CREATE_PRODUCT_STOCK } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createProductStock = (productStock) => {
    return {
        type: CREATE_PRODUCT_STOCK,
        payload: { productStock }
    }
}

export const showProductStock = (stock_id) => {
    return {
        type: SHOW_PRODUCT_STOCK,
        payload: { stock_id }
    }
}
 
export const editProductStock = (productStock) => {
    return {
        type: EDIT_PRODUCT_STOCK,
        payload: { productStock }
    }
}



export const productStockSucess = (msg) => {
    console.log('msg',msg)
    return {
        type: PRODUCT_STOCK_SUCCESS,
        payload: msg
    }
}


export function productStockShow(stock_id){
    return getApi().get('users/id/'+stock_id)
}


export function productStockList(params){
    return getApi().get('productStock/list',{
        params: params
    })
}

export function searchProduct(keyword){
    return getApi().get('product/search/'+keyword)
}

export function searchSupplier(keyword){
    return getApi().get('warehouseSupplierMap/search/'+keyword)
}

export const productStockError = (error) => {
    return {
        type: PRODUCT_STOCK_ERROR,
        payload: error
    }
}
