import React, { Component } from 'react';
import { Container,Row,Col,Card,CardBody,Button,Input,FormText,FormGroup,Label,Form,CardHeader,CustomInput,CardFooter,Table} from "reactstrap";

import { Link } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {checkPermission,permissionEnum} from '../../services/service-provider';
import {checkStatus} from '../../services/httpService';
import {DataTabeSearch} from '../Settings/Common/DataTabeSearch'
import {CustomPagination} from "../Settings/Common/CustomPagination";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
import {getUserList} from '../../store/auth/profile/actions';

function UserRow(props) {
    const user = props.user
    const userLink = `/users/${user.id}`
    // const useractivityLink = `/users/activity`
    
    return (
      <tr key={user.id.toString()}>
          <td scope="row">{props.index}</td>
          <td>{user.name}</td>
          <td>{user.mobile}</td>
          <td>{user.email}</td>
          <td>
          {user.roles.map((role, index) =>
           
            <Button size="sm" color={'primary'}>{role.name}</Button>
          
            )}
            
            {/* {user.roles.map((role, index) => {
              return role.name
          })} */}
          </td>
          <td>{user.created_at}</td>
          {/* <td><Link to={userLink}><Badge color={getBadge(user.status)}>{getStatusValue(user.status)}</Badge></Link></td> */}
          {/* <td><Link to={useractivityLink}><i className="fa fa-archive"></i></Link></td> */}
          {checkPermission(permissionEnum.USER_UPDATE) &&
              <td>
                  <Link to={userLink} className="btn btn-info float-right">
                      <i className="fa fa-edit"></i>
                  </Link>
              </td>
          }
      </tr>
    )
  }

class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            per_page: 15,
            search: '',
            data: {}
        };
    }

    fetchUserList = () => {

      checkStatus();
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }

        getUserList(options)
            .then((response) => {
                this.setState({
                    data: response.data
                })
            })
            .catch((err) => {
                console.log(err);
            })

    }
      componentDidMount() {
            this.fetchUserList();
      }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        },() => {
            this.fetchUserList();
        });
    }

    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchUserList();
        });
    }

    render() {

        let userList = [];
        let paginator
  
        const {data, page, per_page} = this.state;
  
        if (data.hasOwnProperty('data')) {
            userList = data.data;
            paginator = {
                'currentPage':data.current_page,
                'pagesCount':data.last_page,
                'total':data.total,
                'to':data.to,
                'from':data.from,
            };
        }
  
      return (

        <React.Fragment>
        <div className="page-content">
            <Container fluid={true}>
                <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <i className="fa fa-align-justify"></i> Users <small className="text-muted">List</small>
                    {checkPermission(permissionEnum.USER_CREATE) &&
                        <Link to="/users/create">
                            <button className="btn btn-primary float-right">Create User</button>
                        </Link>
                    }
                </CardHeader>
                <CardBody>
                    <DataTabeSearch handleInputChange={this.handleInputChange}/>
                    <Table responsive striped>
                    <thead>
                      <tr>
                        <th scope="col">s.no</th>
                        <th scope="col">name</th>
                        <th scope="col">mobile</th>
                        <th scope="col">email</th>
                        <th scope="col">role</th>
                        <th scope="col">date</th>
                        {/* <th scope="col">activity</th> */}
                          {checkPermission(permissionEnum.USER_UPDATE) &&
                          <th scope="col">action</th>
                          }
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map((user, index) =>
                        <UserRow key={index} user={user} index={index + 1 + ((page -1 ) * per_page)}/>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
                  {paginator &&
                      <CardFooter className="p-4">
                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                      </CardFooter>
                  }
              </Card>
            </Col>
            </Row>
                    </Container>
                </div>
            </React.Fragment>
      )
    }

}
            
const mapStatetoProps = state => {
    const { msg,error,success } = state.Profile;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps,{getUserList})(Users));

