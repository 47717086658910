import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, CardSubtitle,Container } from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

class IncomeExpenseTypeCreate extends Component {
    constructor(props) {
        super(props);
        this.state = { customchk: true, toggleSwitch: true };
    }

     // handleValidSubmit
     handleValidSubmit(event, values) {
        this.props.editProfile(values);
        // profileShow(this.state.user_id).then((response) => {
        //     this.setState({
        //         user_name :response.data.user_name,
        //         email :response.data.email,
        //         user_id : response.data.user_id,  
        //         mobile : response.data.mobile,  
        //         first_name : response.data.first_name,  
        //         last_name : response.data.last_name,  
        //     });
        // })
        // .catch((err) => {
        //     console.log(err);
        // })

    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Income & Expense Type" />

                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Income & Expense Type</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }}>
                                            <div className="form-group row">
                                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Text</label>
                                                <div className="col-md-10">
                                                    <input className="form-control" type="text" defaultValue="Artisanal kale" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="example-email-input" className="col-md-2 col-form-label">Email</label>
                                                <div className="col-md-10">
                                                    <input className="form-control" type="email" defaultValue="bootstrap@example.com" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="example-tel-input" className="col-md-2 col-form-label">Telephone</label>
                                                <div className="col-md-10">
                                                    <input className="form-control" type="tel" defaultValue="1-(555)-555-5555" />
                                                </div>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default IncomeExpenseTypeCreate;
