import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_FUEL,FUEL_SUCCESS,EDIT_FUEL,SHOW_FUEL } from './actionTypes';

import { fuelSuccess, fuelError,fuelShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createFuel({ payload: { fuel } }) {
console.log('fuel',fuel);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'fuel/create',fuel);

             yield put(fuelSuccess(response));
          }
    } catch (error) {
        yield put(fuelError(error));
    }
}


function* editFuel({ payload: { fuel } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'fuel/'+fuel.fuel_id, fuel);
             yield put(fuelSuccess(response));
          }
          
          
    } catch (error) {
        yield put(fuelError(error));
    }
}


function* showFuel({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);
             yield put(fuelShow(response));
          }
          
          
    } catch (error) {
        yield put(fuelError(error));
    }
}

export function* watchFuel() {
    yield takeEvery(EDIT_FUEL, editFuel)
    yield takeEvery(CREATE_FUEL, createFuel)
    yield takeEvery(SHOW_FUEL, showFuel)
}

function* FuelSaga() {
    yield all([
        fork(watchFuel),
    ]);
}

export default FuelSaga;