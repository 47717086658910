import { VOUCHER_ERROR,VOUCHER_SUCCESS,EDIT_VOUCHER } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const Voucher = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_VOUCHER:
            state = { ...state }
            break;
        case VOUCHER_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case VOUCHER_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Voucher;