import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_WAREHOUSESUPPLIERMAP,WAREHOUSESUPPLIERMAP_SUCCESS,EDIT_WAREHOUSESUPPLIERMAP,SHOW_WAREHOUSESUPPLIERMAP } from './actionTypes';

import { warehouseSupplierMapSuccess, warehouseSupplierMapError,warehouseSupplierMapShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createWarehouseSupplierMap({ payload: { warehouseSupplierMap } }) {
console.log('warehouseSupplierMap',warehouseSupplierMap);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'warehouseSupplierMap/create',warehouseSupplierMap);

             yield put(warehouseSupplierMapSuccess(response));
          }
    } catch (error) {
        yield put(warehouseSupplierMapError(error));
    }
}


function* editWarehouseSupplierMap({ payload: { warehouseSupplierMap } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'warehouseSupplierMap/'+warehouseSupplierMap.map_id, warehouseSupplierMap);
             yield put(warehouseSupplierMapSuccess(response));
          }
          
          
    } catch (error) {
        yield put(warehouseSupplierMapError(error));
    }
}


function* showWarehouseSupplierMap({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);
             yield put(warehouseSupplierMapShow(response));
          }
          
          
    } catch (error) {
        yield put(warehouseSupplierMapError(error));
    }
}

export function* watchWarehouseSupplierMap() {
    yield takeEvery(EDIT_WAREHOUSESUPPLIERMAP, editWarehouseSupplierMap)
    yield takeEvery(CREATE_WAREHOUSESUPPLIERMAP, createWarehouseSupplierMap)
    yield takeEvery(SHOW_WAREHOUSESUPPLIERMAP, showWarehouseSupplierMap)
}

function* WarehouseSupplierMapSaga() {
    yield all([
        fork(watchWarehouseSupplierMap),
    ]);
}

export default WarehouseSupplierMapSaga;