import React, { Component } from "react";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter, Media, Table } from "reactstrap";
import { Link } from "react-router-dom";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import {orderList,orderShow} from '../../store/order/actions';
// import "../../pages/Order/Order.scss"

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

function TestRow(props) {
    const order = props.order
    // const testLink = `/order/details/${order.order_id}`
  
   const getBadge = (status) => {
    return status == 1 ? 'success' : 'secondary'
  }
  return (
    <tr key={order.order_id .toString()}>
    <td scope="row">{props.index}</td>
    <td className="text-center">{order.order_date}</td>
    <td className="text-left">{order.name} & {order.phone}</td>
    <td className="text-left">{order.order_code}</td>
    <td className="text-center">{order.quantity}</td>
    <td className="text-right">{order.total_amount}</td>
    <td className="text-right">{order.discount}</td>
    <td className="text-right"></td>
    <td className="text-right">{order.final_amount}</td>
    <td className="text-right">{order.paid_amount}</td>
    {/* <td className="text-center">{order.email}</td>
    <td className="text-center">{order.address}</td> */}
    <td className="text-right"><Link to={ `/order-details/${order.order_id}`}><i className="fa fa-eye fa-2x"></i></Link></td>

</tr>
  )
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: true,
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            modal_scroll: false,
            image_modal_scroll: false,
            uploadImage: {},
            order: {
                
            },
            statusValue:''
        };
    }

    componentDidMount()
    {
            this.fetchorderList();
            this.fetchorderDetails();  
    }

    fetchorderList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search,
            'status_search': this.state.statusValue
        }
        orderList(options).then((response) => {
            this.setState({
                orderList :response.data.data,
                searchBtn: response.data.searchBtn 
            });
            this.state.orderList = response.data.data;
            this.state.searchBtn = response.data.searchBtn;

        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchorderDetails = (order_id) => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        orderShow(options,order_id).then((response) => {
            this.setState({
                orderDetails :response.data, 
            });
            this.state.orderDetails = response.data;
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
      tog_scroll = (order) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.order = order;
        this.fetchBrandCategoryList(order.f_category_info_id);
        this.removeBodyCss();
      }

      tog_image_scroll = (order) => {
        this.setState(prevState => ({
          image_modal_scroll: !prevState.image_modal_scroll,
        }));
        this.state.order = order;
        this.removeBodyCss();
      }


      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            this.props.createProduct(values);
            this.fetchorderList();     
        }

       
       handleEditSubmit(event, values) {  
            this.props.editProduct(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchorderList();
       }


       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchorderList(); 
        });
       }

       orderStatusListFilter(status_id){
        this.setState({
            statusValue: status_id
        });
        this.state.statusValue = status_id;
        this.fetchorderList(); 
        
       }
       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index,

            },() => {
                this.fetchorderList();
            });
        }

    render() {
        let orderListMap = [];
        let searchBtnListMap = [];
            if (this.state.hasOwnProperty('orderList')) {
    
                orderListMap =this.state.orderList.data;
                searchBtnListMap =this.state.searchBtn;
        }
        const imageLink =process.env.REACT_APP_IMAGE_URL;
        // const imageLink ='http://localhost:8083/';
        // const {product_id,product_code,product_name_bn,product_name_en,product_bar_code,f_category_info_id,f_brand_id,f_unit_id,product_stock_balance,product_buy_price,product_sale_price,product_market_price,discount,product_description,product_weight,product_sku_code,product_specification,minimum_oder_quantity,product_meta_tag,f_discount_type,status} = this.state.order;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={this.props.t('Dashboard')} breadcrumbItem={this.props.t('Dashboard')} />

                        <Row>
                            <Col xl="4">
                                <WelcomeComp />
                                <MonthlyEarning />
                            </Col>
                            <Col xl="8">
                                <Row>{
                                    
                                searchBtnListMap != undefined && searchBtnListMap!=null && searchBtnListMap.map((status,index) =>
                                <Col md="4">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">{status.action_name}</p>
                                                    <h4 className="mb-0">{status.orderSum}</h4>
                                                </Media>
                                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                        <span className="avatar-title">
                                                            <i className="bx bx-comment-dots"></i>
                                                        </span>
                                                    </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                                )}
                                    {/* {
                                        this.state.reports.map((report, key) =>
                                            <Col md="4" key={"_col_" + key}>
                                                <Card className="mini-stats-wid">
                                                    <CardBody>
                                                        <Media>
                                                            <Media body>
                                                                <p className="text-muted font-weight-medium">{report.title}</p>
                                                                <h4 className="mb-0">{report.description}</h4>
                                                            </Media>
                                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                                <span className="avatar-title">
                                                                    <i className={"bx " + report.iconClass + " font-size-24"}></i>
                                                                </span>
                                                            </div>
                                                        </Media>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )
                                    } */}
                                </Row>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Card>
                                <CardBody>
                                        <CardTitle>Product Order List</CardTitle>
                                        <div className="table-responsive-xs">
                                        <input type="hidden" id="status_search" name='status_search' value={this.state.statusValue} onChange={(e) => {this.handleChange(e)}}/>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">SL</th>
                                                        <th className="text-center">Order Date</th>
                                                        <th className="text-left">Name and Phone</th>
                                                        <th className="text-left">Order Code</th>
                                                        <th className="text-center">Qty</th>
                                                        <th className="text-right">Total TK</th>
                                                        <th className="text-right">Discount TK</th>
                                                        <th >Courier/Packing Cost</th>
                                                        <th className="text-right">Grand TK</th>
                                                        <th className="text-right">Paid TK</th>
                                                        {/* <th className="text-center">Email</th>
                                                        <th className="text-center">Address</th> */}
                                                        <th className="text-right">Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {orderListMap.map((order, index) =>
                                                <TestRow key={index} order={order} index={index + 1}/>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                </CardBody>
                            </Card>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Dashboard);
