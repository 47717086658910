import { HOME_FEATURES_ERROR,HOME_FEATURES_SUCCESS,EDIT_HOME_FEATURES,SHOW_HOME_FEATURES,CREATE_HOME_FEATURES,CREATE_HOME_FEATURES_MAP,HOME_FEATURES_DELETE} from './actionTypes';
import {getApi} from "../../services/httpService";

export const createHomeFeatures = (features) => {
    return {
        type: CREATE_HOME_FEATURES,
        payload: { features }
    }
}

export const showHomeFeatures = (features_id) => {
    return {
        type: SHOW_HOME_FEATURES,
        payload: { features_id }
    }
}

export const editHomeFeatures = (features) => {
    console.log("three",features)
    return {
        type: EDIT_HOME_FEATURES,
        payload: { features }
    }
}



export const homeFeaturesSuccess = (msg) => {
    return {
        type: HOME_FEATURES_SUCCESS,
        payload: msg
    }
}

export function homefeaturesShow(home_features_id){
    return getApi().get('features/'+home_features_id)
}

export function featuresShow(home_feature_id){
    return getApi().get('features/map/details/'+home_feature_id)
}

export function homefeaturesList(params){
    return getApi().get('features/list',{
        params: params
    })
}

export const homeFeaturesError = (error) => {
    return {
        type: HOME_FEATURES_ERROR,
        payload: error
    }
}

export const deletefeatures = (features_id) => {
    return {
        type: HOME_FEATURES_DELETE,
        payload: { features_id }
    }
}

export const createHomeFeaturesMap = (features) => {
    return {
        type: CREATE_HOME_FEATURES_MAP,
        payload: { features }
    }
}
