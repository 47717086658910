import React, { Component } from "react";
import {CardImg,Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createWarehouseSupplierMap,warehouseSupplierMapList,editWarehouseSupplierMap} from '../../../store/warehouseSupplierMap/actions';
import { createPump,pumpList,editPump,thanaList,cityList,districtList} from '../../../store/petrolPump/actions';
import { createWarehouse,warehouseList,editWarehouse} from '../../../store/warehouse/actions';
import { createSupplier,supplierList,editSupplier} from '../../../store/productSupplier/actions';

const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }

class WarehouseSupplierMapCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                supplier: {
                    
                },
                // displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchwarehouseSupplierMapList();  
            this.fetchwarehouseList();     
            this.fetchsupplierList();     
            this.fetchCityList();     
    }


    fetchwarehouseList = () => {
        let options = {
            page: this.state.page,
            per_page: 50,
            search: this.state.search
        }
        warehouseList(options).then((response) => {
            this.setState({
                warehouseList :response.data.data, 
            });
            this.state.warehouseList = response.data.data;
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchsupplierList = () => {
        let options = {
            page: this.state.page,
            per_page: 50,
            search: this.state.search
        }
        supplierList(options).then((response) => {
            this.setState({
                supplierList :response.data.data, 
            });
            this.state.supplierList = response.data.data;
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchwarehouseSupplierMapList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        warehouseSupplierMapList(options).then((response) => {
            this.setState({
                warehouseSupplierMapList :response.data, 
            });
            this.state.warehouseSupplierMapList = response.data;
        })
        .catch((err) => {
            console.log(err);
        })
    }


    fetchCityList = () => {
        cityList().then((response) => {
            this.setState({
                cityList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchDistrictList = (city_id) => {
        districtList(city_id).then((response) => {
            this.setState({
                districtList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchThanaList = (district_id) => {
        thanaList(district_id).then((response) => {
            this.setState({
                thanaList :response.data, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            this.props.createWarehouseSupplierMap(values);
            this.fetchwarehouseSupplierMapList();
            alert("WarehouseSupplier Create Successfully");
            // window.location.reload();

        }

       
       handleEditSubmit(event, values) {  
            this.props.editWarehouseSupplierMap(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchwarehouseSupplierMapList();
       }

      
        handleInputChange = (e) => {
            if(e.target.name == 'f_division_id'){
                this.fetchDistrictList(e.target.value);
            }
            if(e.target.name == 'f_district_id'){
                this.fetchThanaList(e.target.value);
            }
            this.setState({[e.target.name]: e.target.value},() => {
                this.fetchwarehouseSupplierMapList();
                this.fetchsupplierList();
            });
        }

       handlePageClick = (e, index) => {
            // this.state.displayHide= { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchwarehouseSupplierMapList();
                // this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            });
        }

    render() {
        let warehouseSupplierMapLists = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let supplierList = [];
        let warehouseList = [];
        let paginator;
        if (this.state.hasOwnProperty('warehouseSupplierMapList')) {
            warehouseSupplierMapLists = this.state.warehouseSupplierMapList.data;
            paginator = {
                'currentPage':this.state.warehouseSupplierMapList.current_page,
                'pagesCount':this.state.warehouseSupplierMapList.last_page,
                'total':this.state.warehouseSupplierMapList.total,
                'to':this.state.warehouseSupplierMapList.to,
                'from':this.state.warehouseSupplierMapList.from,
            }
        }
        const {map_id,map_code,f_product_warehouse_id,f_product_supplier_id,detail_address,f_division_id,f_district_id,f_thana_id} = this.state.supplier;
        if(this.state.hasOwnProperty('cityList')){cityList = this.state.cityList;}
        if(this.state.hasOwnProperty('districtList')){districtList = this.state.districtList;}
        if(this.state.hasOwnProperty('thanaList')){thanaList = this.state.thanaList;}
        if(this.state.hasOwnProperty('supplierList')){supplierList = this.state.supplierList;}
        if(this.state.hasOwnProperty('warehouseList')){warehouseList = this.state.warehouseList;}
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                    {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="supplier" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>supplier List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Supplier Name</th>
                                                        <th>Warehouse Name</th>
                                                        <th>Code</th>
                                                        <th>Address</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {warehouseSupplierMapLists.map((warehouseSupplierMap, index) =>
                                                    <tr key={warehouseSupplierMap.map_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{warehouseSupplierMap.supplier == null?warehouseSupplierMap.f_product_supplier_id:warehouseSupplierMap.supplier.supplier_name_en}</td>
                                                        <td>{warehouseSupplierMap.warehouse == null?warehouseSupplierMap.f_product_warehouse_id:warehouseSupplierMap.warehouse.warehouse_name_en}</td>
                                                        <td>{warehouseSupplierMap.map_code}</td>
                                                        <td>{warehouseSupplierMap.detail_address}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(warehouseSupplierMap) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">Edit</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               supplier Updated Successfully!
                                           </Alert>
                                           ) : null}
                                           <div className="col-md-12">
                                               <AvField name="map_code" readonly label="Map Code" value={map_code} className="form-control"  type="text" />
                                           </div>
                                           <div className="col-md-12">
                                               <AvField type="select" className="custom-select" label={reqLabel("Supplier")}  value={f_product_supplier_id}  name="f_product_supplier_id" id="f_product_supplier_id"  >
                                                            <option defaultValue>Select Supplier</option>
                                                            {supplierList.map((supplier,index)=>
                                                                <option value={supplier.supplier_id}>{supplier.supplier_name_en}</option>
                                                            )}
                                                </AvField>
                                           </div>
                                           <div className="col-md-12">
                                               <AvField type="select" className="custom-select" label={reqLabel("Warehouse")}  value={f_product_warehouse_id}   name="f_product_warehouse_id" id="f_product_warehouse_id"  >
                                                            <option defaultValue>Select Warehouse</option>
                                                            {warehouseList.map((warehouse,index)=>
                                                                <option value={warehouse.warehouse_id}>{warehouse.warehouse_name_en}</option>
                                                            )}
                                                </AvField>
                                               <AvField name="map_id" id="map_id" value={map_id}  type="hidden"  />
                                           </div>
                                           <div className="col-md-12">
                                                     <AvField className="form-control" label="Supplier Address"  type="text" value={detail_address} name="detail_address" id="detail_address" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField type="select" className="custom-select" label={reqLabel("Division")} value={f_division_id}   name="f_division_id" id="f_division_id" >
                                                            <option defaultValue>Select Division</option>
                                                            {cityList.map((city,index)=>
                                                                <option value={city.division_id}>{city.division_name}</option>
                                                            )}
                                                     </AvField>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField type="select" className="custom-select" label={reqLabel("District")} value={f_district_id}   name="f_district_id" id="f_district_id" >
                                                            <option defaultValue>Select District</option>
                                                            {districtList.map((district,index)=>
                                                                <option value={district.district_id}>{district.district_name}</option>
                                                            )}
                                                     </AvField>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField type="select" className="custom-select" label={reqLabel("Thana")} value={f_thana_id}   name="f_thana_id" id="f_thana_id" >
                                                            <option defaultValue>Select Thana</option>
                                                            {thanaList.map((thana,index)=>
                                                                <option value={thana.thana_id}>{thana.thana_name}</option>
                                                            )}
                                                     </AvField>
                                                 </div>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create supplier</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }}>
                                                 <div className="col-md-12">
                                                     <AvField type="select" className="custom-select" label={reqLabel("Supplier")}   name="f_product_supplier_id" id="f_product_supplier_id" required >
                                                            <option defaultValue>Select Supplier</option>
                                                            {supplierList.map((supplier,index)=>
                                                                <option value={supplier.supplier_id}>{supplier.supplier_name_en}</option>
                                                            )}
                                                     </AvField>
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField type="select" className="custom-select" label={reqLabel("Warehouse")}   name="f_product_warehouse_id" id="f_product_warehouse_id" required >
                                                            <option defaultValue>Select Warehouse</option>
                                                            {warehouseList.map((warehouse,index)=>
                                                                <option value={warehouse.warehouse_id}>{warehouse.warehouse_name_en}</option>
                                                            )}
                                                     </AvField>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Details Address"  type="text" name="detail_address" id="detail_address" />
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField type="select" className="custom-select" label={reqLabel("Division")}   name="f_division_id" id="f_division_id" onChange={(e) =>this.handleInputChange(e)} required>
                                                            <option defaultValue>Select Division</option>
                                                            {cityList.map((city,index)=>
                                                                <option value={city.division_id}>{city.division_name}</option>
                                                            )}
                                                     </AvField>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField type="select" className="custom-select" label={reqLabel("District")}   name="f_district_id" id="f_district_id" onChange={(e) =>this.handleInputChange(e)} required>
                                                            <option defaultValue>Select District</option>
                                                            {districtList.map((district,index)=>
                                                                <option value={district.district_id}>{district.district_name}</option>
                                                            )}
                                                     </AvField>
                                                 </div>

                                                 <div className="col-md-12">
                                                     <AvField type="select" className="custom-select" label={reqLabel("Thana")}   name="f_thana_id" id="f_thana_id" required>
                                                            <option defaultValue>Select Thana</option>
                                                            {thanaList.map((thana,index)=>
                                                                <option value={thana.thana_id}>{thana.thana_name}</option>
                                                            )}
                                                     </AvField>
                                                 </div>
                                            
                                             
                                                 
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Fuel;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createWarehouseSupplierMap,editWarehouseSupplierMap })(WarehouseSupplierMapCreate));
