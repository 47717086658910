import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PUMP,PUMP_SUCCESS,EDIT_PUMP,SHOW_PUMP } from './actionTypes';

import { pumpSuccess, pumpError,pumpShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createPump({ payload: { pump } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'petrolPump/create',pump);

             yield put(pumpSuccess(response));
          }
    } catch (error) {
        yield put(pumpError(error));
    }
}


function* editPump({ payload: { member } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'petrolPump/id/'+member.member_id, { 
                    member_name : member.member_name,
                    member_name_bn : member.member_name_bn,
                    relationship : member.relationship
                });
             yield put(pumpSuccess(response));
          }
          
          
    } catch (error) {
        yield put(pumpError(error));
    }
}


function* showPump({ payload: { pump_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'petrolPump/'+pump_id);
             yield put(pumpShow(response));
          }
          
          
    } catch (error) {
        yield put(pumpError(error));
    }
}

export function* watchPump() {
    yield takeEvery(EDIT_PUMP, editPump)
    yield takeEvery(CREATE_PUMP, createPump)
    yield takeEvery(SHOW_PUMP, showPump)
}

function* PumpSaga() {
    yield all([
        fork(watchPump),
    ]);
}

export default PumpSaga;