import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Role from "./auth/role/reducer";
import TransactionSucess from "./transaction/reducer";
import LoanType from "./loanType/reducer";
import Member from "./member/reducer";
import PetrolPump from "./petrolPump/reducer";
import Fuel from "./fuel/reducer";
import FuelPumpMap from "./fuelPumpMap/reducer";
import PersonalLoan from "./personalLoan/reducer";
import Brand from "./brand/reducer";
import Banner from "./banner/reducer";
import Product from "./product/reducer";
import Supplier from "./productSupplier/reducer";
import Warehouse from "./warehouse/reducer";
import WarehouseSupplierMap from "./warehouseSupplierMap/reducer";
import ProductStock from "./productStock/reducer";
import ProductCategory from "./category/reducer"
import Order from "./order/reducer"
import HomeFeatures from "./homefeatures/reducer"
import PrivacyPolicy from "./privacyPolicy/reducer"
import Courier from "./courier/reducer";
import CourierPricing from "./courier/reducer";
import Merchant from "./merchant/reducer";
import MerchantBranch from "./merchantBranch/reducer"
import Voucher from "./voucher/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  TransactionSucess,
  LoanType,
  Member,
  PersonalLoan,
  PetrolPump,
  Fuel,
  FuelPumpMap,
  Brand,
  Banner,
  Product,
  Supplier,
  Warehouse,
  WarehouseSupplierMap,
  ProductStock,
  Profile,
  Role,
  ProductCategory,
  Order,
  HomeFeatures,
  PrivacyPolicy,
  Courier,
  CourierPricing,
  Merchant,
  MerchantBranch,
  Voucher,
});

export default rootReducer;
