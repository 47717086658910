import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { createVoucher } from '../../store/voucher/actions';
import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
import { marchantList } from '../../store/merchantBranch/actions';
import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import { toast } from "react-toastify";

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class VoucherCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            supplier: {

            },
            status: 0,
            voucharImage: {},
            brand: {

            },
            voucher_original_price: 0,
            voucher_discount_amount: 0,
            autot_commission_amount: 0,
            voucher_buy_price: 0,


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchsupplierList();
        this.fetchCityList();
        this.fetchMarchantList();
    }

    fetchsupplierList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        supplierList(options).then((response) => {
            this.setState({
                supplierList: response.data,
            });
            this.state.supplierList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }


    fetchCityList = () => {
        cityList().then((response) => {
            this.setState({
                cityList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchDistrictList = (city_id) => {
        districtList(city_id).then((response) => {
            this.setState({
                districtList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchThanaList = (district_id) => {
        thanaList(district_id).then((response) => {
            this.setState({
                thanaList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchMarchantList = () => {
        marchantList().then((response) => {
            this.setState({
                marchantList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.createVoucher(values);
        setTimeout(this.props.history.push('/voucher'), 3000);
        //this.form && this.form.reset();
        // this.fetchsupplierList();
        // alert("Supplier Create Successfully");
        // window.location.reload();
    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchsupplierList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        console.log(e.target.name)
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        if (e.target.name == 'voucher_original_price') {
                this.setState({
                    voucher_original_price: e.target.value
                })
        }

        if (e.target.name == 'voucher_discount_percent') {
            if (e.target.value<= 100) {
                if(this.state.voucher_original_price!=""&& this.state.voucher_original_price != null)
                {
                    let discount_amount = this.state.voucher_original_price * (e.target.value / 100);
                    let voucher_buy_price = this.state.voucher_original_price - discount_amount;
                    this.setState({
                        voucher_discount_amount: discount_amount.toFixed(2),
                        voucher_buy_price: voucher_buy_price.toFixed(2)
                    })
                }else{
                    this.form && this.form.reset();
                    alert('Please Enter Original Price');
                }
               
            } else {
                this.form && this.form.reset();
               alert('Please Enter Value Range Between 0-100');
            }
        }
        if (e.target.name == 'autot_commission_percent') {
            if (e.target.value <= 100) {
                let autot_discount_amount = this.state.voucher_buy_price * (e.target.value / 100)
                this.setState({
                    autot_commission_amount: autot_discount_amount.toFixed(2)
                })
            }
            else{
                this.form && this.form.reset();
                alert('Please Enter Value Range Between 0-100'); 
            }
        }

        this.setState({ [e.target.name]: e.target.value }, () => {

            this.fetchsupplierList();
        });

    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }

    handleImageUpload = (e) => {
        var id = e.target.id;
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type };
          //  console.log("formData", formData);

            this.state.voucharImage[id]= formData;
          //  console.log("this.setState.voucharImage[0]",  this.state.voucharImage[id]);
        }
    }

    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        const { brand_id, brand_code, brand_name_bn, brand_name_en, f_category_info_id, brand_logo } = this.state.brand;
        if (this.state.hasOwnProperty('supplierList')) {
            supplierListMap = this.state.supplierList.data;
            paginator = {
                'currentPage': this.state.supplierList.current_page,
                'pagesCount': this.state.supplierList.last_page,
                'total': this.state.supplierList.total,
                'to': this.state.supplierList.to,
                'from': this.state.supplierList.from,
            }
        }
        const { supplier_id, supplier_code, supplier_name_bn, supplier_name_en, supplier_address, supplier_mobile, f_division_id, f_district_id, f_thana_id } = this.state.supplier;
        if (this.state.hasOwnProperty('cityList')) { cityList = this.state.cityList; }
        if (this.state.hasOwnProperty('districtList')) { districtList = this.state.districtList; }
        if (this.state.hasOwnProperty('thanaList')) { thanaList = this.state.thanaList; }
        if (this.state.hasOwnProperty('marchantList')) { marchantList = this.state.marchantList; }
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
       // console.log("this.props.success", this.props.success)
        if (this.props.success != '') {
            setTimeout(function () {
                window.location.reload();
            }, 1000);

        }

        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Voucher Create" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>


                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Voucher</CardTitle>

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Voucher Name')} id="name_en" name="name_en" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label="Voucher Name (Bangla)" type="text" name="name_bn" id="name_bn" />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField type="select" className="custom-select" label={reqLabel('Merchant Name')} name="f_marchant_id" id="f_marchant_id" onChange={(e) => this.handleInputChange(e)} required >
                                                        <option defaultValue>Select Merchant Name</option>
                                                        {marchantList.map((marchant, index) =>
                                                            <option value={marchant.id}>{marchant.name_en}</option>
                                                        )}
                                                    </AvField>

                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Voucher Original Price')} type="number" defaultValue={this.state.voucher_original_price} name="voucher_original_price" id="voucher_original_price" onChange={(e) => this.handleInputChange(e)} />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Voucher Discount Percent')} type="number" name="voucher_discount_percent" id="voucher_discount_percent" onChange={(e) => this.handleInputChange(e)} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel("Voucher Discount Amount")} value={this.state.voucher_discount_amount} type="number" name="voucher_discount_amount" id="voucher_discount_amount" required readOnly />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Voucher Buy Price')} value={this.state.voucher_buy_price} type="number" name="voucher_buy_price" id="voucher_buy_price" required readOnly />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel("Autot Commission Percent")} type="number" name="autot_commission_percent" id="autot_commission_percent" required onChange={(e) => this.handleInputChange(e)} />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel('Autot Commission Amount')} value={this.state.autot_commission_amount} type="number" name="autot_commission_amount" id="autot_commission_amount" readOnly required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel("Voucher Start Date")} type="date" name="voucher_start_date" id="voucher_start_date" required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" label={reqLabel("Voucher End Date")} type="date" name="voucher_end_date" id="voucher_end_date" required />
                                                </div>

                                                <div className="col-md-6">
                                                    {/* <img src={imageLink + 'brand/' + brand_logo} alt="" height="50" /> */}
                                                    <AvField type="hidden" value={this.state.voucharImage} name="voucharImage" id="voucharImage" />
                                                    <AvField label="Vouchar Image" type="file" className="form-control" name="voucharImage_0"  onChange={(e) => this.handleImageUpload(e)} id="voucharImage" />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <FormGroup>
                                                        <AvField name="voucher_details" label="Description" value={this.state.voucher_details} className="form-control" type="hidden" />
                                                        <CKEditor
                                                            editor={ClassicEditorDescription}
                                                            data={this.state.voucher_details}
                                                            onReady={editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                // console.log( 'Editor is ready to use!', editor );
                                                            }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                this.setState({
                                                                    voucher_details: data,
                                                                });
                                                            }}
                                                            onBlur={(event, editor) => {
                                                                // console.log( 'Blur.', editor );
                                                            }}
                                                            onFocus={(event, editor) => {
                                                                // console.log( 'Focus.', editor );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6">
                                                    <FormGroup>
                                                        <AvField name="vouchar_specification" label={reqLabel("Specification")} value={this.state.vouchar_specification} className="form-control" type="hidden" required />
                                                        <CKEditor
                                                            editor={ClassicEditorSpecification}
                                                            data={this.state.vouchar_specification}
                                                            onReady={editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                // console.log( 'Editor is ready to use!', editor );
                                                            }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                this.setState({
                                                                    vouchar_specification: data,
                                                                });
                                                            }}
                                                            onBlur={(event, editor) => {
                                                                // console.log( 'Blur.', editor );
                                                            }}
                                                            onFocus={(event, editor) => {
                                                                // console.log( 'Focus.', editor );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </Row>

                                            <Row>

                                                <div className="col-md-6">
                                                    <label className="mb-3">{this.state.status == 1 ? 'Inactive Voucher' : 'Active Voucher'}</label>
                                                    <br />
                                                    <div className="d-flex">
                                                        <div>
                                                            <Switch
                                                                name="status"
                                                                id="status"
                                                                onChange={this.handleChange}
                                                                checked={this.state.status}
                                                                className="react-switch"
                                                                id="normal-switch"
                                                            />
                                                        </div>
                                                        {/* <div className="mt-1 mx-2"><span>{this.state.status == 1 ? ' Is Active' : ' Is Inactive'}</span>
                                                        </div> */}
                                                    </div>
                                                    <AvField name="status" value={this.state.status} type="hidden" />
                                                </div>
                                            </Row>



                                            <div className=" mt-4">
                                                <Button type="submit" color="success">Submit</Button>
                                            </div>


                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.Voucher;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createVoucher })(VoucherCreate));