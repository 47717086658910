import { PRODUCT_STOCK_ERROR,PRODUCT_STOCK_SUCCESS,EDIT_PRODUCT_STOCK } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const ProductStock = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_PRODUCT_STOCK:
            state = { ...state }
            break;
        case PRODUCT_STOCK_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case PRODUCT_STOCK_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ProductStock;