import { PERSONAL_LOAN_ERROR,PERSONAL_LOAN_SUCCESS,EDIT_PERSONAL_LOAN,SHOW_PERSONAL_LOAN,CREATE_PERSONAL_LOAN } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createPersonalLoan = (personalLoan) => {
    return {
        type: CREATE_PERSONAL_LOAN,
        payload: { personalLoan }
    }
}

// export const showPersonalLoan = (personalLoan_id) => {
//     return {
//         type: SHOW_PERSONAL_LOAN,
//         payload: { personalLoan_id }
//     }
// }

export const editPersonalLoan = (personalLoan) => {
    return {
        type: EDIT_PERSONAL_LOAN,
        payload: { personalLoan }
    }
}



export const personalLoanSuccess = (success) => {
    return {
        type: PERSONAL_LOAN_SUCCESS,
        payload: success
    }
}


export function personalLoanShow(personalLoan_id){
    return getApi().get('users/id/'+personalLoan_id)
}

export function personalLoanList(params){
    return getApi().get('personalLoan',{
        params: params
    })
}

export const personalLoanError = (error) => {
    return {
        type: PERSONAL_LOAN_ERROR,
        payload: error
    }
}
