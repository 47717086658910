import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_BRAND,BRAND_SUCCESS,EDIT_BRAND,SHOW_BRAND, EDIT_MERCHANT, CREATE_MERCHANT, SHOW_MERCHANT, DELETE_MERCHANT ,EDIT_LOGO } from './actionTypes';

import { merchantError, merchantSuccess } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData, deleteJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createMerchant({ payload: { merchant } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'marchant/create',merchant); 
             
             if(response.success == false){
                console.log('creat saja',response);
                yield put(merchantError(response.data.message));
            }else if(response.success == true){
                yield put(merchantSuccess(response.data.message));
            } 
          }
    } catch (error) {
        console.log(error);
        yield put(merchantError(error));
    }
}

// function* daleteMerchant({ payload: { merchant_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  console.log(merchant_id)
//              const response = yield call(deleteJwtData, 'marchant/'+merchant_id,merchant_id);
//              if(response.success == false){
//                  alert(response.message['message']);
//                 yield put(merchantError(response.message.message));
//             }else if(response.success == true){
//                 yield put(merchantSuccess(response.data.message));
//             } 
//           }
//     } catch (error) {
//         yield put(merchantError("hello"));
//     }
// }


function* editMerchant({ payload: { merchant } }) {
    try {
        
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'marchant/'+merchant.id,merchant);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(merchantError(response.message.message));
            }else if(response.success == true){
                yield put(merchantSuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(merchantError(error));
    }
}


// function* showMerchant({ payload: { user_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtData, 'users/id/'+user_id);
//              yield put(brandShow(response));
//           }
          
          
//     } catch (error) {
//         yield put(brandError(error));
//     }
// }

function* editBanner({ payload: { banner } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          { 
              // console.log("saga", banner)
             const response = yield call(editJwtData, 'marchant/logo/'+banner.id, banner);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(merchantError(response.message.message));
            }else if(response.success == true){
                yield put(merchantSuccess(response.data.message));
            } 
          }
          
          
    } catch (error) {
        yield put(merchantError(error));
    }
}

export function* watchMerchant() {
    yield takeEvery(EDIT_MERCHANT, editMerchant)
    yield takeEvery(CREATE_MERCHANT, createMerchant)
    yield takeEvery(EDIT_LOGO, editBanner)
    // yield takeEvery(SHOW_MERCHANT, showMerchant)
    //  yield takeEvery(DELETE_MERCHANT, daleteMerchant)
}

function* MerchantSaga() {
    yield all([
        fork(watchMerchant),
    ]);
}

export default MerchantSaga;