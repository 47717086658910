import { ROLE_ERROR,ROLE_SUCCESS,EDIT_ROLE,CREATE_ROLE } from './actionTypes';
import {getApi} from "../../../services/httpService";

export const createRole = (role) => {
    return {
        type: CREATE_ROLE,
        payload: { role }
    }
}

export const editRole = (role) => {
    return {
        type: EDIT_ROLE,
        payload: { role }
    }
}

export function getPermissionGroupList(params) {
    return getApi().get('user/permission/group/list', {
        params: params
    })
}

export function getRoleDetails(user_id) {
    return getApi().get('user/role/' + user_id)
}

export function getRoleList(params) {
    return getApi().get('user/role/list', {
        params: params
    })
}
export const roleSuccess = (msg) => {
    return {
        type: ROLE_SUCCESS,
        payload: msg
    }
}


export const roleError = (error) => {
    return {
        type: ROLE_ERROR,
        payload: error
    }
}

export function createOrUpdateRole(role_id, data) {
    if (role_id) {
        return getApi().put('user/role/'+role_id, data)
    }

    return getApi().post('user/role/create', data)
}