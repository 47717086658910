import { PRODUCT_ERROR,PRODUCT_SUCCESS,EDIT_PRODUCT } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const Product = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_PRODUCT:
            state = { ...state }
            break;
        case PRODUCT_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case PRODUCT_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Product;