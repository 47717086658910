import { BANNER_ERROR,BANNER_SUCCESS,EDIT_BANNER,SHOW_BANNER,CREATE_BANNER } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createBanner = (banner) => {
    return {
        type: CREATE_BANNER,
        payload: { banner }
    }
}

export const showBanner = (id) => {
    return {
        type: SHOW_BANNER,
        payload: { id }
    }
}

export const editBanner = (banner) => {
    return {
        type: EDIT_BANNER,
        payload: { banner }
    }
}



export const bannerSuccess = (msg) => {
    return {
        type: BANNER_SUCCESS,
        payload: msg
    }
}


export function bannerShow(id){
    return getApi().get('users/id/'+id)
}


export function bannerList(params){
    return getApi().get('banner/list',{
        params: params
    })
}

export const bannerError = (error) => {
    return {
        type: BANNER_ERROR,
        payload: error
    }
}

export function sliderUpload(params,id){
    console.log("image_id",id)
    if(id){
        return getApi().put('banner/slider/edit/'+id,params)
    }else{
        return getApi().post('banner/slider/upload',params);
    }
}

export function categoryList(params){
    return getApi().get('banners/category/list',{
        params: params
    })
}
