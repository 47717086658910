import { ORDER_ERROR,ORDER_SUCCESS,EDIT_ORDER,SHOW_ORDER,CREATE_ORDER,EDIT_ORDER_DELETE,CREATE_ADD_PRODUCT_ORDER } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const order = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_ORDER:
            state = { ...state }
            break;
        case ORDER_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case ORDER_ERROR:
            state = { ...state, error: action.payload };
            break;
        case CREATE_ADD_PRODUCT_ORDER:
                state = { ...state, error: action.payload };
                break;
        case EDIT_ORDER_DELETE:
                state = { ...state, error: action.payload };
                break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default order;