import React, { Component } from 'react';
import { Container,Row,Col,Card,CardBody,Button,Input,FormText,FormGroup,Label,Form,CardHeader,CustomInput,CardFooter} from "reactstrap";

import { Link } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
import {getUserDetails, createOrUpdateUser, getRoleList} from '../../store/auth/profile/actions';

class CreateUserProfile extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          user: {
            roles:[],
            status: 1
          },
          all_roles: []
        };
      }
    
      componentDidMount() {
        if (this.props.match.params.id) {
          this.fetchUserDetails(this.props.match.params.id);
        }
        this.fetchUserRoles();
      }
    
      fetchUserDetails = (user_id) => {
        getUserDetails(user_id)
          .then((response) => {
    
            let user = response.data
            let roles = user.roles.map((role) => role.id);
            user["roles"] = roles;
            this.setState({
              user: user
            });
          })
          .catch((err) => {
            console.log(err);
          })
      };
    
      fetchUserRoles = () => {
    
        getRoleList()
            .then((response) => {
              this.setState({
                all_roles: response.data.data
              })
            })
            .catch((err) => {
              console.log(err);
            })
      }
    
      updateUserObject = (name, value, callback = null) => {
        let userCopy= Object.assign({}, this.state.user);
        userCopy[name] = value;
    
        this.setState({
          user: userCopy
        }, () => {
          if (typeof callback == "function")
            callback()
        });
      }
    
      switchInputChange = (event) => {
        event.preventDefault();
    
        let name = event.target.name;
        let value = event.target.checked;
        this.updateUserObject(name, value);
    
      }
    
      handleInputChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
    
        this.updateUserObject(name, value);
      }
    
      handleCounterChange = (item) => {
        this.updateUserObject('counter', item, () => {
          this.updateCoachObject('counter_id', item !== null ? item.value : 0);
        });
      };
    
    
      onHandleSubmit = (event) => {
        event.preventDefault();
    
        const {id, name, mobile, email, password, password_confirmation,f_employee_id,f_wings_id, counter_id, roles,image, status} = this.state.user;
    
        if (password !== password_confirmation) {
          alert("Passwords don't match");
        } else {
          
        const data = {
          name: name,
          mobile: mobile,
          password: password,
          email: email,
          image: image,
          password_confirmation: password_confirmation,
          counter_id:counter_id,
          status:status,
          roles:roles,
    
        };
      
    
    
        createOrUpdateUser(id, data)
            .then((response) => {
              if (response.data.status === true) {
                this.props.history.push({
                  pathname: '/users',
                  state: {message: response.data.message}
                });
              }
            })
            .catch((err) => {
              this.props.handleErrorResponse(err);
    
            });
          }
      };
       
      handleChange = (e) => {
        let roles= this.state.user.roles;
    
        if (e.target.checked === true) {
          roles.push(parseInt(e.target.dataset.id));
        } else {
          roles =  roles.filter(function(role) {
            return role !== parseInt(e.target.dataset.id);
          });
        }
    
        let userCopy= Object.assign({}, this.state.user);
        userCopy["roles"] = roles;
        this.setState({
          user: userCopy
        });
    
      }
    
      getRoleInput = (role) => {
    
        const {user} = this.state;
    
        let check_role = user.roles.filter((role_id) => {
          return role.id === role_id
        })
    
        let status = false
        if (check_role.length) {
          status = true
        }
    
        return (
            <CustomInput className="m-2" type="switch" id={role.id} name={role.id} data-id={role.id}
                         label={role.name.toUpperCase()}
                         onChange={(e) => this.handleChange(e)} checked={status}/>
        )
      }
    
      render() {
      const {user, all_roles} = this.state;
      let {trimmedDataURL} = this.state;
    
        let selectedCounter = user.counter ? {label: user.counter.name, value: user.counter.id} : null

    
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Row>
              <Col xs="12" sm="12">
                <Form onSubmit={this.onHandleSubmit}>
                  <Card>
                    <CardHeader>
                      <strong>New User</strong>
                      <small> Form</small>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs="12" sm="12" md={{size: 6, offset: 1}}>
                          <Card>
                            <CardHeader>
                              <strong>User Information</strong>
                              <small> Form</small>
                            </CardHeader>
                            <CardBody>
                              <FormGroup row>
                                <Col sm="2">
                                  <Label htmlFor="name"> Name</Label>
                                </Col>
                                <Col sm="5" md="5">
                                <Input type="text" id="name" name="name"
                                       value={user.name} onChange={this.handleInputChange}
                                       placeholder="Enter  name..."/>
                                <FormText className="help-block">Please enter User
                                  Name</FormText>
                                </Col>
                              </FormGroup>
                              <FormGroup>
                                <Label htmlFor="email">Email</Label>
                                <Input type="email" id="email" name="email"
                                       value={user.email} onChange={this.handleInputChange}
                                       placeholder="Enter email..."/>
                                <FormText className="help-block">Please enter user
                                  email</FormText>
                              </FormGroup>
                              <FormGroup>
                                <Label htmlFor="mobile">Mobile</Label>
                                <Input type="text" id="mobile" name="mobile"
                                       value={user.mobile} onChange={this.handleInputChange}
                                       placeholder="Enter mobile no..."/>
                                <FormText className="help-block">Please enter user
                                  mobile</FormText>
                              </FormGroup>
                              <FormGroup>
                                <Label htmlFor="password">Password</Label>
                                <Input type="password" id="password" name="password"
                                       onChange={this.handleInputChange}
                                       placeholder="Enter password..."/>
                                <FormText className="help-block">Please enter your
                                  password</FormText>
                              </FormGroup>
    
                              <FormGroup>
                                <Label htmlFor="password_confirmation">Confirm password</Label>
                                <Input type="password" id="password_confirmation"
                                       name="password_confirmation" onChange={this.handleInputChange}
                                       placeholder="Enter password again..."/>
                                <FormText className="help-block">Please enter user password again</FormText>
                              </FormGroup>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={{size: 4}}>
                          <Card>
                            <CardHeader>
                              <strong>User Role Information</strong>
                              <small> Form</small>
                            </CardHeader>
                            <CardBody>
                              <FormGroup>
                                <Label for="exampleCheckbox">Attach User Role</Label>
                                <div>
                                  {all_roles.map((role) => {
                                    return this.getRoleInput(role);
                                  })}
                                </div>
                              </FormGroup>
    
                            </CardBody>
                          </Card>
                      </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Button type="submit" size="sm" color="primary"
                              className="mr-2 pull-right"><i
                        className="fa fa-dot-circle-o"/> Submit</Button>
                      <Link to="/users">
                        <Button type="reset" size="sm" color="danger"
                                className="mr-2 pull-right"><i
                          className="fa fa-ban"/> Cancel</Button>
                      </Link>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
              </Row>
                    </Container>
                </div>
            </React.Fragment>
            
        );
      }
}
            
const mapStatetoProps = state => {
    const { msg,error,success } = state.Profile;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps,{getUserDetails, createOrUpdateUser, getRoleList})(CreateUserProfile));

