export const CREATE_ORDER = 'CREATE_ORDER';
export const EDIT_ORDER = 'EDIT_ORDER';
export const SHOW_ORDER = 'SHOW_ORDER';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_ERROR = 'ORDER_ERROR';
export const CREATE_ORDER_STATUS = 'CREATE_ORDER_STATUS';
export const CONFIRM_ORDER = 'CONFIRM_ORDER';
export const EDIT_ORDER_DELETE = 'EDIT_ORDER_DELETE';
export const CREATE_ADD_PRODUCT_ORDER = 'CREATE_ADD_PRODUCT_ORDER';


