import { ORDER_ERROR,ORDER_SUCCESS,EDIT_ORDER,SHOW_ORDER,CONFIRM_ORDER,CREATE_ORDER,CREATE_ORDER_STATUS,EDIT_ORDER_DELETE,CREATE_ADD_PRODUCT_ORDER } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createOrder = (order) => {
    return {
        type: CREATE_ORDER,
        payload: { order }
    }
}

export const showOrder = (order_id) => {
    return {
        type: SHOW_ORDER,
        payload: { order_id }
    }
}

export const editOrder = (order) => {
    return {
        type: EDIT_ORDER,
        payload: { order }
    }
}

export const deleteOrder = (order) => {
    return {
        type: EDIT_ORDER_DELETE,
        payload: { order }
    }
}

export const createAddProductOrder = (addProduct) => {
    return {
        type: CREATE_ADD_PRODUCT_ORDER,
        payload: { addProduct }
    }
}
export const createOrderStatus = (orderStatus) => {
    return {
        type: CREATE_ORDER_STATUS,
        payload: { orderStatus }
    }
}

export const confirmOrder = (order_id) => {
    return {
        type: CONFIRM_ORDER,
        payload: { order_id }
    }
}


export const orderSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: ORDER_SUCCESS,
        payload: msg
    }
}


export function orderShow(order_id){
    return getApi().get('order/details/'+order_id)
}

export function orderConfirm(order_id){
    return getApi().get('order/confirm/'+order_id)
}

export function statusList(params){
    return getApi().get('order/status/list',{
        params: params
    })
}

export function orderList(params){
    return getApi().get('order/product/list',{
        params: params
    })
}

export function orderTreeList(params){
    return getApi().get('order/list/tree',{
        params: params
    })
}

export const orderError = (error) => {
    return {
        type: ORDER_ERROR,
        payload: error
    }
}

export function intToEnglish(number){

    var NS = [
        {value: 10000000, str: "Crore"},
        {value: 100000, str: "Lac"},
        {value: 1000, str: "Thousand"},
        {value: 100, str: "Hundred"},
        {value: 90, str: "Ninety"},
        {value: 80, str: "Eighty"},
        {value: 70, str: "Seventy"},
        {value: 60, str: "Sixty"},
        {value: 50, str: "Fifty"},
        {value: 40, str: "Forty"},
        {value: 30, str: "Thirty"},
        {value: 20, str: "Twenty"},
        {value: 19, str: "Nineteen"},
        {value: 18, str: "Eighteen"},
        {value: 17, str: "Seventeen"},
        {value: 16, str: "Sixteen"},
        {value: 15, str: "Fifteen"},
        {value: 14, str: "Fourteen"},
        {value: 13, str: "Thirteen"},
        {value: 12, str: "Twelve"},
        {value: 11, str: "Eleven"},
        {value: 10, str: "Ten"},
        {value: 9, str: "Nine"},
        {value: 8, str: "Eight"},
        {value: 7, str: "Seven"},
        {value: 6, str: "Six"},
        {value: 5, str: "Five"},
        {value: 4, str: "Four"},
        {value: 3, str: "Three"},
        {value: 2, str: "Two"},
        {value: 1, str: "One"}
      ];

      var result = '';
      for (var n of NS) {
        if(number>=n.value){
          if(number<=90){
            result += n.str;
            number -= n.value;
            if(number>0) result += ' ';
          }else{
            var t =  Math.floor(number / n.value);
            var d = number % n.value;
            if(d>0){
              return intToEnglish(t) + ' ' + n.str +' ' + intToEnglish(d);
            }else{
              return intToEnglish(t) + ' ' + n.str;
            }

          }
        }
      }
      return result;
    }