import React, { Component } from "react";
import { CardImg,Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { createCourierPricing,editCourierPricing,courierPricingList, courierList} from '../../store/courier/actions';
import { cityList, districtList } from '../../store/petrolPump/actions';

const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }

class CourierPricingCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                courierPricing: {
                    
                },
                displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
        this.fetchCityList();
        this.fetchcourierPricingList();
        this.fetchCourierList();
    }

    fetchcourierPricingList = () => {
        this.setState({
            displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        });
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        courierPricingList(options).then((response) => {
            this.setState({
                courierPricingList :response.data, 
            });
            this.state.courierPricingList = response.data;
            this.setState({
                displayHide: { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            });
        })
        .catch((err) => {
            console.log(err);
            this.setState({
                displayHide: { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            });
        })
    }

    //modal
    tog_scroll = (courierPricing) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.courierPricing = courierPricing;
        this.fetchDistrictList(courierPricing.f_division_id);
        this.fetchCourierList(courierPricing.f_courier_id);
        this.removeBodyCss();
      }

    removeBodyCss() {
        document.body.classList.add("no_padding");
      }

    fetchCityList = () => {
        cityList().then((response) => {
            this.setState({
                cityList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchDistrictList = (city_id) => {
        districtList(city_id).then((response) => {
            this.setState({
                districtList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchCourierList = () => {
          courierList().then((response) => {
            this.setState({
                courierList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

      // handleValidSubmit
        handleValidSubmit(e, values) {
            this.props.createCourierPricing(values);
            this.fetchcourierPricingList();
            this.form && this.form.reset();
            // window.location.reload(); 
        }
       
       handleEditSubmit(event, values) {  
            this.props.editCourierPricing(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
            this.fetchcourierPricingList();
            this.form && this.form.reset();
            // window.location.reload();
       }

       handleInputChange = (e) => {
           // console.log("handleValidSubmit", e.target.name)
            if (e.target.name == 'f_division_id') {
                this.fetchDistrictList(e.target.value);
            }
            this.setState({[e.target.name]: e.target.value},() => {
            this.fetchcourierPricingList();
        });
       }

       handlePageClick = (e, index) => {
        this.setState({
            displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        });
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchcourierPricingList();
                this.setState({
                    displayHide: { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
                });
            });
        }

    render() {
        let courierPricingListMap = [];
        let cityList = [];
        let districtList = [];
        let courierList = [];

        let paginator;
        if (this.state.hasOwnProperty('courierPricingList')) {
            courierPricingListMap = this.state.courierPricingList.data;
            
            paginator = {
                'currentPage':this.state.courierPricingList.current_page,
                'pagesCount':this.state.courierPricingList.last_page,
                'total':this.state.courierPricingList.total,
                'to':this.state.courierPricingList.to,
                'from':this.state.courierPricingList.from,
            }
        }
        const {f_courier_id,area,id,base_price_upto_one_kg,additional_price_per_kg,cod_percent,f_division_id,f_district_id} = this.state.courierPricing;
        if (this.state.hasOwnProperty('cityList')) { cityList = this.state.cityList; }
        if (this.state.hasOwnProperty('districtList')) { districtList = this.state.districtList; }
        if (this.state.hasOwnProperty('courierList')) { courierList = this.state.courierList; }
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
          console.log("this.props.success",this.props.success)
          if(this.props.success != ''){
            setTimeout(function() {
                window.location.reload();
            }, 1000);
             
          }
        return (
            <React.Fragment>
                <div style={this.state.displayHide}>
                    <div class="spinner-border text-primary" style={{ margin: '25% 50%' }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="courier" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="8" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Courier pricing List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Courier Name</th>
                                                        <th>Division</th>
                                                        <th>District</th>
                                                        <th>Area</th>
                                                        <th>Base Price Upto 1 KG</th>
                                                        <th>Additional Price Per KG</th>
                                                        <th>COD Percent</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {courierPricingListMap.map((courierPricing, index) =>
                                                    <tr key={courierPricing.id .toString()}>
                                                        <td scope="row">{index+1}</td>

                                                        <td>{courierPricing.courier.name}</td>
                                                        <td>{courierPricing.division.division_name}</td>
                                                        <td>{courierPricing.district.district_name}</td>

                                                        <td>{courierPricing.area}</td>
                                                        <td>{courierPricing.base_price_upto_one_kg}</td>
                                                        <td>{courierPricing.additional_price_per_kg}</td>
                                                        <td>{courierPricing.cod_percent}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(courierPricing) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                        </td> 
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{area}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }} ref={c => (this.form = c)}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Courier Pricing Information Updated Successfully!
                                           </Alert>
                                           ) : null}

                                            <div className="form-group">
                                                    <AvField type="select" className="custom-select" label={reqLabel("Courier")} value={f_courier_id} name="Courier_id" id="Courier_id" onChange={(e) => this.handleInputChange(e)} required>
                                                        <option defaultValue>Select Courier </option>
                                                        {courierList.map((courier, index) =>
                                                                <option value={courier.id}>{courier.name}</option>
                                                        )}
                                                    </AvField>
                                            </div>

                                            <div className="form-group">
                                                    <AvField type="select" className="custom-select" label={reqLabel("Division")} value={f_division_id} name="f_division_id" id="f_division_id" onChange={(e) => this.handleInputChange(e)} required>
                                                        <option defaultValue>Select Division</option>
                                                        {cityList.map((city, index) =>
                                                            <option value={city.division_id}>{city.division_name}</option>
                                                        )}
                                                    </AvField>
                                            </div>

                                            <div className="form-group">
                                                    <AvField type="select" className="custom-select" label={reqLabel("District")} value={f_district_id} name="f_district_id" id="f_district_id" required>
                                                        <option defaultValue>Select District</option>
                                                        {districtList.map((district, index) =>
                                                            <option value={district.district_id}>{district.district_name}</option>
                                                        )}
                                                    </AvField>
                                            </div>

                                           <div className="form-group">
                                                <AvField className="form-control" type="text" label='Area' name="area" value={area}/>
                                                <AvField name="id" id="id" value={id}  type="hidden"  />
                                           </div>
                                           <div className="form-group">
                                                <AvField className="form-control" type="text" label='Base Price Upto 1 KG' name="base_price_upto_one_kg" value={base_price_upto_one_kg}/> 
                                           </div>
                                           <div className="form-group">
                                                <AvField className="form-control" type="text" label='Additional Price Per KG' name="additional_price_per_kg" value={additional_price_per_kg}/> 
                                           </div>
                                           <div className="form-group">
                                                <AvField className="form-control" type="text" label='COD percent' name="cod_percent" value={cod_percent}/> 
                                           </div>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="4" md="2">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Courier Pricing</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                               
                                                <div className="col-md-12">
                                                    <AvField type="select" className="custom-select" label={reqLabel('Courier')} name="Courier_id" id="Courier_id" onChange={(e) => this.handleInputChange(e)} required >
                                                        <option defaultValue>Select Courier</option>
                                                            {courierList.map((courier, index) =>
                                                                <option value={courier.id}>{courier.name}</option>
                                                        )}
                                                    </AvField>
                                                </div>
                                                <div className="col-md-12">
                                                    <AvField type="select" className="custom-select" label={reqLabel('Division')} name="f_division_id" id="f_division_id" onChange={(e) => this.handleInputChange(e)} required >
                                                        <option defaultValue>Select Division</option>
                                                            {cityList.map((city, index) =>
                                                                <option value={city.division_id}>{city.division_name}</option>
                                                        )}
                                                    </AvField>
                                                </div>
                                                <div className="col-md-12">
                                                    <AvField type="select" className="custom-select" label={reqLabel('District')} name="f_zilla_id" id="f_district_id" onChange={(e) => this.handleInputChange(e)} required >
                                                        <option defaultValue>Select District</option>
                                                            {districtList.map((district, index) =>
                                                                <option value={district.district_id}>{district.district_name}</option>
                                                            )}
                                                    </AvField>
                                                </div>
                                                 <div className="col-md-12">
                                                    <AvField className="form-control" type="text" label='Area' name="area" id="area" required/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label='Base Price Upto 1KG' id="base_price_upto_one_kg" name="base_price_upto_one_kg"/>
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label='Additional Price Per KG' id="additional_price_per_kg" name="additional_price_per_kg" />
                                                 </div>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label='COD percent' id="cod_percent" name="cod_percent"/>
                                                 </div>
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.CourierPricing;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createCourierPricing,editCourierPricing,courierPricingList })(CourierPricingCreate));
