import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_WAREHOUSE,WAREHOUSE_SUCCESS,EDIT_WAREHOUSE,SHOW_WAREHOUSE } from './actionTypes';

import { warehouseSuccess, warehouseError,warehouseShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createWarehouse({ payload: { warehouse } }) {
console.log('warehouse',warehouse);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'warehouse/create',warehouse);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(warehouseError(response.message.message));
            }else if(response.success == true){
                yield put(warehouseSuccess(response.data.message));
            }
          }
    } catch (error) {
        yield put(warehouseError(error));
    }
}


function* editWarehouse({ payload: { warehouse } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'warehouse/'+warehouse.warehouse_id, warehouse);
             yield put(warehouseSuccess(response));
          }
          
          
    } catch (error) {
        yield put(warehouseError(error));
    }
}


function* showwarehouse({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);
             yield put(warehouseShow(response));
          }
          
          
    } catch (error) {
        yield put(warehouseError(error));
    }
}

export function* watchWarehouse() {
    yield takeEvery(EDIT_WAREHOUSE, editWarehouse)
    yield takeEvery(CREATE_WAREHOUSE, createWarehouse)
    yield takeEvery(SHOW_WAREHOUSE, showwarehouse)
}

function* WarehouseSaga() {
    yield all([
        fork(watchWarehouse),
    ]);
}

export default WarehouseSaga;