import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,CardFooter,Modal,ModalHeader,ModalBody,Input,TabContent, TabPane, NavItem, NavLink, Label , Form, FormGroup, Progress } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';

import classnames from 'classnames';
import { Link } from "react-router-dom";
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./datatables.scss";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createTransaction,expenseTransactionList,incomeTransactionList,editTransaction,expenseCategoryList,incomeCategoryList,loanTypeList,memberList} from '../../../store/transaction/actions';
import {createPersonalLoan,personalLoanList,editPersonalLoan} from '../../../store/personalLoan/actions';

class TransactionCreate extends Component {
    constructor(props) {
        super(props);
        function format(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1;
            var y = date.getFullYear();
            return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
        }
        this.state = {
                activeTab: 1,
                expensePage: 1,
                incomePage: 1,
                loanPage: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                modal_loan: false,
                tran_date: format(new Date()),
                transaction: {
                    
                },
                personalLoan: {

                }
            };
        this.toggleTab.bind(this);

    }

    componentDidMount()
    {
            this.fetchExpenseTransactionList();     
            this.fetchIncomeTransactionList();     
            this.fetchExpenseCategoryList();     
            this.fetchIncomeCategoryList();     
            this.fetchPersonalLoanList();     
            this.fetchLoanTypeList();     
            this.fetchMemberList();     
    }

    handleExpensePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            expensePage: index
        },() => {
            this.fetchExpenseTransactionList();
        });
    }

    handleIncomePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            incomePage: index
        },() => {
            this.fetchIncomeTransactionList();
        });
    }

    handleLoanPageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            loanPage: index
        },() => {
            this.fetchPersonalLoanList();     
        });
    }

    handleExpenseInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchExpenseTransactionList();
        });
    }

    
    handleIncomeInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchIncomeTransactionList();
        });
    }

    handleLoanInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchPersonalLoanList();     
        });
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            if(tab >= 1 && tab <=4 ){
                this.setState({
                    activeTab: tab
                });
            }
        }
    }

    fetchExpenseTransactionList = () => {
        let options = {
            page: this.state.expensePage,
            per_page: this.state.per_page,
            search: this.state.search
        }
        expenseTransactionList(options).then((response) => {
            this.setState({
                expenseTransactionList :response, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchIncomeTransactionList = () => {
        let options = {
            page: this.state.incomePage,
            per_page: this.state.per_page,
            search: this.state.search
        }
        incomeTransactionList(options).then((response) => {
            this.setState({
                incomeTransactionList : response, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchPersonalLoanList = () => {
        let options = {
            page: this.state.loanPage,
            per_page: this.state.per_page,
            search: this.state.search
        }
        personalLoanList(options).then((response) => {
            this.setState({
                personalLoanList : response, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    

    fetchExpenseCategoryList = () => {
        expenseCategoryList().then((response) => {
            this.setState({
                expenseCategoryList :response, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchIncomeCategoryList = () => {
        incomeCategoryList().then((response) => {
            this.setState({
                incomeCategoryList :response, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchMemberList = () => {
        memberList().then((response) => {
            this.setState({
                memberList :response, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchLoanTypeList = () => {
        loanTypeList().then((response) => {
            this.setState({
                loanTypeList :response, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (transaction) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        function format(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1;
            var y = date.getFullYear();
            return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
        }
        this.state.transaction = transaction;
        this.state.transaction.tran_date = format(new Date(transaction.tran_date));
        this.removeBodyCss();
      }

      
      tog_loan = (personalLoan) => {
        this.setState(prevState => ({
          modal_loan: !prevState.modal_loan,
        }));
        function format(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1;
            var y = date.getFullYear();
            return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
        }
        this.state.personalLoan = personalLoan;
        this.state.personalLoan.loan_date = format(new Date(personalLoan.loan_date));
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
      handleValidExpenseSubmit(event, values) {          
        this.props.createTransaction(values);
        this.fetchExpenseTransactionList();     
       }

       handleValidIncomeSubmit(event, values) {          
        this.props.createTransaction(values);
        this.fetchIncomeTransactionList();     
       }

       handleValidLoanSubmit(event, values) {          
        this.props.createPersonalLoan(values);
        this.fetchPersonalLoanList();     
       }


       
       handleEditExpenseSubmit(event, values) {  
            this.props.editTransaction(values);
            this.fetchExpenseTransactionList();    
            this.setState({ modal_scroll: false }) 
       }

       handleEditIncomeSubmit(event, values) {  
        this.props.editTransaction(values);
        this.fetchIncomeTransactionList();    
        this.setState({ modal_scroll: false }) 
       }

       handleEditLoanSubmit(event, values) {  
        this.props.editPersonalLoan(values);
        this.fetchPersonalLoanList();    
        this.setState({ modal_loan: false }) 
       }

    render() {
        let expenseTransactionList = [];
        let incomeTransactionList = [];
        let memberList = [];
        let expenseCategoryList = [];
        let incomeCategoryList = [];
        let loanTypeList = [];
        let personalLoanList = [];
        let expensePaginator;
        let incomePaginator;
        let loanPaginator;
        if (this.state.hasOwnProperty('expenseTransactionList')) {
            expenseTransactionList = this.state.expenseTransactionList.data;
            expensePaginator = {
                'currentPage':this.state.expenseTransactionList.currentPage,
                'pagesCount':this.state.expenseTransactionList.pagesCount,
                'total':this.state.expenseTransactionList.total,
                'to':this.state.expenseTransactionList.to,
                'from':this.state.expenseTransactionList.from,
            }
        }
        if (this.state.hasOwnProperty('incomeTransactionList')) {
            incomeTransactionList = this.state.incomeTransactionList.data;
            incomePaginator = {
                'currentPage':this.state.incomeTransactionList.currentPage,
                'pagesCount':this.state.incomeTransactionList.pagesCount,
                'total':this.state.incomeTransactionList.total,
                'to':this.state.incomeTransactionList.to,
                'from':this.state.incomeTransactionList.from,
            }

        }
        if (this.state.hasOwnProperty('personalLoanList')) {
            personalLoanList = this.state.personalLoanList.data;
            loanPaginator = {
                'currentPage':this.state.personalLoanList.currentPage,
                'pagesCount':this.state.personalLoanList.pagesCount,
                'total':this.state.personalLoanList.total,
                'to':this.state.personalLoanList.to,
                'from':this.state.personalLoanList.from,
            }

        }
        if (this.state.hasOwnProperty('memberList')) { memberList = this.state.memberList;}
        if (this.state.hasOwnProperty('expenseCategoryList')) { expenseCategoryList = this.state.expenseCategoryList;}
        if (this.state.hasOwnProperty('incomeCategoryList')) { incomeCategoryList = this.state.incomeCategoryList;}
        if (this.state.hasOwnProperty('loanTypeList')) { loanTypeList = this.state.loanTypeList;}
        const {trn_id,tran_amount,tran_date,f_tran_type_id,f_member_id,f_deposit_type_id,remarks,tran_code} = this.state.transaction;
        const {loan_id,loan_amount,loan_date,f_loan_type_id,loan_code} = this.state.personalLoan;
        let loan_member,loan_deposit_type,loan_remarks;
        if (this.state.personalLoan.hasOwnProperty('f_member_id')) { loan_member = this.state.personalLoan.f_member_id;}
        if (this.state.personalLoan.hasOwnProperty('f_deposit_type_id')) { loan_deposit_type = this.state.personalLoan.f_deposit_type_id;}
        if (this.state.personalLoan.hasOwnProperty('remarks')) { loan_remarks = this.state.personalLoan.remarks;}

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }

          
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Transaction" breadcrumbItem="Expense & Income" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        {this.props.loanError && this.props.loanError ? <Alert color="danger">{this.props.loanError}</Alert> : null}
                        {this.props.loanSuccess && this.props.loanSuccess ? <Alert color="success">{this.props.loanSuccess}</Alert> : null}
                        <Card>
                                    <CardBody>

                                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTab === 1 })} onClick={() => { this.toggleTab(1); }} >
                                                                        <span className="step-number mr-2">01</span>
                                                                        Expense Transaction
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTab === 2 })} onClick={() => { this.toggleTab(2); }} >
                                                                        <span className="step-number mr-2">02</span>
                                                                        <span>Income Transaction</span>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={classnames({ active: this.state.activeTab === 3 })} onClick={() => { this.toggleTab(3); }} >
                                                                        <span className="step-number mr-2">03</span>
                                                                        Loan Transaction
                                                                    </NavLink>
                                                                </NavItem>
                                            </ul>
                                            <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">
                                                <TabPane tabId={1}>
                                                <Row>
                                                    <Col lg="8" md="8">
                                                
                                                            <Card>
                                                                <CardBody>
                                                                    <CardTitle>Expense Transaction List</CardTitle>
                                                                    <div className="table-responsive-sm">
                                                                    <DataTabeSearch handleInputChange={this.handleExpenseInputChange}/>
                                                                        <Table className="table table-centered table-nowrap mb-0">

                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>Date</th>
                                                                                    <th>Transaction Type</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Member</th>
                                                                                    <th>Deposit Type</th>
                                                                                    <th>Remarks</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {expenseTransactionList.map((transaction, index) =>
                                                                                <tr key={transaction.trn_id.toString()}>
                                                                                    <td scope="row">{index+1}</td>
                                                                                    <td>{new Date(transaction.tran_date).toDateString()}</td>
                                                                                    <td>{transaction.f_tran_type_id}</td>
                                                                                    <td>{transaction.tran_amount}</td>
                                                                                    <td>{transaction.f_member_id}</td>
                                                                                    <td>{transaction.f_deposit_type_id}</td>
                                                                                    <td>{transaction.remarks}</td>
                                                                                    <td><button type="button"
                                                                                        className="btn btn-sm btn-primary waves-effect waves-light"
                                                                                        onClick={() => { this.tog_scroll(transaction) }} 
                                                                                        data-toggle="modal"
                                                                                        >Edit</button>
                                                                                        </td>
                                                                                </tr>
                                                                            )}
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </CardBody>
                                                                {expensePaginator &&
                                                                <CardFooter className="p-6">
                                                                    <CustomPagination paginator={expensePaginator} handlePageClick={this.handleExpensePageClick} />
                                                                </CardFooter>
                                                                }
                                                            </Card>
                                                            <Modal isOpen={this.state.modal_scroll}
                                                            toggle={this.tog_scroll}
                                                            scrollable={true} className={this.props.className}>
                                                                    <ModalHeader toggle={this.tog_scroll} tag="h4"> {tran_code} </ModalHeader>
                                                                        <ModalBody>
                                                                                <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditExpenseSubmit(e,v) }}>
                                                                                        <Row form>
                                                                                            <Col className="col-12">
                                                                                                <AvField className="form-control" type="date" label="Transaction Date" id="tran_date" name="tran_date" value={tran_date}/>
                                                                                                <AvField name="trn_id" id="trn_id" value={trn_id}  type="hidden"  />
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField type="select"className="custom-select" label="Expense Type" name="f_tran_type_id" id="f_tran_type_id" value={f_tran_type_id}>
                                                                                                        <option defaultValue>Select</option>
                                                                                                        {expenseCategoryList.map((expense, index) =>
                                                                                                            <option value={expense.category_id}>{expense.category_name}</option>
                                                                                                        )}
                                                                                                    </AvField>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField className="form-control" type="number" label="Amount" name="tran_amount" id="tran_amount" value={tran_amount}/>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField type="select"className="custom-select" label="Deposit Type" name="f_deposit_type_id" id="f_deposit_type_id" value={f_deposit_type_id}>
                                                                                                        <option value="1">Cash</option>
                                                                                                        <option value="2">Bank</option>
                                                                                                    </AvField>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField type="select"className="custom-select" label="Member" name="f_member_id" id="f_member_id" value={f_member_id}>
                                                                                                        <option defaultValue>Select</option>
                                                                                                        {memberList.map((member, index) =>
                                                                                                            <option value={member.member_id}>{member.member_name}</option>
                                                                                                        )}
                                                                                                    </AvField>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField className="form-control" label="Remarks" type="textarea" name="remarks" id="remarks" value={remarks}/>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <div className="text-right">
                                                                                                    <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                                                                    <button type="submit" className="btn btn-success save-event">Save</button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                </AvForm>
                                                                        </ModalBody>
                                                                </Modal>
                                                        </Col> 
                                                        <Col lg="4" md="4">
                                                            <Card>
                                                                <CardBody>
                                                                    <CardTitle>Create Expense Transaction</CardTitle>
                                                                    <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidExpenseSubmit(e,v) }}>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-12">
                                                                                <AvField className="form-control" type="date" label="Transaction Date"  id="tran_date" name="tran_date" value={this.state.tran_date}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-0">
                                                                            <div className="col-md-12">
                                                                                <AvField type="select"className="custom-select" name="f_tran_type_id" label="Expense Type" id="f_tran_type_id">
                                                                                    <option defaultValue>Select</option>
                                                                                    {expenseCategoryList.map((expense, index) =>
                                                                                        <option value={expense.category_id}>{expense.category_name}</option>
                                                                                    )}
                                                                                </AvField>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-12">
                                                                                <AvField className="form-control" label="Amount"  type="text" name="tran_amount" id="tran_amount" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-0">
                                                                            <div className="col-md-12">
                                                                                <AvField type="select" label="Deposit Type"  className="custom-select" name="f_deposit_type_id" id="f_deposit_type_id" value="1">
                                                                                    <option value="1">Cash</option>
                                                                                    <option value="2">Bank</option>
                                                                                </AvField>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-0">
                                                                            <div className="col-md-12">
                                                                                <AvField type="select" label="Member "  className="custom-select" name="f_member_id" id="f_member_id">
                                                                                    <option defaultValue>Select</option>
                                                                                    {memberList.map((member, index) =>
                                                                                        <option value={member.member_id}>{member.member_name}</option>
                                                                                    )}
                                                                                </AvField>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-12">
                                                                                <AvField className="form-control" label="Remarks"  type="textarea" name="remarks" id="remarks" />
                                                                            </div>
                                                                        </div>
                                                                        <div className=" mt-4">
                                                                            <Button type="submit" color="success">Submit</Button>
                                                                        </div>
                                                                    </AvForm>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                <Row>
                                                    <Col lg="8" md="8">
                                                
                                                            <Card>
                                                                <CardBody>
                                                                    <CardTitle>Income Transaction List</CardTitle>
                                                                    <div className="table-responsive-sm">
                                                                    <DataTabeSearch handleInputChange={this.handleIncomeInputChange}/>
                                                                        <Table className="table mb-0">

                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>Date</th>
                                                                                    <th>Transaction Type</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Member</th>
                                                                                    <th>Deposit Type</th>
                                                                                    <th>Remarks</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {incomeTransactionList.map((transaction, index) =>
                                                                                <tr key={transaction.trn_id.toString()}>
                                                                                    <td scope="row">{index+1}</td>
                                                                                    <td>{new Date(transaction.tran_date).toDateString()}</td>
                                                                                    <td>{transaction.f_tran_type_id}</td>
                                                                                    <td>{transaction.tran_amount}</td>
                                                                                    <td>{transaction.f_member_id}</td>
                                                                                    <td>{transaction.f_deposit_type_id}</td>
                                                                                    <td>{transaction.remarks}</td>
                                                                                    <td><button type="button"
                                                                                        className="btn btn-sm btn-primary waves-effect waves-light"
                                                                                        onClick={() => { this.tog_scroll(transaction) }} 
                                                                                        data-toggle="modal"
                                                                                        >Edit</button>
                                                                                        </td>
                                                                                </tr>
                                                                            )}
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </CardBody>
                                                                {incomePaginator &&
                                                                <CardFooter className="p-6">
                                                                    <CustomPagination paginator={incomePaginator} handlePageClick={this.handleIncomePageClick} />
                                                                </CardFooter>
                                                                }
                                                            </Card>
                                                            <Modal isOpen={this.state.modal_scroll}
                                                            toggle={this.tog_scroll}
                                                            scrollable={true} className={this.props.className}>
                                                                    <ModalHeader toggle={this.tog_scroll} tag="h4"> {tran_code} </ModalHeader>
                                                                        <ModalBody>
                                                                                <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditIncomeSubmit(e,v) }}>
                                                                                        <Row form>
                                                                                            <Col className="col-12">
                                                                                                <AvField className="form-control" type="date" label="Transaction Date" id="tran_date" name="tran_date" value={tran_date}/>
                                                                                                <AvField name="trn_id" id="trn_id" value={trn_id}  type="hidden"  />
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField type="select"className="custom-select" label="Income Type" name="f_tran_type_id" id="f_tran_type_id" value={f_tran_type_id}>
                                                                                                        <option defaultValue>Select</option>
                                                                                                        {incomeCategoryList.map((income, index) =>
                                                                                                            <option value={income.category_id}>{income.category_name}</option>
                                                                                                        )}
                                                                                                    </AvField>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField className="form-control" type="number" label="Amount" name="tran_amount" id="tran_amount" value={tran_amount}/>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField type="select"className="custom-select" label="Deposit Type" name="f_deposit_type_id" id="f_deposit_type_id" value={f_deposit_type_id}>
                                                                                                        <option value="1">Cash</option>
                                                                                                        <option value="2">Bank</option>
                                                                                                    </AvField>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField type="select"className="custom-select" label="Member" name="f_member_id" id="f_member_id" value={f_member_id}>
                                                                                                        <option defaultValue>Select</option>
                                                                                                        {memberList.map((member, index) =>
                                                                                                            <option value={member.member_id}>{member.member_name}</option>
                                                                                                        )}
                                                                                                    </AvField>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField className="form-control" label="Remarks" type="textarea" name="remarks" id="remarks" value={remarks}/>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <div className="text-right">
                                                                                                    <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                                                                    <button type="submit" className="btn btn-success save-event">Save</button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                </AvForm>
                                                                        </ModalBody>
                                                                </Modal>
                                                        </Col> 
                                                        <Col lg="4" md="4">
                                                            <Card>
                                                                <CardBody>
                                                                    <CardTitle>Create Income Transaction</CardTitle>
                                                                    <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidIncomeSubmit(e,v) }}>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-12">
                                                                                <AvField className="form-control" type="date" label="Transaction Date"  id="tran_date" name="tran_date" value={this.state.tran_date}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-0">
                                                                            <div className="col-md-12">
                                                                                <AvField type="select"className="custom-select" name="f_tran_type_id" label="Income Type" id="f_tran_type_id">
                                                                                    <option defaultValue>Select</option>
                                                                                    {incomeCategoryList.map((income, index) =>
                                                                                        <option value={income.category_id}>{income.category_name}</option>
                                                                                    )}
                                                                                </AvField>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-12">
                                                                                <AvField className="form-control" label="Amount"  type="text" name="tran_amount" id="tran_amount" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-0">
                                                                            <div className="col-md-12">
                                                                                <AvField type="select" label="Deposit Type"  className="custom-select" name="f_deposit_type_id" id="f_deposit_type_id" value="1">
                                                                                    <option value="1">Cash</option>
                                                                                    <option value="2">Bank</option>
                                                                                </AvField>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-0">
                                                                            <div className="col-md-12">
                                                                                <AvField type="select" label="Member "  className="custom-select" name="f_member_id" id="f_member_id">
                                                                                    <option defaultValue>Select</option>
                                                                                    {memberList.map((member, index) =>
                                                                                        <option value={member.member_id}>{member.member_name}</option>
                                                                                    )}
                                                                                </AvField>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-12">
                                                                                <AvField className="form-control" label="Remarks"  type="textarea" name="remarks" id="remarks" />
                                                                            </div>
                                                                        </div>
                                                                        <div className=" mt-4">
                                                                            <Button type="submit" color="success">Submit</Button>
                                                                        </div>
                                                                    </AvForm>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                <Row>
                                                    <Col lg="8" md="8">
                                                
                                                            <Card>
                                                                <CardBody>
                                                                    <CardTitle>Loan Transaction List</CardTitle>
                                                                    <div className="table-responsive-sm">
                                                                    <DataTabeSearch handleInputChange={this.handleLoanInputChange}/>
                                                                        <Table className="table mb-0">

                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>Date</th>
                                                                                    <th>Loan Type</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Member</th>
                                                                                    <th>Deposit Type</th>
                                                                                    <th>Remarks</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {personalLoanList.map((personalLoan, index) =>
                                                                                <tr key={personalLoan.loan_id.toString()}>
                                                                                    <td scope="row">{index+1}</td>
                                                                                    <td>{new Date(personalLoan.loan_date).toDateString()}</td>
                                                                                    <td>{personalLoan.f_loan_type_id }</td>
                                                                                    <td>{personalLoan.loan_amount}</td>
                                                                                    <td>{personalLoan.f_member_id}</td>
                                                                                    <td>{personalLoan.f_deposit_type_id}</td>
                                                                                    <td>{personalLoan.remarks}</td>
                                                                                    <td><button type="button"
                                                                                        className="btn btn-sm btn-primary waves-effect waves-light"
                                                                                        onClick={() => { this.tog_loan(personalLoan) }} 
                                                                                        data-toggle="modal"
                                                                                        >Edit</button>
                                                                                        </td>
                                                                                </tr>
                                                                            )}
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </CardBody>
                                                                {loanPaginator &&
                                                                <CardFooter className="p-6">
                                                                    <CustomPagination paginator={loanPaginator} handlePageClick={this.handleLoanPageClick} />
                                                                </CardFooter>
                                                                }
                                                            </Card>
                                                            <Modal isOpen={this.state.modal_loan}
                                                            toggle={this.tog_loan}
                                                            scrollable={true} className={this.props.className}>
                                                                    <ModalHeader toggle={this.tog_loan} tag="h4"> {loan_code} </ModalHeader>
                                                                        <ModalBody>
                                                                                <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditLoanSubmit(e,v) }}>
                                                                                        <Row form>
                                                                                            <Col className="col-12">
                                                                                                <AvField className="form-control" type="date" label="Transaction Date" id="loan_date" name="loan_date" value={loan_date}/>
                                                                                                <AvField name="loan_id" id="loan_id" value={loan_id}  type="hidden"  />
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField type="select"className="custom-select" label="Loan Type" name="f_loan_type_id" id="f_loan_type_id" value={f_loan_type_id}>
                                                                                                        <option defaultValue>Select</option>
                                                                                                        {loanTypeList.map((loan, index) =>
                                                                                                            <option value={loan.loan_type_id}>{loan.loan_type_name_en}</option>
                                                                                                        )}
                                                                                                    </AvField>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField className="form-control" type="number" label="Amount" name="loan_amount" id="loan_amount" value={loan_amount}/>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField type="select"className="custom-select" label="Deposit Type" name="f_deposit_type_id" id="f_deposit_type_id" value={loan_deposit_type}>
                                                                                                        <option value="1">Cash</option>
                                                                                                        <option value="2">Bank</option>
                                                                                                    </AvField>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField type="select"className="custom-select" label="Member" name="f_member_id" id="f_member_id" value={loan_member}>
                                                                                                        <option defaultValue>Select</option>
                                                                                                        {memberList.map((member, index) =>
                                                                                                            <option value={member.member_id}>{member.member_name}</option>
                                                                                                        )}
                                                                                                    </AvField>
                                                                                            </Col>
                                                                                            <Col className="col-12">
                                                                                                    <AvField className="form-control" label="Remarks" type="textarea" name="remarks" id="remarks" value={loan_remarks}/>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <div className="text-right">
                                                                                                    <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                                                                    <button type="submit" className="btn btn-success save-event">Save</button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                </AvForm>
                                                                        </ModalBody>
                                                                </Modal>
                                                        </Col> 
                                                        <Col lg="4" md="4">
                                                            <Card>
                                                                <CardBody>
                                                                    <CardTitle>Create Loan Transaction</CardTitle>
                                                                    <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidLoanSubmit(e,v) }}>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-12">
                                                                                <AvField className="form-control" type="date" label="Transaction Date"  id="loan_date" name="loan_date" value={this.state.tran_date}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-0">
                                                                            <div className="col-md-12">
                                                                                <AvField type="select"className="custom-select" name="f_loan_type_id" label="Loan Type" id="f_loan_type_id">
                                                                                    <option defaultValue>Select</option>
                                                                                    {loanTypeList.map((loan, index) =>
                                                                                        <option value={loan.loan_type_id}>{loan.loan_type_name_en}</option>
                                                                                    )}
                                                                                </AvField>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-12">
                                                                                <AvField className="form-control" label="Amount"  type="text" name="loan_amount" id="loan_amount" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-0">
                                                                            <div className="col-md-12">
                                                                                <AvField type="select" label="Deposit Type"  className="custom-select" name="f_deposit_type_id" id="f_deposit_type_id" value="1">
                                                                                    <option value="1">Cash</option>
                                                                                    <option value="2">Bank</option>
                                                                                </AvField>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row mb-0">
                                                                            <div className="col-md-12">
                                                                                <AvField type="select" label="Member "  className="custom-select" name="f_member_id" id="f_member_id">
                                                                                    <option defaultValue>Select</option>
                                                                                    {memberList.map((member, index) =>
                                                                                        <option value={member.member_id}>{member.member_name}</option>
                                                                                    )}
                                                                                </AvField>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-12">
                                                                                <AvField className="form-control" label="Remarks"  type="textarea" name="remarks" id="remarks" />
                                                                            </div>
                                                                        </div>
                                                                        <div className=" mt-4">
                                                                            <Button type="submit" color="success">Submit</Button>
                                                                        </div>
                                                                    </AvForm>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                
                                            </TabContent>
                                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                                            <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab - 1);} }>Previous</Link></li>
                                            <li className={this.state.activeTab === 4 ? "next disabled" : "next"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab + 1);} }>Next</Link></li>
                                            </ul>
                                        </div>
                                    </CardBody>
                                </Card>

                        
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    console.log('kop',state);
    const { msg,error,success } = state.TransactionSucess;
    const { loanError,loanSuccess } = state.PersonalLoan;
    return { msg,error,success,loanError,loanSuccess};
}

export default withRouter(connect(mapStatetoProps, { createTransaction,editTransaction,createPersonalLoan,editPersonalLoan })(TransactionCreate));
