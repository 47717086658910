import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../Common/CustomPagination";
import {DataTabeSearch} from '../Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createCategory,categoryList,editCategory} from '../../../store/category/actions';

class CategoryCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                category: {
                    
                }
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchCategoryList();     
    }

    fetchCategoryList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        categoryList(options).then((response) => {
            this.setState({
                categoryList :response, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (category) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.category = category;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {          
            this.props.createCategory(values);
            this.fetchCategoryList();     
        }

       
       handleEditSubmit(event, values) {  
           console.log('values',values);       
            this.props.editCategory(values);
            this.fetchCategoryList();     
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchCategoryList();
        });
       }

       handlePageClick = (e, index) => {
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchCategoryList();
            });
        }

    render() {
        let categoryList = [];
        let paginator;
        if (this.state.hasOwnProperty('categoryList')) {
            categoryList = this.state.categoryList.data;
            paginator = {
                'currentPage':this.state.categoryList.currentPage,
                'pagesCount':this.state.categoryList.pagesCount,
                'total':this.state.categoryList.total,
                'to':this.state.categoryList.to,
                'from':this.state.categoryList.from,
            }
        }
        const {category_id,category_name,category_name_bn,type,reminder} = this.state.category;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="category" />

                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Category List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Type</th>
                                                        <th>Category Name</th>
                                                        <th>Category Name (Bangla)</th>
                                                        <th>Reminder</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {categoryList.map((category, index) =>
                                                    <tr key={category.category_id.toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{category.type}</td>
                                                        <td>{category.category_name}</td>
                                                        <td>{category.category_name_bn}</td>
                                                        <td><Badge color={getBadge(category.reminder)}>{getStatusValue(category.reminder)}</Badge></td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(category) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{category_name}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               User Updated Successfully!
                                           </Alert>
                                           ) : null}
                                           <div className="form-group ">
                                               <AvField name="category_name" label="Category Name" value={category_name} className="form-control " placeholder="Enter UserName" type="text" />
                                               <AvField name="category_id" id="category_id" value={category_id}  type="hidden"  />
                                           </div>
                                           <div className="form-group">
                                               <AvField name="category_name_bn" label="Category Name (Bangla)" value={category_name_bn} className="form-control" placeholder="Enter category_name_bn" type="text" />
                                           </div>
                                               <AvField type="select"className="custom-select" label="Type" value={type} name="type" id="type">
                                                   <option defaultValue>Select</option>
                                                   <option value="Income">Income</option>
                                                   <option value="Expense">Expense</option>
                                               </AvField>
                                           <div className="custom-control custom-checkbox mb-3">
                                               <input type="checkbox" className="custom-control-input" id="reminder" onChange={() => false} checked={reminder} />
                                               <label className="custom-control-label" onClick={() => { this.setState({ customchk: !this.state.customchk }) }} >Reminder</label>
                                           </div>
                                           <div className="text-center mt-4">
                                               <Button type="submit" color="danger">Update</Button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Category</CardTitle>
                                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }}>
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    User Updated Successfully!
                                                </Alert>
                                                ) : null}
                                            <div className="form-group row">
                                                <label htmlFor="example-text-input" className="col-md-2 col-form-label" >Category Name</label>
                                                <div className="col-md-10">
                                                    <AvField className="form-control" type="text" name="category_name" id="category_name" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="example-search-input" className="col-md-2 col-form-label" >Category Name (Bangla)</label>
                                                <div className="col-md-10">
                                                    <AvField className="form-control" type="text" name="category_name_bn" id="category_name_bn" />
                                                </div>
                                            </div>
                                            <div className="form-group row mb-0">
                                                <label className="col-md-2 col-form-label" >Category Type</label>
                                                <div className="col-md-10">
                                                    <AvField type="select"className="custom-select" name="type" id="type">
                                                        <option defaultValue>Select</option>
                                                        <option value="Income">Income</option>
                                                        <option value="Expense">Expense</option>
                                                    </AvField>
                                                </div>
                                            </div>
                                            <div className="custom-control custom-switch mb-2" dir="ltr">
                                                <AvInput  type="checkbox" className="custom-control-input" name="reminder" id="reminder" />
                                                <label className="custom-control-label" htmlFor="reminder" onClick={(e) => { this.setState({ toggleSwitch: !this.state.toggleSwitch }) }}>Reminder</label>
                                                
                                            </div>
                                            <div className=" mt-4">
                                                <Button type="submit" color="success">Submit</Button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createCategory,editCategory })(CategoryCreate));
