import { WAREHOUSESUPPLIERMAP_ERROR,WAREHOUSESUPPLIERMAP_SUCCESS,EDIT_WAREHOUSESUPPLIERMAP } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const WarehouseSupplierMap = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_WAREHOUSESUPPLIERMAP:
            state = { ...state }
            break;
        case WAREHOUSESUPPLIERMAP_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case WAREHOUSESUPPLIERMAP_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default WarehouseSupplierMap;