import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_TRANSACTION,TRANSACTION_SUCCESS,EDIT_TRANSACTION,SHOW_TRANSACTION } from './actionTypes';

import { transactionSuccess, transactionError,transactionShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createTransaction({ payload: { transaction } }) {

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'transaction', { 
                    tran_amount : transaction.tran_amount,
                    tran_date : transaction.tran_date,
                    f_tran_type_id : transaction.f_tran_type_id,  
                    f_deposit_type_id : transaction.f_deposit_type_id,
                    f_member_id : transaction.f_member_id,
                    remarks : transaction.remarks,
                });
             yield put(transactionSuccess(response));
          }
    } catch (error) {
        yield put(transactionError(error));
    }
}


function* editTransaction({ payload: { transaction } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'transaction/id/'+transaction.trn_id, { 
                    tran_amount : transaction.tran_amount,
                    tran_date : transaction.tran_date,
                    f_tran_type_id : transaction.f_tran_type_id,  
                    f_deposit_type_id : transaction.f_deposit_type_id,
                    f_member_id : transaction.f_member_id,
                    remarks : transaction.remarks,
                });
             yield put(transactionSuccess(response));
          }
          
          
    } catch (error) {
        yield put(transactionError(error));
    }
}


function* showTransaction({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);
             yield put(transactionShow(response));
          }
          
          
    } catch (error) {
        yield put(transactionError(error));
    }
}

export function* watchTransaction() {
    yield takeEvery(EDIT_TRANSACTION, editTransaction)
    yield takeEvery(CREATE_TRANSACTION, createTransaction)
    yield takeEvery(SHOW_TRANSACTION, showTransaction)
}

function* TransactionSaga() {
    yield all([
        fork(watchTransaction),
    ]);
}

export default TransactionSaga;