import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createProduct,productList,editProduct,discountList,productImageUpload,searchBrandwithCategory,daleteProduct} from '../../../store/product/actions';
import { createBrand,brandList,editBrand,brandListAll} from '../../../store/brand/actions';
import { createCategory,categoryList,editCategory,categoryTreeList} from '../../../store/category/actions';
import axios from 'axios';
import Switch from 'react-switch';
// import shortid from 'shortid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }

class ProductIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
                status: true,
                is_free : true,
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                image_modal_scroll: false,
                uploadImage: {},
                product: {
                    
                },
                selectedCategory:'',
                
                // displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleIsFreeChange = this.handleIsFreeChange.bind(this);
    }
    fetchCategoryTreeList = () => {
        categoryTreeList().then((response) => {
            this.setState({
                rootCategories :response.data.rootCategories, 
                categoryTree :response.data.childTree, 
            });
        })
        .catch((err) => {
            console.log(err);
        })
    }
    componentDidMount()
    {
            this.fetchproductList();  
            this.fetchbrandList(); 
            this.fetchCategoryList();  
            this.fetchdiscountList();  
            this.fetchCategoryTreeList();  
    }
componentWillUnmount() {
    this._isMounted = false;
  }


    // fetchbrandList = () => {
    //     this.state.displayHide= { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
    //     let options = {
    //         page: this.state.page,
    //         per_page: 50,
    //         search: this.state.search
    //     }
    //     brandList(options).then((response) => {
    //         this.setState({
    //             brandList :response.data.data, 
    //         });
    //     this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     })
    // }

    fetchdiscountList = () => {
      
        let options = {
            page: this.state.page,
            per_page: 50,
            search: this.state.search
        }
        discountList(options).then((response) => {
            this.setState({
                discountList :response.data, 
            });
      
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchCategoryList = () => {

        let options = {
            page: this.state.page,
            per_page: 50,
            search: this.state.search
        }
        categoryList(options).then((response) => {
            this.setState({
                categoryList :response.data.data, 
            });
       
        })
        .catch((err) => {
            console.log(err);
        })
    }

    fetchbrandList = () => {
        brandListAll().then((response) => {
            this.setState({
                brandList: response.data,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchproductList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        productList(options).then((response) => {
            this.setState({
                productList :response.data, 
            });
            this.state.productList = response.data;

        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
      tog_scroll = (product) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.product = product;
        this.state.is_free = product.is_delivery_charge_free;
        // this.fetchBrandCategoryList(product.f_category_info_id);
        this.removeBodyCss();

      }

      tog_image_scroll = (product) => {
        this.setState(prevState => ({
          image_modal_scroll: !prevState.image_modal_scroll,
        }));
        this.state.featureImage = product.feature_image;
        this.state.product = product;
        this.removeBodyCss();

      }

      handleImageUpload = (e) => {
        var id = e.target.id;
        var f_product_id = this.state.product.product_id;
        let files = e.target.files;

            let reader = new FileReader(); 
            reader.readAsDataURL(files[0]); 
            reader.onload = (e)  => {
                const formData = {file: e.target.result, name:files[0].name, type:files[0].type ,f_product_id: f_product_id};

                productImageUpload(formData,id).then((response) => {
                    this.state.featureImage = response.data.products.feature_image;
                    this.fetchproductList();     
                    alert('Image Uploaded Successfully');

                })
                .catch((err) => {
                    console.log(err);
                })
            }
       }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            this.props.createProduct(values);
            this.fetchproductList();     
        }

       
       handleEditSubmit(event, values) { 
           console.log("values",values)
            this.props.editProduct(values);
            // this.setState(prevState => ({
            //     modal_scroll: !prevState.modal_scroll,
            //   }));
            this.fetchproductList();

       }


    //    handleEditInputChange = (e) => {
    //     this.setState({[e.target.name]: e.target.value});
    //     if(e.target.name == 'f_category_info_id'){
    //         this.fetchBrandCategoryList(e.target.value);
    //     }
    // }
       handleEditImageSubmit(event, values) {  
           console.log('image',values);
        this.props.editProduct(values);
        this.setState(prevState => ({
            image_modal_scroll: !prevState.image_modal_scroll,
          }));
        this.fetchproductList();     
   }

   handleChange(status) {
    this.setState({ status });
  }

  handleIsFreeChange(is_free) {
    this.setState({ is_free });
  }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchproductList();
            this.fetchCategoryList(); 
            // this.fetchBrandCategoryList();  
            this.fetchCategoryTreeList();  

        });
       }
       handlePageClick = (e, index) => {
                // this.state.displayHide={ display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}

            e.preventDefault()
            this.setState({
                page: index,

            },() => {
                this.fetchproductList();
                // this.state.displayHide= {display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}

            });
        }

    handleDeleteSubmit( values) {
            alert("Are You Want To Delete This?");
            this.fetchproductList();  
            daleteProduct(values)
            .then((response) => {
              if (response.data.status == true) {
               //   console.log(response)
                alert(response.data.message);
                this.fetchproductList();  
                window.location.reload();
              }
              else if (response.data.status == true) {
                alert(response.data.message);
              }
            })
            .catch((err) => {
              this.props.handleErrorResponse(err);
            //  console.log('err', this.props.handleErrorResponse(err));
            });
        }    
    //   var selectedCategory;
    onChangeDrop = (currentNode, selectedNodes) => {
        console.log('onChange::', currentNode);
        this.setState({ selectedCategory: currentNode.category_id,value :currentNode.value,label:currentNode.label});     
        // this.fetchCategoryTreeList();  

      }

       onAction = (node, action) => {
        console.log('onAction::', action, node)

      }
       onNodeToggle = currentNode => {
        console.log('onNodeToggle::', currentNode)

      }
    render() {
        let categoryList = [];
        let brandList = [];
        let discountListMap =[];
        let featureImage = [];
        let categoryTree = [];
        if (this.state.hasOwnProperty('categoryTree')) {  categoryTree = this.state.categoryTree; }
        if (this.state.hasOwnProperty('categoryList')) { categoryList = this.state.categoryList; }
        if (this.state.hasOwnProperty('brandList')) { brandList = this.state.brandList; }
        if (this.state.hasOwnProperty('featureImage')) { featureImage = this.state.featureImage; }
        if (this.state.hasOwnProperty('discountList')) { discountListMap = this.state.discountList; }
        let productListMap = [];
        let paginator;
        if (this.state.hasOwnProperty('productList')) {
            productListMap = this.state.productList.data;

            paginator = {
                'currentPage':this.state.productList.current_page,
                'pagesCount':this.state.productList.last_page,
                'total':this.state.productList.total,
                'to':this.state.productList.to,
                'from':this.state.productList.from,
            }
        }
        const imageLink =process.env.REACT_APP_IMAGE_URL;
        // const imageLink ='http://localhost:8083/';
        const {product_id,product_code,product_name_bn,product_name_en,product_bar_code,f_category_info_id,f_brand_id,f_unit_id,product_stock_balance,product_buy_price,product_sale_price,product_market_price,discount,product_description,product_weight,product_sku_code,product_specification,minimum_oder_quantity,product_meta_tag,f_discount_type,status,is_delivery_charge_free} = this.state.product;
        var upload_image_arry = [0,1,2,3,4];
        featureImage = (featureImage == undefined?[]:featureImage);
        upload_image_arry = upload_image_arry.slice(featureImage.length)
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }

          if(this.props.success != ''){
            setTimeout(function() {
                window.location.reload();
            }, 2000);
             
          }
    
        return (
            <React.Fragment>
                    {/* <div style={this.state.displayHide}> 
                                <h6 align="center">Loding...</h6> 
                                <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="product" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="12" md="12">
                                <Card>
                                    <CardHeader>
                                        <Link to={'/productCreate'}><Button className="btn btn-success pull-right">Add New Product</Button></Link>
                                    </CardHeader>
                                    <CardBody>
                                        <CardTitle>Product List</CardTitle>
                                        <div className="table-responsive-xs">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table table-responsive">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Product Name</th>
                                                        <th>Product Name (Bangla)</th>
                                                        <th>Code</th>
                                                        <th>BarCode</th>
                                                        <th>Category</th>
                                                        <th>Brand</th>
                                                        <th>Unit</th>
                                                        <th>Stock Balance</th>
                                                        <th>Buy Price</th>
                                                        <th>Sale Price</th>
                                                        <th>Market Price</th>
                                                        <th>Discount</th>
                                                        <th>Status</th>
                                                        <th style={{width: '210px'}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {productListMap.map((product, index) =>
                                                    <tr key={product.product_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{product.product_name_en}</td>
                                                        <td>{product.product_name_bn}</td>
                                                        <td>{product.product_code}</td>
                                                        <td>{product.product_bar_code}</td>
                                                        <td>{product.category == null? product.f_category_info_id:product.category.category_name_en}</td>
                                                        <td>{product.brand == null? product.f_brand_id:product.brand.brand_name_en}</td>
                                                        <td>{product.f_unit_id ==1? 'Piece':'KG'}</td>
                                                        <td>{product.product_stock_balance}</td>
                                                        <td>{product.product_buy_price}</td>
                                                        <td>{product.product_sale_price}</td>
                                                        <td>{product.product_market_price}</td>
                                                        <td>{product.discount}</td>
                                                        <td><Badge color={getBadge(product.status)}>{getStatusValue(product.status)}</Badge></td>
                                                        
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(product) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>&nbsp;&nbsp;
                                                             <button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_image_scroll(product) }} 
                                                               data-toggle="modal"
                                                             >Edit Image</button>   
                                                            <button type="button"
                                                                className="btn btn-sm btn-danger waves-effect waves-light"
                                                                onClick={() => { this.handleDeleteSubmit( product.product_id) }}
                                                                >Delete</button>
                                                            
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   size="lg"
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{product_name_en}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               product Updated Successfully!
                                           </Alert>
                                           ) : null}
                                           <div className="form-group">
                                               <AvField name="product_code" label="Product Code" value={product_code} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group ">
                                               <AvField name="product_name_en"  label={reqLabel('Product Name')} value={product_name_en} className="form-control "  type="text" />
                                               <AvField name="product_id" id="product_id" value={product_id}  type="hidden"  />
                                           </div>
                                           <div className="form-group">
                                               <AvField name="product_name_bn" label="Product Name (Bangla)" value={product_name_bn} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group">
                                               <AvField name="product_bar_code" label="Product Bar Code" value={product_bar_code} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group">
                                           <DropdownTreeSelect mode="radioSelect"  data={categoryTree} onChange={this.onChangeDrop} onAction={this.onAction} onNodeToggle={this.onNodeToggle} />{this.state.label}
                                           <AvField  name="f_category_info_id" value={this.state.selectedCategory} className="form-control"  type="hidden" />

                                                <AvField name="sasd" label="Category" value={f_category_info_id} type="select" className="custom-select" >
                                                                                <option defaultValue>{this.state.label}</option>
                                                                            {categoryList.map((category,index)=>
                                                                                <option value={category.category_id}>{category.category_name_en}</option>
                                                                            )}
                                                </AvField>           
                                           </div>
                                           <div className="form-group">
                                               <AvField name="f_brand_id" label={reqLabel('Brand')} value={f_brand_id} type="select"className="custom-select" >
                                                        <option defaultValue>Select Brand</option>
                                                        {brandList.map((brand,index)=>
                                                            <option value={brand.brand_id}>{brand.brand_name_en}</option>
                                                        )}
                                                </AvField>
                                           </div>
                                           <div className="form-group">
                                               <AvField name="f_unit_id" label={reqLabel('Unit')} value={f_unit_id} type="select"className="custom-select"  >
                                                            <option defaultValue>Select Unit</option>
                                                            <option value="1">Piece</option>
                                                            <option value="2">Ltr</option>
                                                            <option value="2">ml</option>
                                                            <option value="3">kg</option>
                                                            <option value="4">gm</option>
                                                            <option value="2">ml</option>
                                                </AvField>
                                           </div>
                                           {/* <div className="form-group">
                                               <AvField name="product_stock_balance" label="Current Stock" value={product_stock_balance} className="form-control"  type="text" readonly />
                                           </div> */}
                                           <div className="form-group">
                                               <AvField name="product_buy_price" label={reqLabel('Buy Price')} value={product_buy_price} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group">
                                               <AvField name="product_sale_price" label={reqLabel('Sale Price')}  value={product_sale_price} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group">
                                               <AvField name="product_market_price" label={reqLabel('Market Price')} value={product_market_price} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group">
                                                <AvField name="product_weight" label="Weight" value={product_weight}  className="form-control"  type="text" />
                                            </div>
                                            <div className="form-group">
                                                <AvField name="product_sku_code" label="SKU Code" value={product_sku_code}  className="form-control"  type="text" />
                                            </div>
                                            <div className="form-group">
                                                <AvField name="minimum_oder_quantity" label="Minimum Order Quantity" value={minimum_oder_quantity}  className="form-control"  type="number" />
                                            </div>
                                            <div className="form-group">
                                                <AvField name="product_meta_tag" label="Meta Tag" value={product_meta_tag}  className="form-control"  type="textarea" />
                                            </div>
                                            <div className="form-group">
                                               <AvField name="f_discount_type" label={reqLabel('Discount Type')} value={f_discount_type} type="select"className="custom-select" >
                                                        <option defaultValue>Discount Type</option>
                                                        {discountListMap.map((discount,index)=>
                                                            <option value={discount.discount_id}>{discount.discount_type_name}</option>
                                                        )}
                                                </AvField>
                                           </div>
                                           <div className="form-group">
                                           <AvField  name="discount" label={reqLabel('Discount')} value={discount} className="form-control"  ></AvField>
                                           </div>
                                            <div className="form-group">
                                                <AvField name="product_description" label="Description"  value={this.state.product_description}  className="form-control"  type="hidden" />
                                                                        <CKEditor
                                                                            editor={ ClassicEditorDescription }
                                                                            data={this.state.product_description}
                                                                            onReady={ editor => {
                                                                                this.setState({
                                                                                    product_description :product_description, 
                                                                                });
                                                                                // You can store the "editor" and use when it is needed.
                                                                                // console.log( 'Editor is ready to use!', editor );
                                                                            } }
                                                                            onChange={ ( event, editor ) => {
                                                                                const data = editor.getData();
                                                                                this.setState({
                                                                                    product_description :data, 
                                                                                });
                                                                            } }
                                                                            onBlur={ ( event, editor ) => {
                                                                                // console.log( 'Blur.', editor );
                                                                            } }
                                                                            onFocus={ ( event, editor ) => {
                                                                                // console.log( 'Focus.', editor );
                                                                            } }
                                                                            
                                                                    />
                                            </div>
                                            <div className="form-group">
                                                <AvField name="product_specification" label={reqLabel('Product Specification')} value={this.state.product_specification}  className="form-control"  type="hidden" />
                                                <CKEditor
                                                        editor={ ClassicEditorSpecification }
                                                        data={this.state.product_specification}
                                                        onReady={ editor => {
                                                            this.setState({
                                                                product_specification :product_specification, 
                                                            });
                                                            // You can store the "editor" and use when it is needed.
                                                            // console.log( 'Editor is ready to use!', editor );
                                                        } }
                                                        onChange={ ( event, editor ) => {
                                                            const data = editor.getData();
                                                            this.setState({
                                                                product_specification :data, 
                                                            });
                                                        } }
                                                        onBlur={ ( event, editor ) => {
                                                            // console.log( 'Blur.', editor );
                                                        } }
                                                        onFocus={ ( event, editor ) => {
                                                            // console.log( 'Focus.', editor );
                                                        } }
                                                />
                                            </div>
                                          
                                            <Row>
                                                {/* <Col lg="12">
                                                                    <div className="custom-control custom-switch mb-2" dir="ltr">
                                                                        <AvInput  type="checkbox" className="custom-control-input" name="status" id="status" />
                                                                        <label className="custom-control-label" htmlFor="status" >Status</label>
                                                    
                                                                    </div>
                                                    </Col> */}   
                                                    <Col lg="6">
                                                        <div>
                                                            <Switch
                                                                name="status"
                                                                id="status"
                                                                value={status}
                                                                onChange={this.handleChange}
                                                                checked={this.state.status}
                                                                className="react-switch"
                                                                id="normal-switch"
                                                            />
                                                            <span style={{marginLeft:'20px'}}>{this.state.status ? 'Is Active' : 'Is Inactive'}</span>
                                                            <AvField name="status" value={this.state.status}  type="hidden" />
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                                    {/* <div className="form-group custom-checkbox" > */}
                                                                        {/* <AvField onChangeText={this.handleIsFreeChange}
                                                                        checked={this.state.isFree} type="checkbox" className="Custom-control-input " name="IsDeliveryChargeFree" label="Is Delivery Charge Free"/> */}
                                                                    {/* </div> */}
                                                        <Switch
                                                                name="DeliveryChargeFree"
                                                                        id="DeliveryChargeFree"
                                                                        value={is_delivery_charge_free}
                                                                        onChange={this.handleIsFreeChange}
                                                                        checked={this.state.is_free}
                                                                        className="react-switch"
                                                                       
                                                                />
                                                            <span style={{marginLeft:'20px'}}>{this.state.is_free ? 'Delivery Charge Free' : 'Delivery Charge Not Free'}</span>
                                                            <AvField name="DeliveryChargeFree" value={this.state.is_free} type="hidden" />
                                                    </Col>  
                                            </Row>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                               <Modal
                                 isOpen={this.state.image_modal_scroll}
                                   toggle={this.tog_image_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">Feature Image</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ image_modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditImageSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Image Updated Successfully!
                                           </Alert>
                                           ) : null}
                                            <AvField  type="hidden" value={this.state.uploadImage} name="feature_image" id="feature_image"/>

                                           {featureImage.map((image,index)=>
                                           <div className="form-group">
                                                    <img src={imageLink+'upload/Products/'+image.f_product_id+'/featureImage/'+image.feature_image} alt="" height="200" />

                                                    <AvField  label="Change Picture" type="file" className="form-control" name={'f_product_id_'+index} onChange={(e) =>this.handleImageUpload(e)} id={image.feature_image_id}/>

                                            </div>
                                           )}

                                           {upload_image_arry.map((image)=>
                                                <div className="form-group ">
                                                    <AvField label="Upload Picture" name={'f_product_id_'+image} id={''} onChange={(e) =>this.handleImageUpload(e)}  className="form-control "  type="file" />
                                                </div>
                                           )}

                                           
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_image_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Product;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createProduct,editProduct,daleteProduct })(ProductIndex));
