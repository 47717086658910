import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PERSONAL_LOAN,PERSONAL_LOAN_SUCCESS,EDIT_PERSONAL_LOAN,SHOW_PERSONAL_LOAN } from './actionTypes';

import { personalLoanSuccess, personalLoanError,showPersonalLoan } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* createPersonalLoan({ payload: { personalLoan } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'personalLoan', { 
                    loan_amount : personalLoan.loan_amount,
                    loan_date : personalLoan.loan_date,
                    f_loan_type_id : personalLoan.f_loan_type_id,  
                    f_deposit_type_id : personalLoan.f_deposit_type_id,
                    f_member_id : personalLoan.f_member_id,
                    remarks : personalLoan.remarks,
                });
             yield put(personalLoanSuccess(response));
          }
    } catch (error) {
        yield put(personalLoanError(error));
    }
}


function* editPersonalLoan({ payload: { personalLoan } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'personalLoan/id/'+personalLoan.loan_id, { 
                    loan_amount : personalLoan.loan_amount,
                    loan_date : personalLoan.loan_date,
                    f_loan_type_id : personalLoan.f_loan_type_id,  
                    f_deposit_type_id : personalLoan.f_deposit_type_id,
                    f_member_id : personalLoan.f_member_id,
                    remarks : personalLoan.remarks,
                });
             yield put(personalLoanSuccess(response));
          }
          
          
    } catch (error) {
        yield put(personalLoanError(error));
    }
}


// function* showPersonalLoan({ payload: { user_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtData, 'personalLoan/id/'+user_id);
//              yield put(showPersonalLoan(response));
//           }
          
          
//     } catch (error) {
//         yield put(personalLoanError(error));
//     }
// }

export function* watchPersonalLoan() {
    yield takeEvery(EDIT_PERSONAL_LOAN, editPersonalLoan)
    yield takeEvery(CREATE_PERSONAL_LOAN, createPersonalLoan)
    // yield takeEvery(SHOW_PERSONAL_LOAN, showPersonalLoan)
}

function* PersonalLoanSaga() {
    yield all([
        fork(watchPersonalLoan),
    ]);
}

export default PersonalLoanSaga;