import React, { Component } from "react";
import { CardImg,Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,Badge,CardFooter,Modal } from "reactstrap";
import { AvForm, AvField,AvInput  } from 'availity-reactstrap-validation';
import {CustomPagination} from "../../Settings/Common/CustomPagination";
import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { createFuel,fuelList,editFuel} from '../../../store/fuel/actions';

const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>*</sup></>;
  }

class FuelCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                fuel: {
                    
                },
                displayHide: { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount()
    {
            this.fetchfuelList();     
            this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            
    }

    fetchfuelList = () => {
        this.state.displayHide= { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        fuelList(options).then((response) => {
            this.setState({
                fuelList :response.data, 
            });
            this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //modal
    tog_scroll = (fuel) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.fuel = fuel;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
            this.props.createFuel(values);
            this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            this.fetchfuelList();     
        }

       
       handleEditSubmit(event, values) {  
            this.props.editFuel(values);
            this.setState(prevState => ({
                modal_scroll: !prevState.modal_scroll,
              }));
              this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            this.fetchfuelList();     
       }

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchfuelList();
        });
       }

       handlePageClick = (e, index) => {
        this.state.displayHide= { display: 'block',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                this.fetchfuelList();
                this.state.displayHide= { display: 'none',position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}
            });
        }

    render() {
        let fuelListMap = [];
        let paginator;
        if (this.state.hasOwnProperty('fuelList')) {
            fuelListMap = this.state.fuelList.data;
            paginator = {
                'currentPage':this.state.fuelList.current_page,
                'pagesCount':this.state.fuelList.last_page,
                'total':this.state.fuelList.total,
                'to':this.state.fuelList.to,
                'from':this.state.fuelList.from,
            }
        }
        const {fuel_id,fuel_code,fuel_name_bn,fuel_name_en} = this.state.fuel;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
        return (
            <React.Fragment>
                    <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="fuel" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                        <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Fuel List</CardTitle>
                                        <div className="table-responsive-sm">
                                        <DataTabeSearch handleInputChange={this.handleInputChange}/>
                                            <Table className="table mb-0">

                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Fuel Name</th>
                                                        <th>Fuel Name (Bangla)</th>
                                                        <th>Code</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {fuelListMap.map((fuel, index) =>
                                                    <tr key={fuel.fuel_id .toString()}>
                                                        <td scope="row">{index+1}</td>
                                                        <td>{fuel.fuel_name_en}</td>
                                                        <td>{fuel.fuel_name_bn}</td>
                                                        <td>{fuel.fuel_code}</td>
                                                        <td><button type="button"
                                                               className="btn btn-sm btn-primary waves-effect waves-light"
                                                               onClick={() => { this.tog_scroll(fuel) }} 
                                                               data-toggle="modal"
                                                             >Edit</button>
                                                            </td>
                                                       </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                     <CardFooter className="p-6">
                                          <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                      </CardFooter>
                                      }
                                </Card>
                                <Modal
                                 isOpen={this.state.modal_scroll}
                                   toggle={this.tog_scroll}
                                   scrollable={true}
                                   >
                                   <div className="modal-header">
                                       <h5 className="modal-title mt-0">{fuel_name_en}</h5>
                                       <button
                                       type="button"
                                       onClick={() =>
                                           this.setState({ modal_scroll: false })
                                       }
                                       className="close"
                                       data-dismiss="modal"
                                       aria-label="Close"
                                       >
                                       <span aria-hidden="true">&times;</span>
                                       </button>
                                   </div>
                                   <div className="modal-body">
                                   <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleEditSubmit(e,v) }}>
                                       {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Fuel Updated Successfully!
                                           </Alert>
                                           ) : null}
                                           <div className="form-group">
                                               <AvField name="fuel_code" label="Fuel Code" value={fuel_code} className="form-control"  type="text" />
                                           </div>
                                           <div className="form-group ">
                                               <AvField name="fuel_name_en" label={reqLabel("Fuel Name")} value={fuel_name_en} className="form-control "  type="text" />
                                               <AvField name="fuel_id" id="fuel_id" value={fuel_id}  type="hidden"  />
                                           </div>
                                           <div className="form-group">
                                               <AvField name="fuel_name_bn" label="Fuel Name (Bangla)" value={fuel_name_bn} className="form-control"  type="text" />
                                           </div>
                                           <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                           </div>
                                   </AvForm>
                                   </div>
                               </Modal>
                            </Col>
                            <Col lg="6" md="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Fuel</CardTitle>
                                        
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }}>
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" type="text" label={reqLabel("Fuel Name")}  id="fuel_name_en" name="fuel_name_en" />
                                                 </div>
                                             
                                             
                                                 <div className="col-md-12">
                                                     <AvField className="form-control" label="Fuel Name (Bangla)"  type="text" name="fuel_name_bn" id="fuel_name_bn" />
                                                 </div>
                                            
                                             
                                                 
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.Fuel;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { createFuel,editFuel })(FuelCreate));
