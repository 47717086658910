import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_PRIVACY_POLICY,BRAND_SUCCESS,EDIT_PRIVACY_POLICY,SHOW_PRIVACY_POLICY } from './actionTypes';

import { privacyPolicySuccess, privacyPolicyError,privacyPolicyShow } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

// function* createBanner({ payload: { banner } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(postJwtData, 'banner/create',banner);
//              if(response.success == false){
//                 yield put(privacyPolicyError(response.message.message));
//             }else if(response.success == true){
//                 yield put(privacyPolicySuccess(response.data.message));
//             } 
//           }
//     } catch (error) {
//         yield put(privacyPolicyError(error));
//     }
// }


function* editPrivacyPolicy({ payload: { PrivacyPolicy } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
              console.log('saga',PrivacyPolicy)
             const response = yield call(editJwtData, 'user/privacy/policy/update/'+1, PrivacyPolicy);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(privacyPolicyError(response.message.message));
            }else if(response.success == true){
                yield put(privacyPolicySuccess(response.data.message));
            } 
          }
          
          
    } catch (error) {
        yield put(privacyPolicyError(error));
    }
}

function* showPrivacyPolicy({ payload: { user_id } }) {
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(getJwtData, 'users/id/'+user_id);
             yield put(privacyPolicyShow(response));
          }
          
          
    } catch (error) {
        yield put(privacyPolicyError(error));
    }
}

export function* watchPrivacyPolicy() {
    yield takeEvery(EDIT_PRIVACY_POLICY, editPrivacyPolicy)
    yield takeEvery(SHOW_PRIVACY_POLICY, showPrivacyPolicy)
}

function* PrivacyPolicySaga() {
    yield all([
        fork(watchPrivacyPolicy),
    ]);
}

export default PrivacyPolicySaga;